import { Area } from "arris_dist";

export enum ActionType {
    CREATE_AREA_SUCCESS="CREATE_AREA_SUCCESS",
    DELETE_AREA_SUCCESS="DELETE_AREA_SUCCESS",
    GET_AREAS_SUCCESS="GET_AREAS_SUCCESS",
    UPDATE_AREA_SUCCESS="UPDATE_AREA_SUCCESS"
}

interface createAreaSuccess {
    type: ActionType.CREATE_AREA_SUCCESS,
    payload: Area
}

interface deleteAreaSuccess {
    type: ActionType.DELETE_AREA_SUCCESS,
    payload: string
}

interface getAreasSuccess {
    type: ActionType.GET_AREAS_SUCCESS,
    areas: Area[]
}

interface updateAreaSuccess {
    type: ActionType.UPDATE_AREA_SUCCESS,
    payload: Area
}

export type Action = 
    createAreaSuccess |
    deleteAreaSuccess |
    getAreasSuccess |
    updateAreaSuccess;