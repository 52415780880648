import XsCustomers from "./xs";
import SmCustomers from "./sm";
import MdCustomers from "./md";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function Customers({is_tablet, is_phone}: Props){
    if(is_phone){
        return <XsCustomers />;
    }
    else if(is_tablet){
        return <SmCustomers />;
    }
    return <MdCustomers />;
}