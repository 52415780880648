export enum ActionType {
    ORDER_LOADING="ORDER_LOADING",
    ORDER_UPDATING="ORDER_UPDATING"
}

interface IOrderLoading {
    type: ActionType.ORDER_LOADING
}

interface IOrderUpdating {
    type: ActionType.ORDER_UPDATING
}

export type Action = IOrderLoading | IOrderUpdating;