import { text_primary } from "styles/theme";
import { large_area } from "styles/values";
import AboutPic1 from "static/about1.webp";
// about1.webp is the image, if you replace the image with a different name
// (contained in the top level folder "static")
// change it above here and it will reflect in the app

export const aboutMissionOuterContainerStyle = {
    paddingTop: {
        "md": 0,
        "sm": 10,
        "xs": 0
    },
    borderBottom: {
        "md": `1px solid ${text_primary}`,
        "sm": "none",
        "xs": `1px solid ${text_primary}`
    }
}

export const aboutMissionInnerContainerStyle = {
    height: "100%"
}

export const aboutMissionPictureStyle = {
    backgroundImage: `url(${AboutPic1})`,
    WebKitBorderRadius: 4,
    MozBorderRadius: 5,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    borderRadius: 4,
    backgroundPositionY: "10%",
    height: {
        "md": "auto",
        "sm": `${large_area}vh`,
        "xs": "auto"
    }
}

export const aboutMissionSpacerStyle = {
    height: "50px"
}