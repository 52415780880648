import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { Action } from "api/action_types/region";
import { ActionType } from "api/action_types/region";
import { url_prefix } from "settings";

const region_url: string = "region";
const sub_region_url: string = "sub_region";

export const getRegions = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.REGION_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    let url = `${url_prefix}${region_url}`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_REGIONS_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.REGION_FAIL,
          payload: err.message
        });
      }
    }
  }
}

export const getSubRegions = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.REGION_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    let url = `${url_prefix}${sub_region_url}`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_SUB_REGIONS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.REGION_FAIL,
          payload: err.message
        });
      }
    }
  }
}
