import { primary_color } from "styles/theme";
import { white_color } from "styles/theme";
import { error_color } from "styles/theme";

export const registrationThankYouContainerStyle = {
  width: "auto",
  backgroundColor: "#212121",
  color: white_color,
  minHeight: "60vh"
}

export const registrationThankYouGridStyle = {
  padding: "5%"
}

export const registrationFormContainerStyle = {
  width: "auto",
  backgroundColor: "#212121",
  color: white_color,
  minHeight: "60vh"
}

export const registrationCloseButtonStyle = {
  paddingTop: "25px",
  paddingRight: "50px"
}

export const registrationCloseButtonIconStyle = {
  color: white_color
}

export const registrationFormInnerContainerStyle = {
  paddingLeft: "50px",
  paddingRight: "50px",
  paddingTop: "25px"
}

export const registrationFormErrorTextStyle = {
  color: error_color
}

export const registrationResetPasswordTextStyle = {
  color: primary_color,
  cursor: "pointer",
  marginLeft: 0.5
}

export const formHelperTextStyle = {
  color: white_color,
  marginBottom: 0.25,
  marginTop: 1
}

export const registrationTextFieldStyle = {
  color: white_color,
  fieldset: { borderColor: white_color },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: white_color
    }
  },
  input: {
    color: "#fff"
  }
}

// this is needed in cases where there is no Error label underneath the textfield
export const registrationFormTextFieldSpacer = {
  marginBottom: 1.5
}

export const registrationFormLabelStyle = {
  color: white_color
}

export const registrationRadioControlStyle = {
  color: white_color,
  '&, &.Mui-checked': {
    color: white_color,
  }
}

export const registrationSubmitContainer = {
  marginTop: "25px"
}

export const registrationSubmitButtonStyle = {
  border: `1px solid ${white_color}`,
  color: white_color
}

export const registrationSelectionFormLabel = {
  color: white_color,
  "&.Mui-focused": {
    color: white_color
  }
}
