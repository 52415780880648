import type { Action as AreaAction } from "./area";
import type { Action as BumpAction } from "./bump";
import type { Action as ColorOptionAction } from "./color_option";
import type { Action as CooktopAction } from "./cooktop";
import type { Action as CornerAction } from "./corner";
import type { Action as CounterAction } from "./counter";
import type { Action as CutShapeAction } from "./cut_shape";
import type { Action as HistoryAction } from "./history";
import type { Action as LineAction } from "./line";
import type { Action as SeamAction } from "./seam";
import type { Action as SlabAction } from "./slab";
import type { Action as SinkAction } from "./sink";

import { ActionType as AreaActionType } from "./area";
import { ActionType as BumpActionType } from "./bump";
import { ActionType as ColorOptionActionType } from "./color_option";
import { ActionType as CooktopActionType } from "./cooktop";
import { ActionType as CornerActionType } from "./corner";
import { ActionType as CounterActionType } from "./counter";
import { ActionType as CutShapeActionType } from "./cut_shape";
import { ActionType as HistoryActionType } from "./history";
import { ActionType as LineActionType } from "./line";
import { ActionType as SeamActionType } from "./seam";
import { ActionType as SlabActionType } from "./slab";
import { ActionType as SinkActionType } from "./sink";

export const ActionType = {
    ...AreaActionType,
    ...BumpActionType,
    ...ColorOptionActionType,
    ...CooktopActionType,
    ...CornerActionType,
    ...CounterActionType,
    ...CutShapeActionType,
    ...HistoryActionType,
    ...LineActionType,
    ...SeamActionType,
    ...SlabActionType,
    ...SinkActionType
}

export type Action = 
    AreaAction |
    BumpAction |
    ColorOptionAction |
    CooktopAction |
    CornerAction |
    CounterAction |
    CutShapeAction |
    HistoryAction |
    LineAction |
    SeamAction |
    SlabAction |
    SinkAction;

