export const aboutHeroTitleStyle = {
    paddingBottom: 6
}

export const aboutHeroParagraphStyle = {
    fontSize: 26,
    lineHeight: 1.75,
    marginLeft: "auto",
    marginRight: "auto",
    width: {
        "xs": "40%",
        "sm": "80%",
        "md": "80%"
    }
}