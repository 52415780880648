export enum ActionType {
    SET_LAMINATE_COUNTERTOP_REMOVAL="SET_LAMINATE_COUNTERTOP_REMOVAL",
    SET_STONE_COUNTERTOP_REMOVAL="SET_STONE_COUNTERTOP_REMOVAL"
}

interface setLaminateCountertopRemoval {
    type: ActionType.SET_LAMINATE_COUNTERTOP_REMOVAL,
    payload: number
}

interface setStoneCountertopRemoval {
    type: ActionType.SET_STONE_COUNTERTOP_REMOVAL,
    payload: number
}

export type Action = setLaminateCountertopRemoval | setStoneCountertopRemoval;