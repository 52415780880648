/// TYPES ///
import { OrderCounter } from "arris_dist";
import { SelfOrderCounter } from "arris_dist";
/// COMPONENTS ///
import { View } from "@react-pdf/renderer";
import QuotePrintPreviewCounter from "./counter";

interface Props {
    counters: OrderCounter[] | SelfOrderCounter[]
}

export default function PrintOrderCounters({counters}: Props) {
    return (
        <View style={{display: "flex", flexDirection: "row", flex: 1, flexWrap: "wrap"}}>
            {
                counters.map((counter, index) => {
                    return (
                        <QuotePrintPreviewCounter key={index} counter={counter} />
                    );
                })
            }
        </View>
    );
}