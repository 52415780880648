import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import AboutHero from "./hero";
import OurMission from "./mission";
import Timeline from "./timeline";
import SecondBanner from "./second_banner";
import Values from "./values";
import ValuesList from "./values_list";
import Box from "@mui/material/Box";
import Footer from "components/universal/footer";

export default function About() {
    const theme = useTheme();
    const is_phone = useMediaQuery(theme.breakpoints.down("sm"));
    const is_tablet = useMediaQuery(theme.breakpoints.down("md"));

    return(
        <Box>
            <AboutHero is_tablet={is_tablet} is_phone={is_phone} />
            <OurMission is_tablet={is_tablet} is_phone={is_phone} />
            <Timeline is_tablet={is_tablet} is_phone={is_phone} />
            <SecondBanner />
            <Values is_tablet={is_tablet} is_phone={is_phone} />
            <ValuesList is_tablet={is_tablet} is_phone={is_phone} />
            <Footer is_tablet={is_tablet} is_phone={is_phone} />
        </Box>
    )
}
