import type { Action as AreaAction } from "./area";
import type { Action as CounterAction } from "./counter";
import type { Action as ErrorAction } from "./error";
import type { Action as LoadingAction } from "./loading";
import type { Action as SelfOrderAction } from "./self_order";
import type { Action as SlabAction } from "./slab";

import { ActionType as AreaActionType } from "./area";
import { ActionType as CounterActionType } from "./counter";
import { ActionType as ErrorActionType } from "./error";
import { ActionType as LoadingActionType } from "./loading";
import { ActionType as SelfOrderActionType } from "./self_order";
import { ActionType as SlabActionType } from "./slab";

export const ActionType = {
    ...AreaActionType,
    ...CounterActionType,
    ...ErrorActionType,
    ...LoadingActionType,
    ...SelfOrderActionType,
    ...SlabActionType
}

export type Action =
    AreaAction |
    CounterAction |
    ErrorAction |
    LoadingAction |
    SelfOrderAction |
    SlabAction;

