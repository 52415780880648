import { primary_color } from "styles/theme";
import { text_primary } from "styles/theme";
import { white_color } from "styles/theme";
import { well_color } from "styles/theme";

export const plansContainerStyle = {
    marginBottom: 10
}

export const plansTitleTextStyle = {
    paddingTop: "10vh"
}

export const plansSubtitleTextStyle = {
    paddingTop: "2vh",
    textTransform: "none"
}

export const planContainerStyle = {
    paddingTop: "10vh"
}

export const plansCardStyle = {
    width: {
        "xs": 300,
        "sm": 400,
        "md": 400
    },
    padding: 4,
    paddingTop: 4,
    paddingBottom: 4
}

export const plansBestValueCardStyle = {
    width: {
        "xs": 380,
        "sm": 480,
        "md": 480
    },
}

export const bestValueCardInnerWrapperStyle = {
    position: "absolute"
}

export const plansValueCardStyle = {
    position: "relative",
    left: {
        "xs": 140,
        "sm": 185,
        "md": 185
    },
    top: -20,
    width: 100,
    height: 35,
    backgroundColor: primary_color,
    marginBottom: 2
}

export const planButtonStyle = {
    marginTop: 4,
    marginBottom: 1,
    width: 200,
    padding: 1
}

export const planTextStyle = {
    color: text_primary
}

export const planPriceWrapperStyle = {
    paddingTop: 1
}

export const planBillingPeriodTextStyle = {
    fontSize: 15,
    paddingTop: 1
}

export const planTrialTextStyle = {
    paddingTop: 5
}

export const planFeatureRowStyle = {
    paddingTop: 3
}

export const planFeatureTextStyle = {
    marginLeft: 2
}

export const planBestValueTextStyle = {
    color: white_color
}

export const monthlyPlanStackStyle = {
    paddingBottom: 1.5
}

export const yearlyPlanStackStyle = {
    backgroundColor: well_color,
    paddingBottom: 1.5,
    paddingTop: 4,
    paddingRight: 4,
    paddingLeft: 4
}

export const yearlyPlanBenefitStackStyle = {
    paddingBottom: 4,
    paddingRight: 4,
    paddingLeft: 4
}