import XsOurPartners from "./xs";
import MdOurPartners from "./md";
import SmOurPartners from "./sm";

interface Props {
    is_phone: boolean,
    is_tablet: boolean
}

export default function OurPartners({is_phone, is_tablet}: Props){
    if(is_phone){
        return <XsOurPartners />;
    }
    if(is_tablet){
        return <SmOurPartners />;
    }
    return <MdOurPartners />;
}