import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReadMoreBox from "components/universal/read_more_box";
import { Row5 } from "values/text/home";
import Pic5 from "static/pic5.jpg";
import { ParallaxBanner } from "react-scroll-parallax";
import { homeMaterialContainer } from "styles/home_page/material";
import { homeMaterialXsImage } from "styles/home_page/material";
import { homeMaterialImageContainer } from "styles/home_page/material";

export default function XsMaterial(){
    return (
        <Box sx={homeMaterialContainer}>
            <Grid container>
                <Grid item xs={12} sx={homeMaterialImageContainer}>
                    <ParallaxBanner 
                        style={homeMaterialXsImage}
                        layers={[{
                            image: Pic5,
                            translateY: [-45, 50],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                        }]}>
                    </ParallaxBanner>
                </Grid>
            </Grid>
            <ReadMoreBox 
                title1={Row5.TITLE_1}
                title2={Row5.TITLE_2}
                paragraph={Row5.PARAGRAPH_1}
                grid_left={2}
                grid_right={7}
                grid_end={3}
                is_mobile={true} />
        </Box>
    )
}

                    