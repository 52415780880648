import { QuoteLineItem } from "arris_dist";
import { Quote } from "arris_dist";

export enum ActionType {
    QUOTE_PENDING="QUOTE_PENDING",
    QUOTE_FAIL="QUOTE_FAIL",
    GET_QUOTE_SUCCESS="GET_QUOTE_SUCCESS",
    GENERATE_QUOTE="GENERATE_QUOTE",
    SET_QUOTE_CHANGED="SET_QUOTE_CHANGED"
}

interface quotePending {
    type: ActionType.QUOTE_PENDING
}

interface quoteFail {
    type: ActionType.QUOTE_FAIL,
    payload: string
}

interface generateQuote {
    type: ActionType.GENERATE_QUOTE,
    payload: QuoteLineItem[]
}

interface getQuoteSuccess {
    type: ActionType.GET_QUOTE_SUCCESS,
    quote: Quote
}

interface setQuoteChanged {
    type: ActionType.SET_QUOTE_CHANGED,
    payload: boolean
}



export type Action = quotePending | quoteFail | getQuoteSuccess | generateQuote | setQuoteChanged;