import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StatementBox from "components/universal/statement_box";
import { Streamline } from "values/text/fabricator";
import { largePictureAreaStyle } from "styles/general";
import { black_color } from "styles/theme";
import { white_color } from "styles/theme";
import { fabricatorStreamlineContainerStyle } from "styles/fabricator/streamline";
import { fabricatorStreamlineGridStyle } from "styles/fabricator/streamline";
import { fabricatorStreamlineImageStyle } from "styles/fabricator/streamline";

export default function XsStreamline(){
    return (
        <Box  sx={[largePictureAreaStyle, fabricatorStreamlineContainerStyle]}>
            <Grid container sx={fabricatorStreamlineGridStyle}>
                <StatementBox 
                    title={Streamline.TITLE}
                    subtitle1={Streamline.PARAGRAPH}
                    text_color={white_color}
                    background_color={black_color}
                    hover_background_color={white_color}
                    hover_text_color={black_color}
                    border_color={white_color}
                    is_phone={true}
                    paddingTop={10} />
                <Grid item xs={6} sx={fabricatorStreamlineImageStyle}>
                </Grid>
            </Grid>
        </Box>
    )
}