import MdSavings from "./md";
import XsSavings from "./xs";

interface Props {
    is_mobile: boolean
}

export default function Savings({is_mobile}: Props){
    if(is_mobile){
        return <XsSavings />;
    }
    return <MdSavings />;
}