import { Box, Typography } from '@mui/material';
import {scrollerWrapper, scrollerImgMd, scrollerImgXs} from 'styles/home_page/clients/index';
import { heroWhyTitleStyle } from 'styles/home_page/why';
import { Row10 } from 'values/text/home';
import AccentMarble from "static/accent.jpg";
import Action from "static/action.jpg";
import Aya from "static/aya kitchens.jpg";
import Bfc from "static/bfc.jpg";
import RennovationF from "static/rennovationfind.jpg";

export default function MdClients() {
  return (
    <Box sx={scrollerWrapper}>
        <Typography variant="h2" textAlign="center" sx={heroWhyTitleStyle}>
            {Row10.TITLE}
        </Typography>
        <div className='scroller' data-animated="true">
            <ul className='scroller__inner'>
                {itemData.map((item) => (
                    <li key={item.img}>
                    <img style={scrollerImgXs}
                        src={`${item.img}`}
                        alt={item.title}
                        loading="lazy"
                    />
                    </li>
                ))}
                {itemData.map((item) => (
                    <li key={item.img}>
                    <img style={scrollerImgXs}
                        src={`${item.img}`}
                        alt={item.title}
                        loading="lazy"
                    />
                    </li>
                ))}
            </ul>
        </div>
    </Box>
  );
}

const itemData = [

  {
    img: AccentMarble,
    title: 'Accent Marble',
  },
  {
    img: Action,
    title: 'Action Flooring',
  },
  {
    img: RennovationF,
    title: 'Rennovation Find',
  },
  {
    img: Aya,
    title: 'Aya Kitchens',
  },
  {
    img: Bfc,
    title: 'BFC Flooring',
  }
];
