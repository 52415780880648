import { OrderSlab } from "arris_dist";

export enum ActionType {
    GET_ORDER_SLABS_SUCCESS="GET_ORDER_SLABS_SUCCESS"
}

interface IGetOrderSlabsSuccess {
    type: ActionType.GET_ORDER_SLABS_SUCCESS,
    order_slabs: OrderSlab[]
}

export type Action = IGetOrderSlabsSuccess;