import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useAppDispatch } from 'hooks/api';
import { useAppSelector } from 'hooks/api';
import { getConfirmation } from "api/actions/confirmation";
import { postConfirmation } from "api/actions/confirmation";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { primary_color } from "styles/theme";
import { text_primary } from "styles/theme";
import { error_color } from "styles/theme";
import { app_url } from "settings";

export default function AccountConfirmation() {
  const dispatch = useAppDispatch();
  const { error } = useAppSelector((state) => state.authentication);
  const { account_confirmation_complete } = useAppSelector((state) => state.authentication);
  const { uuid } = useParams();
  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [show_password, setShowPassword] = useState<boolean>(false);
  const [show_password2, setShowPassword2] = useState<boolean>(false);
  const [local_error, setError] = useState<string>("");
  const [has_error, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (uuid) {
      dispatch(getConfirmation(uuid));
    }
  }, [dispatch]);

  const handlePassword = (input: string) => {
    setPassword(input);
    setError("");
    setHasError(false);
  }

  const handlePassword2 = (input: string) => {
    setPassword2(input);
    setError("");
    setHasError(false);
  }

  const activate = () => {
    if (password !== password2) {
      setError("Passwords do not match");
      setHasError(true);
      return;
    }

    if (password.length < 8) {
      setError("Password must be at least 8 characters");
      setHasError(true);
      return;
    }

    if (uuid) {
      dispatch(postConfirmation(uuid, {
        old_password: "",
        password: password,
        password2: password2
      }));
    }
    else {
      // no uuid, set as resend
    }
  }

  const redirectPage = () => {
    window.location.replace(app_url + "login");
  }

  return (
    <Box sx={{
      padding: {
        "md": "20vh",
        "xs": "2vh"
      }
    }}>
      {
        account_confirmation_complete ?
          <Stack>
            <Typography variant="h6" sx={{ paddingLeft: 3, paddingBottom: 0.5, color: text_primary }}>
              Your password has been updated. Please login to complete your registration.
            </Typography>
            <Button
              onClick={redirectPage}
              variant="contained"
              sx={{ width: 150, marginTop: 1.5, marginLeft: 3 }}>
              Login
            </Button>
          </Stack> :
          <Stack>
            <Typography variant="h6" sx={{ paddingLeft: 3, paddingBottom: 0.5, color: text_primary }}>
              Thank you for confirming your email
            </Typography>
            {
              has_error || error === "" ?
                <Typography variant="body2" sx={{ padding: 3, paddingTop: 0.5, color: has_error ? error_color : text_primary }}>
                  {has_error ? local_error : "Please enter a password for your account"}
                </Typography> :
                <Typography variant="body2" sx={{ padding: 3, paddingTop: 0.5, color: error_color }}>
                  {error}
                </Typography>
            }
            <TextField
              autoCapitalize="none"
              type={show_password ? "text" : "password"}
              InputLabelProps={{
                style: {
                  color: primary_color
                }
              }}
              InputProps={{
                style: {
                  color: text_primary
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      !show_password ?
                        <Tooltip title="Show Password">
                          <IconButton onClick={() => setShowPassword(true)}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip> :
                        <Tooltip title="Hide Password">
                          <IconButton onClick={(e) => setShowPassword(false)}>
                            <VisibilityOffIcon />
                          </IconButton>
                        </Tooltip>
                    }
                  </InputAdornment>
                )
              }}
              sx={{ marginLeft: 3, marginBottom: 1, width: 400, color: text_primary }}
              size="small"
              error={has_error}
              onChange={(e) => handlePassword(e.target.value)}
              label="Password" />
            <TextField
              autoCapitalize="none"
              type={show_password2 ? "text" : "password"}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  color: primary_color
                }
              }}
              InputProps={{
                style: {
                  color: text_primary
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      !show_password2 ?
                        <Tooltip title="Show Password">
                          <IconButton onClick={() => setShowPassword2(true)}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip> :
                        <Tooltip title="Hide Password">
                          <IconButton onClick={(e) => setShowPassword2(false)}>
                            <VisibilityOffIcon />
                          </IconButton>
                        </Tooltip>
                    }
                  </InputAdornment>
                )
              }}
              sx={{ marginLeft: 3, width: 400, color: text_primary }}
              size="small"
              error={has_error}
              onChange={(e) => handlePassword2(e.target.value)}
              label="Confirm Password" />
            <Button
              onClick={activate}
              variant="contained"
              sx={{ width: 150, marginTop: 1.5, marginLeft: 3 }}>
              Activate
            </Button>
          </Stack>
      }
    </Box>
  );
}
