import { primary_color } from "styles/theme";
import { large_area_no_padding } from "styles/values";

export const heroVisionContainerStyle = {
    background: primary_color
}

export const heroVisionContainerInnerWrapperStyle = {
    height: "100%"
}

export const heroVisionImageInnerWrapperStyle = {
    width: "100%",
    overflow: "hidden",
    height: {
        "xs": "55vh",
        "sm": "75vh",
        "md": "100%"
    }
}

export const heroVisionXsImageStyle = {
    height: "130%",
    width: "130%"
}

export const heroVisionSmImageStyle = {
    height: "130%",
    width: "130%"
}

export const heroVisionMdImageStyle = {
    height: "140%",
    width: "140%"
}

export const heroVisionImageLayersStyle = {
    backgroundPositionY: "10%"
}

export const heroVisionImageOuterWrapperStyle = {
    minHeight: {
        "xs": "auto",
        "sm": "auto",
        "md": `${large_area_no_padding}vh`
    }
}