import MdReadMoreBox from "./md";
import XsReadMoreBox from "./xs";

interface Props {
    title1: string,
    title2?: string,
    title3?: string,
    paragraph: string,
    grid_left?: number,
    grid_right?: number,
    grid_end?: number,
    is_mobile: boolean
}

export default function ReadMoreBox({title1, title2, title3, paragraph, grid_left, grid_right, grid_end, is_mobile}: Props){
    if(is_mobile){
        return (
            <XsReadMoreBox 
                title1={title1} 
                title2={title2}
                title3={title3}
                paragraph={paragraph}
                grid_left={grid_left}
                grid_right={grid_right}
                grid_end={grid_end} />
        );
    }
    return (
        <MdReadMoreBox 
            title1={title1} 
            title2={title2}
            title3={title3}
            paragraph={paragraph}
            grid_left={grid_left}
            grid_right={grid_right}
            grid_end={grid_end} />
    );
}