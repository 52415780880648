import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReadMoreBox from "components/universal/read_more_box";
import { Row6 } from "values/text/home";
import { largePictureAreaStyle } from "styles/general";
import { homeControlContainerStyle } from "styles/home_page/control";
import { homeControlImageStyle } from "styles/home_page/control";

export default function MdControl(){
    return (
        <Box sx={[largePictureAreaStyle, homeControlContainerStyle]}>
            <Grid container>
                <ReadMoreBox 
                    title1={Row6.TITLE_1}
                    title2={Row6.TITLE_2}
                    title3={Row6.TITLE_3}
                    paragraph={Row6.PARAGRAPH_1}
                    is_mobile={false} />
                <Grid item xs={6} sx={homeControlImageStyle}>
                </Grid>
            </Grid>
        </Box>
    )
}