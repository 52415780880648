import MdMaterial from "./md";
import XsMaterial from "./xs";

interface Props {
    is_mobile: boolean
}

export default function Material({is_mobile}: Props){
    if(is_mobile){
        return <XsMaterial />;
    }
    return <MdMaterial />;
}