import MdClients from "./md";
import SmClients from "./sm";
import XsClients from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function Clients({is_tablet, is_phone}: Props){
    if(is_phone){ 
        return <XsClients />;
    }
    else if(is_tablet){
        return <SmClients />;
    }
    return <MdClients />;
}