import { Slab } from "arris_dist";

export enum ActionType {
    CREATE_SLAB_SUCCESS="CREATE_SLAB_SUCCESS",
    DELETE_SLAB_SUCCESS="DELETE_SLAB_SUCCESS"
}

interface deleteSlabSuccess {
    type: ActionType.DELETE_SLAB_SUCCESS,
    payload: string
}

interface createSlabSuccess {
    type: ActionType.CREATE_SLAB_SUCCESS,
    payload: Slab
}

export type Action = 
    createSlabSuccess |
    deleteSlabSuccess;