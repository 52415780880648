import { BrowserRouter } from "react-router-dom";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import NavBar from "components/navbar/navbar";
import Home from "components/home/home";
import About from "components/about/about";
import Merchant from "components/merchant/merchant";
import Fabricator from "components/fabricator/fabricator";
import Plans from "components/plans/plans";
import AccountConfirmation from "components/account/confirmation";
import PasswordReset from "components/account/password_reset";
import SignOrder from "components/signature/order";
import SignSelfOrder from "components/signature/self_order";
import SignQuote from "components/signature/quote";
import CompletePasswordReset from "components/account/complete_password_reset";
import { home_url } from "./endpoints";
import { plans_url } from "./endpoints";
import { about_url } from "./endpoints";
import { merchant_url } from "./endpoints";
import { fabricator_url } from "./endpoints";
import { email_confirmation_url } from "./endpoints";
import { password_reset_url } from "./endpoints";
import { sign_order_url } from "./endpoints";
import { sign_quote_url } from "./endpoints";
import { sign_direct_order_url } from "./endpoints";
import { demo_url } from "./endpoints";

export default function MainRouter(){
    return (
        <BrowserRouter>
            <NavBar />
            <Routes>
                <Route path={`${home_url}`}>
                    <Route index element={<Home />} />
                </Route>
                <Route path={`${about_url}`}>
                    <Route index element={<About />} />
                </Route>
                <Route path={`${merchant_url}`}>
                    <Route index element={<Merchant />} />
                </Route>
                <Route path={`${fabricator_url}`}>
                    <Route index element={<Fabricator />} />
                </Route>
                <Route path={`${plans_url}`}>
                    <Route index element={<Plans />} />
                </Route>
                <Route path={`${email_confirmation_url}`}>
                    <Route path=":uuid" element={<AccountConfirmation />}/>
                </Route>
                <Route path={`${password_reset_url}`}>
                    <Route index element={<PasswordReset />}/>
                    <Route path=":uuid" element={<CompletePasswordReset />} />
                </Route>
                <Route path={sign_order_url}>
                    <Route path=":key" element={<SignOrder />} />
                </Route>
                <Route path={sign_direct_order_url}>
                    <Route path=":key" element={<SignSelfOrder />} />
                </Route>
                <Route path={sign_quote_url}>
                    <Route path=":key" element={<SignQuote />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}