export enum ActionType {
    RESTORE="RESTORE",
    UNDO="UNDO",
    REDO="REDO",
    CREATE_HISTORY_POINT="CREATE_HISTORY_POINT"
}

interface restore {
    type: ActionType.RESTORE
}

interface undo {
    type: ActionType.UNDO
}

interface redo {
    type: ActionType.REDO
}

interface createHistoryPoint {
    type: ActionType.CREATE_HISTORY_POINT
}

export type Action = restore | redo | undo | createHistoryPoint;