import { hero_area_transparent_nav } from "./values";
import { large_area } from "./values";
import { large_area_no_padding } from "./values";
import { medium_area } from "./values";
import { small_area } from "./values";
import { area_vertical_padding } from "./values";
import { mobile_area_vertical_padding } from "./values";

export const heroTransparentAreaStyle = {
    minHeight: `${hero_area_transparent_nav}vh`,
    paddingTop: `15vh`
}

export const largeAreaStyle = {
    minHeight: `${large_area}vh`,
    paddingTop: `${area_vertical_padding}vh`,
    paddingBottom: `${area_vertical_padding}vh`
}

export const largePictureAreaStyle = {
    minHeight: `${large_area_no_padding}vh`
}

export const xsLargePictureAreaStyle = {
    minHeight: "55vh"
}

export const mediumAreaStyle = {
    minHeight: `${medium_area}vh`,
    paddingTop: `${area_vertical_padding}vh`,
    paddingBottom: `${area_vertical_padding}vh`
}

export const smallAreaStyle = {
    minHeight: `${small_area}vh`,
    paddingTop: {
        xs: mobile_area_vertical_padding,
        md:`${area_vertical_padding}vh`,
    },
    paddingBottom: `${area_vertical_padding}vh`
}