import XsValues from "./xs";
import SmValues from "./sm";
import MdValues from "./md";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}
export default function Values({is_tablet, is_phone}: Props){
    if(is_phone){
        return <XsValues />;
    }
    else if(is_tablet){
        return <SmValues />;
    }
    return <MdValues />;
}