import { SelfOrder } from "arris_dist";
import { User } from "arris_dist";

export enum ActionType {
    GET_SELF_ORDERS_SUCCESS="GET_SELF_ORDERS_SUCCESS",
    GET_SELF_ORDER_SUCCESS="GET_SELF_ORDER_SUCCESS",
    GET_SELF_ORDER_VENDOR_SUCCESS="GET_SELF_ORDER_VENDOR_SUCCESS",
    CREATE_SELF_ORDER_SUCCESS="CREATE_SELF_ORDER_SUCCESS",
    RESET_SELF_ORDER_SUCCESS="RESET_SELF_ORDER_SUCCESS"
}

interface IGetSelfOrders {
    type: ActionType.GET_SELF_ORDERS_SUCCESS,
    self_orders: SelfOrder[]
}

interface IGetSelfOrder {
    type: ActionType.GET_SELF_ORDER_SUCCESS,
    self_order: SelfOrder
}

interface IGetSelfOrderVendor {
    type: ActionType.GET_SELF_ORDER_VENDOR_SUCCESS,
    vendor: User
}

interface ICreateSelfOrder {
    type: ActionType.CREATE_SELF_ORDER_SUCCESS,
    self_order: SelfOrder
}

interface IResetSelfOrder {
    type: ActionType.RESET_SELF_ORDER_SUCCESS
}

export type Action=IGetSelfOrders | IGetSelfOrder | IGetSelfOrderVendor | ICreateSelfOrder | IResetSelfOrder;