import MdTimeline from "./md";
import SmTimeline from "./sm";
import XsTimeline from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function Timeline({is_tablet, is_phone}: Props){
    if(is_phone){
        return <XsTimeline />;
    }
    else if(is_tablet){
        return <SmTimeline />;
    }
    return <MdTimeline />;
}