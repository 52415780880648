import { useAppDispatch } from "hooks/api";
import { setTryNow } from "api/actions/ui";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Grid";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { readMoreButtonIconStyle } from "styles/universal/read_more_box";
import { readMoreButtonStyle } from "styles/universal/read_more_box";
import { readMoreTitleTextStyle } from "styles/universal/read_more_box";
import { readMoreParagraphTextStyle } from "styles/universal/read_more_box";

interface Props {
    title1: string,
    title2?: string,
    title3?: string,
    paragraph: string,
    grid_left?: number,
    grid_right?: number,
    grid_end?: number
}

export default function XsReadMoreBox({
    title1,
    title2,
    title3,
    paragraph,
    grid_left=4,
    grid_right=7,
    grid_end=1
}: Props) {
    const dispatch = useAppDispatch();

    return (
        <Grid container>
            <Grid item xs={1.25}></Grid>
            <Grid item xs={9.75}>
                <Stack>
                    <Typography variant="h2" sx={readMoreTitleTextStyle}>
                        {title1}
                    </Typography>
                    {
                        title2 ?
                        <Typography variant="h2" sx={readMoreTitleTextStyle}>
                            {title2}
                        </Typography> : 
                        null
                    }
                    {
                        title3 ?
                        <Typography variant="h2" sx={readMoreTitleTextStyle}>
                            {title3}
                        </Typography> :
                        null
                    }
                    <Typography variant="body1" sx={readMoreParagraphTextStyle}>
                        {paragraph}
                    </Typography>
                    <Button 
                        onClick={() => dispatch(setTryNow(true))}
                        endIcon={<ArrowForwardIcon sx={readMoreButtonIconStyle}/>}
                        sx={readMoreButtonStyle}>
                        Try Now
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={1}></Grid>
        </Grid>
  );
}
