import { Corner } from "arris_dist";

export enum ActionType {
    UPDATE_CORNER="UPDATE_CORNER"
}

interface updateCorner {
    type: ActionType.UPDATE_CORNER,
    payload: Corner
}

export type Action = updateCorner;