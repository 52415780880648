/// HOOKS ///
import { useAppDispatch } from "hooks/api";
import { useAppSelector } from "hooks/api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTheme } from "@mui/material";
/// ACTIONS ///
import { setTryNow } from "api/actions/ui";
/// COMPONENTS ///
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MobileDrawer from "./mobile_drawer";
import RegistrationComponent from "components/registration/registration";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
/// ICONS ///
import MenuIcon from "@mui/icons-material/Menu";
/// IMAGES ///
import LogoIcon from "static/logo_icon.png";
/// URLS ///
import { about_url } from "components/router/endpoints";
import { demo_url } from "components/router/endpoints";
import { app_url } from "settings";
import { fabricator_url } from "components/router/endpoints";
import { home_url } from "components/router/endpoints";
import { merchant_url } from "components/router/endpoints";
import { plans_url } from "components/router/endpoints";
/// STYLES ///
import { logoTextStyle } from "styles/navbar";
import { navAppBarStyle } from "styles/navbar";
import { navBarButtonStyle } from "styles/navbar";
import { navBarPromo } from "styles/navbar";
import { navBarPromoTextStyle } from "styles/navbar";
import { navBarSpacerStyle } from "styles/navbar";
import { navBarDemoButtonStyle } from "styles/navbar";
import { navBarTryNowButtonStyle } from "styles/navbar";
import { navHamburgerButtonStyle } from "styles/navbar/navbar";
import { navLogoStyle } from "styles/navbar";
import { navToolbarStyle } from "styles/navbar";
/// VALUES ///
import { primary_color } from "styles/theme";
import { white_color } from "styles/theme";

export default function NavBar() {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { open } = useAppSelector((state) => state.ui);
    const [drawer_open, setDrawerOpen] = useState<boolean>(false); 

    const navigateLogin = () => {
        window.location.href = app_url + "login";
    }

    const nav = (url: string) => {
        window.scrollTo(0,0);
        navigate(url, {replace: false});
    }

    return (
        <AppBar position="sticky" sx={navAppBarStyle(theme.zIndex.drawer + 1)}>
            <Toolbar disableGutters={true} sx={navToolbarStyle}>
                <img 
                    style={navLogoStyle}
                    onClick={() => navigate(home_url, {replace: false})}
                    src={LogoIcon} 
                    height="45%" />
                <Typography 
                    variant="h4" 
                    sx={logoTextStyle}
                    onClick={() => nav(home_url)}>
                        A r r i s
                </Typography>
                <Box display="flex" alignItems="center">
                    
                    
                </Box>
                <Box sx={navBarSpacerStyle}></Box> 
                <Button
                    onClick={() => nav(plans_url)}
                    sx={navBarButtonStyle}>
                    Plans
                </Button>
                <Button
                    onClick={() => nav(merchant_url)}
                    sx={navBarButtonStyle}>
                    For Merchants
                </Button>
                <Button
                    onClick={() => nav(fabricator_url)}
                    sx={navBarButtonStyle}>
                    For Fabricators
                </Button>
                <Button 
                    onClick={navigateLogin}
                    sx={navBarButtonStyle}>
                    Login
                </Button>
                <Button 
                    size="small"
                    variant="contained"  
                    onClick={() => window.open("https://calendly.com/arris-stone/30min", '_blank')}
                    sx={navBarDemoButtonStyle}>
                    Get a demo
                </Button>
                <Button 
                    size="small"
                    variant="contained"  
                    onClick={() => dispatch(setTryNow(!open))}
                    sx={navBarTryNowButtonStyle}>
                    Try now
                </Button>
                <IconButton 
                    sx={navHamburgerButtonStyle}
                    color="primary"
                    onClick={() => setDrawerOpen(prev => !prev)}>
                    <MenuIcon />
                </IconButton>
                <MobileDrawer open={drawer_open} setOpen={setDrawerOpen} />
                <RegistrationComponent />
            </Toolbar>
            <Box display="block" alignItems="center" textAlign={"center"} sx={navBarPromo}>
                <Typography 
                    variant="h3"
                    fontSize={15}
                    color={white_color}
                    sx={[{navBarPromoTextStyle}]}
                    onClick={() => window.open("https://calendly.com/arris-stone/30min", '_blank')}>
                    Get a demo
                </Typography>
            </Box>
        </AppBar>
  );
}