import XsDescription from "./xs";
import SmDescription from "./sm";
import MdDescription from "./md";

interface Props {
    is_tablet?: boolean,
    is_phone?: boolean,
    subtitle: string,
    paragraph: string
}

export default function Description({is_tablet=false, is_phone=false, subtitle, paragraph}: Props){
    if(is_phone){
        return <XsDescription subtitle={subtitle} paragraph={paragraph} />;
    }
    else if(is_tablet){
        return <SmDescription subtitle={subtitle} paragraph={paragraph} />;
    }
    return <MdDescription subtitle={subtitle} paragraph={paragraph} />;
}