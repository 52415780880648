import XsWhyArris from "./xs";
import SmWhyArris from "./sm";
import MdWhyArris from "./md";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function WhyArris({is_tablet, is_phone}: Props){
    if(is_phone){
        return <XsWhyArris />;
    }
    else if(is_tablet){
        return <SmWhyArris />;
    }
    return <MdWhyArris />;
}