export const merchantQualitySpacerStyle = {
    height: "50px"
}
export const qualityTitle = {
    fontSize: {
        "md":"28px",
        "xs":"24px"
    }
}
export const qualityPara = {
    fontSize: "19px",
    paddingBlock:"15px",
    lineHeight: 2
}
export const container = {
    paddingLeft: {
        "md": "70px",
        "xs": "20px"
    },
    paddingRight: {
        "md": "70px",
        "xs": "20px"
    },
    paddingBlock: {
        "md": "40px",
        "xs": "20px"
    }
}
