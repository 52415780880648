import type { Action as DiscountAction } from "./discount";
import type { Action as QuoteAction } from "./quote";
import type { Action as RemovalAction } from "./removal";
import type { Action as UserAction } from "./user";

import { ActionType as DiscountActionType } from "./discount";
import { ActionType as QuoteActionType } from "./quote";
import { ActionType as RemovalActionType } from "./removal";
import { ActionType as UserActionType } from "./user";

export const ActionType = {
    ...DiscountActionType,
    ...QuoteActionType,
    ...RemovalActionType,
    ...UserActionType
}

export type Action = DiscountAction | QuoteAction | RemovalAction | UserAction;

