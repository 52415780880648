import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CommentBox from "components/universal/comment_box";
import { QualityDetails } from "values/text/merchant";
import { smallAreaStyle } from "styles/general";
import { text_primary } from "styles/theme";
import { merchantQualitySpacerStyle, qualityTitle, container,qualityPara } from "styles/merchant/quality";
import { Typography } from "@mui/material";


export default function MdQuality(){
    return (
        <Box sx={[{smallAreaStyle}]}>
            <Grid container>
            <Grid item xs={6} sx={container}>
                <Typography variant="h2" sx={qualityTitle}>{QualityDetails.TITLE_1}</Typography>
                <Typography variant="body2" sx={qualityPara}>{QualityDetails.PARAGRAPH_1}</Typography>
            </Grid>
            <Grid item xs={6} sx={container}>
                <Typography variant="h2" sx={qualityTitle}>{QualityDetails.TITLE_2}</Typography>
                <Typography variant="body2" sx={qualityPara}>{QualityDetails.PARAGRAPH_2}</Typography>
            </Grid>
            </Grid>
        </Box>
    )
}