import MdAnimation from "./md";
import SmAnimation from "./sm";
import XsAnimation from "./xs";

interface Props {
    is_tablet?: boolean,
    is_phone?: boolean,
    src: string
}

export default function Animation({is_phone=false, is_tablet=false, src}: Props){
    if(is_phone){
        return <XsAnimation src={src} />;
    }
    else if(is_tablet){
        return <SmAnimation src={src} />;
    }
    return <MdAnimation src={src} />;
}