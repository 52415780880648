import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import FabricatorHero from "./hero";
import Growth from "./growth";
import Comments from "./comments";
import Streamline from "./streamline";
import Details from "./streamline_details";
import Footer from "components/universal/footer";

export default function Fabricator() {
    const theme = useTheme();
    const is_phone = useMediaQuery(theme.breakpoints.down("sm"));
    const is_tablet = useMediaQuery(theme.breakpoints.down("md"));

    return(
        <Box>
            <FabricatorHero is_tablet={is_tablet} is_phone={is_phone} />
            <Growth is_tablet={is_tablet} is_phone={is_phone} />
            <Comments is_tablet={is_tablet} is_phone={is_phone} />
            <Streamline is_tablet={is_tablet} is_phone={is_phone} />
            <Details is_tablet={is_tablet} is_phone={is_phone} />
            <Footer is_tablet={is_tablet} is_phone={is_phone} />
        </Box>
    )
}
