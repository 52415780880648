import Pic5 from "static/pic5.jpg";
// pic5.jpg is the image for md size, if you replace the image with a different name
// (contained in the top level folder "static")
// change it above here and it will reflect in the app

export const homeEdgeContainerStyle = {
    paddingTop: {
        "xs": 5,
        "sm": 5,
        "md": "auto"
    },
    paddingBottom: {
        "xs": 5,
        "sm": 5,
        "md": "auto"
    },
    marginTop: {
        "xs": 0,
        "sm": 0,
        "md": "15vh"
    },
    height: { 
        "xs": "auto",
        "sm": "auto",
        "md": "95vh"
    },
    backgroundImage: {
        "xs": "none",
        "sm": "none",
        "md": `url(${Pic5})`
    },
    backgroundPosition: "cover",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
}

export const homeEdgeImageWrapperStyle = {
    height: "100%",
    width: "100%",
    overflow: "hidden"
}

export const homeEdgeImageStyle = {
    height: "100%",
    width: "100%",
    overflow: "hidden"
}