import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Row1 } from "values/text/about";
import { mediumAreaStyle } from "styles/general";
import { aboutHeroTitleStyle } from "styles/about_page/hero";
import { aboutHeroParagraphStyle } from "styles/about_page/hero";

export default function SmAboutHero(){
    return (
        <Box sx={mediumAreaStyle}>
            <Stack>
                <Typography variant="h1" textAlign="center" sx={aboutHeroTitleStyle}>
                    {Row1.TITLE}
                </Typography>
                <Typography variant="body1" sx={aboutHeroParagraphStyle} textAlign="center">
                    {Row1.PARAGRAPH_BRIEF}
                </Typography>
            </Stack>
        </Box>
    )
}