/// HOOKS ///
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/api";
import { setTryNow } from "api/actions/ui";
/// COMPONENTS ///
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
/// ICONS ///
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MiniLogo from "static/ArrisMiniLogo.webp";
/// STYLES ///
import { mediumAreaStyle } from "styles/general";
import { footerItemStyle } from "styles/universal/footer";
/// VALUES ///
import { black_color } from "styles/theme";
import { primary_color } from "styles/theme";
import { text_primary } from "styles/theme";
/// URLS ///
import { about_url } from "components/router/endpoints";
import { fabricator_url } from "components/router/endpoints";
import { merchant_url } from "components/router/endpoints";
import { plans_url } from "components/router/endpoints";

export default function XsFooter() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const nav = (url: string) => {
        window.scrollTo(0, 0);
        navigate(`/${url}`, {replace: false});
    } 

    return (
        
            <Box sx={[mediumAreaStyle, {paddingTop: 5,paddingBottom: 0}]}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sx={[footerItemStyle, {textAlign: "center"}]}>
                        <img src={MiniLogo} height="50" width="100" style={{marginLeft: -12}} />
                    </Grid>
                    <Grid item xs={12} sx={{height: "25px"}}></Grid>
                    <Grid item xs={12} sx={[footerItemStyle, {textAlign: "center"}]}>
                        <Typography variant="h5">
                            Sign Up
                        </Typography>
                        <Button variant="contained" onClick={() => dispatch(setTryNow(true))}>
                            Try Now
                        </Button>
                    </Grid>
                    <Grid item xs={1} sx={footerItemStyle}>
                    </Grid>
                    <Grid item xs={12} sx={[footerItemStyle, {borderBottom: 3, textAlign: "center", borderBlockColor:primary_color}]}>
                        <Typography variant="body1">
                            Join our growing team
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{height: "30px"}}></Grid>
                    <Grid item xs={12} sx={[footerItemStyle, {textAlign: "center"}]}>
                        <Button onClick={() => nav(about_url)}>
                            About
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={[footerItemStyle, {textAlign: "center"}]}>
                        <Button  onClick={() => nav(merchant_url)}>
                            Merchants
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={[footerItemStyle, {textAlign: "center"}]}>
                      <Button onClick={() => nav(fabricator_url)}>
                            Fabricators
                      </Button>
                    </Grid>
                    <Grid item xs={12} sx={[footerItemStyle, {textAlign: "center"}]}>
                        <Button onClick={() => nav(plans_url)}>
                            Plans
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={{height: "20px"}}></Grid>
                    <Grid item xs={12} sx={[footerItemStyle, {paddingLeft: 0, textAlign: "center"}]}>
                        <Button sx={{color: black_color}} onClick={() => window.open("tel:18336127747")}>
                        1-833-61-ARRIS
                        </Button> | <Button sx={{color: black_color}} onClick={() => window.open("mailto:sales@arrisstone.com")}>
                        sales@arrisstone.com
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={[footerItemStyle, {paddingLeft: 0, textAlign: "center"}]}>
                        <Button sx={{color: black_color}} onClick={() => window.open("tel:18336127747")}>
                        11336 105 Ave NW, Edmonton, AB
                        </Button>
                    </Grid>
                    <Grid item xs={6} sx={{paddingLeft: 6.15, verticalAlign:"center"}}>
                        <Typography variant="body1" >
                            @2022 Arris
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>                     
                    </Grid>
                    <Grid item xs={2}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                            <IconButton>
                                <FacebookIcon sx={{color: text_primary}} />
                            </IconButton>
                            <IconButton>
                                <TwitterIcon sx={{color: text_primary}} />
                            </IconButton>
                            <IconButton>
                                <InstagramIcon sx={{color: text_primary}} />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Box> 
        
  );
}
