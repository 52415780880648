import MdMerchantHero from "./md";
import SmMerchantHero from "./sm";
import XsMerchantHero from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}
export default function MerchantHero({is_tablet, is_phone}: Props){
    if(is_phone){
        return <XsMerchantHero />;
    }
    else if(is_tablet){
        return <SmMerchantHero />;
    }
    return <MdMerchantHero />;
}