import MdHomeHero from "./md";
import SmHomeHero from "./sm";
import XsHomeHero from "./xs";

interface Props { 
    is_tablet: boolean,
    is_phone: boolean
}

export default function HomeHero({is_tablet, is_phone}: Props){
    if(is_phone){
        return <XsHomeHero />;
    }
    else if(is_tablet){
        return <SmHomeHero />;
    }
    return <MdHomeHero />;
}