import { CutShape } from "arris_dist";

export enum ActionType {
    CREATE_CUT_SHAPES_SUCCESS="CREATE_CUT_SHAPES_SUCCESS",
    UPDATE_CUT_SHAPES_SUCCESS="UPDATE_CUT_SHAPES_SUCCESS",
    UPDATE_CUT_SHAPE_SUCCESS="UPDATE_CUT_SHAPE_SUCCESS",
    DELETE_CUT_SHAPE_SUCCESS="DELETE_CUT_SHAPE_SUCCESS",
    SET_SELECTED_CUT_SHAPE="SET_SELECTED_CUT_SHAPE"
}

interface deleteCutShapeSuccess {
    type: ActionType.DELETE_CUT_SHAPE_SUCCESS,
    payload: string
}

interface updateCutShapesSuccess {
    type: ActionType.UPDATE_CUT_SHAPES_SUCCESS,
    payload: CutShape[],
    counter_uuid: string
}

interface updateCutShapeSuccess {
    type: ActionType.UPDATE_CUT_SHAPE_SUCCESS,
    payload: CutShape
}

interface createCutShapesSuccess {
    type: ActionType.CREATE_CUT_SHAPES_SUCCESS,
    payload: CutShape[],
    counter_uuid: string
}

interface setSelectedCutShape {
    type: ActionType.SET_SELECTED_CUT_SHAPE,
    payload: CutShape | null
}

export type Action = 
    createCutShapesSuccess |
    updateCutShapesSuccess |
    updateCutShapeSuccess |
    deleteCutShapeSuccess |
    setSelectedCutShape;