export const statementBoxContainer = (padding_top: number, padding_bottom: number, background_color: string) => ({
    paddingTop: padding_top,
    paddingBottom: padding_bottom,
    backgroundColor: background_color
});

export const statementBoxButtonIconStyle = (text_color: string) => ({
    height: 26,
    width: 26,
    marginLeft: 0.75,
    paddingLeft: 1,
    borderLeft: `1px solid ${text_color}`
});

export const statementBoxButtonStyle = (
    hide_try_now: boolean,
    text_color: string,
    hover_background_color: string,
    hover_text_color: string
) => ({
    display: hide_try_now ? "none" : "flex",
    marginLeft: {
        "xs": 6,
        "sm": 10,
        "md": 10
    },
    paddingLeft: 1.25,
    paddingRight: 1.25,
    border: `1px solid ${text_color}`,
    color: text_color,
    marginTop: 4,
    ":hover": {
        bgcolor: hover_background_color,
        color: hover_text_color
    }
});

export const statementBoxParagraphTextStyle = (
    text_color: string,
    border_color: string,
    padding_top: number,
    padding_left: number
) => ({
    color: text_color,
    borderLeft: `1px solid ${border_color}`,
    paddingTop: padding_top,
    paddingLeft: padding_left,
    paddingRight: {
        "md":"30%",
        "xs":"10%"
    },
    lineHeight: 1.5
});

export const statementBoxSubtitleTextStyle = (
    text_color: string,
    border_color: string,
    padding_left: number,
    padding_bottom: number
) => ({
    color: text_color,
    borderLeft: `3px solid ${border_color}`,
    paddingLeft: padding_left,
    paddingRight: 8,
    paddingBottom: padding_bottom,
    lineHeight: 2
});

export const statementBoxTitleTextStyle = (
    text_color: string,
    border_color: string,
    padding_left: number,
    padding_bottom: number
) => ({
    color: text_color,
    borderLeft: `1px solid ${border_color}`,
    paddingRight: 8,
    paddingBottom: padding_bottom,
    paddingLeft: padding_left
});