import MdTimelineBox from "./md";
import SmTimelineBox from "./sm";
import XsTimelineBox from "./xs";

interface Props {
    is_tablet?: boolean,
    is_phone?: boolean,
    year: string,
    title: string,
    paragraph: string,
    shift_right?: boolean
}

export default function TimelineBox({year, title, paragraph, is_tablet=false, is_phone=false, shift_right=false}: Props) {
    if(is_phone){
        return (
            <XsTimelineBox 
                year={year}
                title={title}
                paragraph={paragraph}
                shift_right={shift_right} />
        )
    }
    else if(is_tablet){
        return (
            <SmTimelineBox 
                year={year}
                title={title}
                paragraph={paragraph}
                shift_right={shift_right} />
        )
    }
    return (
        <MdTimelineBox 
            year={year}
            title={title}
            paragraph={paragraph}
            shift_right={shift_right} />
    );
}
