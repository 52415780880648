import MerchantHero from "static/merchant_hero.webp";
import MerchantHeroXs from "static/merchant_hero_xs.jpg";
import { black_color, white_color } from "styles/theme";

export const merchantHeroBannerStyle = {
    marginTop: "-10vh",
    backgroundImage: {
        "md":`url(${MerchantHero})`,
        "xs": `url(${MerchantHeroXs})`,
    },
    backgroundSize: "cover",
    minHeight: {
        "xs": "25vh",
        "md": "75vh"
    }
}

export const merchantHeroTitleTextStyle = {
    paddingLeft: {
        "xs": "10%",
        "sm": "10%",
        "md": "25%"
    },
    paddingRight: {
        "xs": "10%",
        "sm": "10%",
        "md": "25%"
    },
    lineHeight: 2,
    fontSize: {
        "xs": 15,
        "sm": 35,
        "md": 40
    },
    color: {
        "xs": white_color,
        "md": black_color
    }
}
export const textBG = {
    background: "#000",
    opacity: 0.6
}