import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ParallaxBanner } from "react-scroll-parallax";
import StatementBox from "components/universal/statement_box";
import { Row2 } from "values/text/home";
import { white_color } from "styles/theme";
import { primary_color } from "styles/theme";
import { largePictureAreaStyle } from "styles/general";
import Pic1 from "static/pic1.webp";
import { heroVisionContainerStyle } from "styles/home_page/vision";
import { heroVisionContainerInnerWrapperStyle } from "styles/home_page/vision";
import { heroVisionImageOuterWrapperStyle } from "styles/home_page/vision";
import { heroVisionImageInnerWrapperStyle } from "styles/home_page/vision";
import { heroVisionMdImageStyle } from "styles/home_page/vision";

export default function MdVision(){
    return (
        <Box sx={[largePictureAreaStyle, heroVisionContainerStyle]}>
            <Grid container sx={heroVisionContainerInnerWrapperStyle}>
                <StatementBox 
                    title={Row2.TITLE}
                    subtitle1={Row2.SUBTITLE_1}
                    subtitle2={Row2.SUBTITLE_2}
                    subtitle3={Row2.SUBTITLE_3}
                    paragraph={Row2.PARAGRAPH_1}
                    text_color={white_color}
                    background_color={primary_color}
                    border_color={white_color} 
                    paddingTop={10} 
                    paddingBottom={10}/>
                <Grid item xs={6} sx={heroVisionImageOuterWrapperStyle}>
                    <Box sx={heroVisionImageInnerWrapperStyle}>
                        <ParallaxBanner 
                            style={heroVisionMdImageStyle}
                            layers={[{
                                image: Pic1,
                                translateX: [0, -30],
                                shouldAlwaysCompleteAnimation: true,
                                expanded: false,
                            }]}>
                        </ParallaxBanner>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}