import Box from "@mui/material/Box";
import { ParallaxBanner } from "react-scroll-parallax";
import AboutPic2 from "static/About2.png";
import { aboutSecondBannerContainerStyle } from "styles/about_page/second_banner";
import { aboutSecondBannerParallaxImageStyle } from "styles/about_page/second_banner";

export default function SecondBanner(){
    return (
        <Box sx={aboutSecondBannerContainerStyle}>
            <ParallaxBanner 
                style={aboutSecondBannerParallaxImageStyle}
                layers={[{
                    image: AboutPic2,
                    translateY: [-45, 50],
                    shouldAlwaysCompleteAnimation: true,
                    expanded: false,
                }]}>
            </ParallaxBanner>
        </Box>
    )
}