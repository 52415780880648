import { useAppDispatch } from "hooks/api";
import { setTryNow } from "api/actions/ui";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Grid";
import { StatementBoxTitle } from "../text";
import { StatementBoxSubtitle } from "../text";
import { StatementBoxParagraph } from "../text";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { primary_color } from "styles/theme";
import { text_primary } from "styles/theme";
import { white_color } from "styles/theme";
import { statementBoxButtonStyle } from "styles/universal/statement_box";
import { statementBoxButtonIconStyle } from "styles/universal/statement_box";

interface Props {
    title: string,
    subtitle1?: string,
    subtitle2?: string,
    subtitle3?: string,
    paragraph?: string,
    paragraph2?: string,
    text_color?: string,
    hover_text_color?: string,
    background_color?: string,
    hover_background_color?: string,
    border_color?: string,
    borderRadius?: number,
    paddingTop?: number,
    paddingLeft?: number,
    paddingBottom?: number,
    marginLeft?: number,
    marginTop?: number,
    hide_try_now?: boolean
}

export default function MdStatementBox({
    title,
    subtitle1,
    subtitle2,
    subtitle3,
    paragraph,
    paragraph2,
    text_color=text_primary,
    hover_text_color=primary_color,
    background_color=white_color,
    hover_background_color=white_color,
    border_color=text_primary,
    borderRadius=0,
    paddingTop=0,
    paddingBottom=0,
    paddingLeft=0,
    marginLeft=0,
    marginTop=0,
    hide_try_now
}: Props) {
    const dispatch = useAppDispatch();

    return (
        <Grid container item xs={6}>
            <Grid 
                item
                xs={0} md={3}
                sx={{
                    paddingTop: `${paddingTop}vh`,
                    paddingLeft: `${paddingLeft}vh`,
                    paddingBottom: `${paddingBottom}vh`,
                    marginLeft: `${marginLeft}vh`,
                    marginTop: `${marginTop}vh`
                }}></Grid>
            <Grid 
                item
                xs={12} md={9}
                sx={{
                    borderRadius: borderRadius,
                    backgroundColor: background_color,
                    paddingTop: `${paddingTop}vh`,
                    paddingLeft: `${paddingLeft}vh`,
                    paddingBottom: `${paddingBottom}vh`,
                    marginLeft: `${marginLeft}vh`,
                    marginTop: `${marginTop}vh`
                }}>
                <Stack>
                    <StatementBoxTitle text_color={text_color} border_color={border_color} title={title}/>
                    <StatementBoxSubtitle 
                        text_color={text_color}
                        border_color={border_color}
                        subtitle={subtitle1}
                        paddingBottom={subtitle2 || subtitle3 ? 1 : 4} />
                    <StatementBoxSubtitle 
                        text_color={text_color}
                        border_color={border_color}
                        subtitle={subtitle2}
                        paddingBottom={subtitle3 ? 1 : 2} />
                    <StatementBoxSubtitle 
                        text_color={text_color}
                        border_color={border_color}
                        subtitle={subtitle3}
                        paddingBottom={0} />
                    <StatementBoxParagraph text_color={text_color} border_color={border_color} text={paragraph} />
                    <StatementBoxParagraph text_color={text_color} border_color={border_color} text={paragraph2} />
                    <Button 
                        onClick={() => dispatch(setTryNow(true))}
                        endIcon={<ArrowForwardIcon sx={statementBoxButtonIconStyle(text_color)}/>}
                        sx={statementBoxButtonStyle(false, text_color, hover_background_color, hover_text_color)}>
                        Try Now
                    </Button>
                </Stack>
            </Grid>
        </Grid>
  );
}
