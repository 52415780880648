/// TYPES ///
import { OrderArea } from "arris_dist";
import { SelfOrderArea } from "arris_dist";
/// COMPONENTS ///
import { View } from "@react-pdf/renderer";
import OrderLineItems from "./line_items";

interface Props {
    areas: OrderArea[] | SelfOrderArea[]
}

export default function CustomerOrderAreas({areas}: Props) {    
    return (
        <View style={{marginTop: "2px", width: "100%"}}>
            <View style={{width: "100%", paddingLeft: "10px", paddingRight: "10px"}}>
                {
                    areas.map((area) => {
                        return (
                            <OrderLineItems 
                                line_items={area.order_line_items.filter(item => item.customer_line_item)}
                                key={area.id} />
                        )
                    })
                }
            </View>
        </View>
    );
}