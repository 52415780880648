import MdControl from "./md";
import XsControl from "./xs";

interface Props {
    is_mobile: boolean
}

export default function Control({is_mobile}: Props){
    if(is_mobile){
        return <XsControl />;
    }
    return <MdControl />;
}