import { useEffect } from "react";
import { useSpring } from "@react-spring/web";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { animated } from "@react-spring/web";
import { aboutTimelineBoxStyle } from "styles/about_page/timeline";
import { aboutTimelineBoxTitleStyle } from "styles/about_page/timeline";
import { aboutTimelineSeparatorStyle } from "styles/about_page/timeline";
import { aboutTimelineStackStyle } from "styles/about_page/timeline";
import { aboutTimelineYearStyle } from "styles/about_page/timeline";

interface Props {
    year: string,
    title: string,
    paragraph: string,
    shift_right?: boolean
}

export default function XsTimelineBox({
    year,
    title,
    paragraph,
    shift_right
}: Props) {
    const [styles, api] = useSpring(
        () => ({
          x: 0
        }), []
    );

    useEffect(() => {
        api.start({
            config: {duration: 400},
            to: [
              {x: shift_right ? 0 : -0},
            ],
        });
    }, []);

    return (
        <animated.div
            className="spring-box"
            style={{...styles}}>
            <Box sx={aboutTimelineBoxStyle}>
                <Stack sx={aboutTimelineStackStyle}>
                    <Typography variant="h2" sx={aboutTimelineYearStyle}>
                        {year}
                    </Typography>
                    <Box sx={aboutTimelineSeparatorStyle}></Box>
                    <Typography variant="h6" sx={aboutTimelineBoxTitleStyle}>
                        {title}
                    </Typography>
                    <Typography variant="body1">
                        {paragraph}
                    </Typography>
                </Stack>
            </Box>
        </animated.div>
  );
}
