import { useState } from "react";
import { useEffect } from "react";
import { useAppDispatch } from 'hooks/api';
import { useAppSelector } from 'hooks/api';
import { resetPassword } from "api/actions/password_reset";
import { resetPasswordValues } from "api/actions/password_reset";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { validateEmail } from "functions/email";
import { primary_color } from "styles/theme";
import { text_primary } from "styles/theme";
import { error_color } from "styles/theme";

export default function PasswordReset() {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");
  const [password_reset_complete, setPasswordResetComplete] = useState<boolean>(false);
  const { loading, error } = useAppSelector((state) => state.authentication);
  const [has_error, setHasError] = useState<boolean>(false);
  const [local_error, setLocalError] = useState<string>("");

  useEffect(() => {
    dispatch(resetPasswordValues());
  }, [dispatch]);

  const handleResetPassword = () => {
    if (!validateEmail(email)) {
      setHasError(true);
      setLocalError("Please enter a valid email");
      return;
    }

    dispatch(resetPassword(email));
    setPasswordResetComplete(true);
  }

  return (
    <Box sx={{
      padding: {
        "md": "20vh",
        "xs": "2vh"
      }
    }}>
      {
        password_reset_complete ?
          <Stack>
            <Typography variant="h6" sx={{ paddingLeft: 3, paddingBottom: 1.5, color: text_primary }}>
              A password reset has been sent to the email address. If you do not see the email, please check your junk or spam folder.
            </Typography>
          </Stack> :
          <Stack>
            <Typography variant="h6" sx={{ paddingLeft: 3, paddingBottom: 1.5, color: text_primary }}>
              Enter your email address
            </Typography>
            {
              error !== "" ?
                <Typography variant="body2" sx={{ paddingLeft: 3, paddingTop: 0, paddingBottom: 0, color: error_color }}>
                  {error}
                </Typography> :
                null
            }

            {
              has_error && local_error !== "" ?
                <Typography variant="body2" sx={{ padding: 3, paddingTop: 0, paddingBottom: 0, color: error_color }}>
                  {local_error}
                </Typography> :
                null
            }

            <TextField
              autoCapitalize="none"
              type="email"
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  color: primary_color
                }
              }}
              InputProps={{
                style: {
                  color: text_primary
                }
              }}
              sx={{ marginLeft: 3, marginBottom: 2, marginTop: 1.5, width: 400, color: text_primary }}
              size="small"
              error={has_error}
              onChange={(e) => setEmail(e.target.value)}
              label="Email" />
            {
              loading ?
                <CircularProgress /> :
                <Button
                  onClick={handleResetPassword}
                  variant="contained"
                  sx={{ width: 150, marginTop: 1.5, marginLeft: 3 }}>
                  Send Reset
                </Button>
            }

          </Stack>
      }

    </Box>
  );
}
