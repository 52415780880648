import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StatementBox from "components/universal/statement_box";
import { largePictureAreaStyle } from "styles/general";
import { black_color } from "styles/theme";
import { white_color } from "styles/theme";
import { text_primary } from "styles/theme";
import { Streamline } from "values/text/merchant";
import { merchantStreamlineContainerStyle } from "styles/merchant/streamline";
import { merchantStreamlineImageStyle } from "styles/merchant/streamline";
import { merchantStreamlineGridStyle } from "styles/merchant/streamline";

export default function MdStreamline(){
    return (
        <Box  sx={[largePictureAreaStyle, merchantStreamlineContainerStyle]}>
            <Grid container sx={merchantStreamlineGridStyle}>
                <StatementBox 
                    title={Streamline.TITLE}
                    subtitle1={Streamline.PARAGRAPH}
                    text_color={white_color}
                    hover_text_color={text_primary}
                    background_color={black_color}
                    border_color={white_color}
                    hover_background_color={white_color}
                    paddingTop={10} />
                <Grid item xs={6} sx={merchantStreamlineImageStyle}>
                </Grid>
            </Grid>
        </Box>
    )
}