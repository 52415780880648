import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/api";
import { setTryNow } from "api/actions/ui";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { mediumAreaStyle } from "styles/general";
import { footerItemStyle } from "styles/universal/footer";
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MiniLogo from "static/ArrisMiniLogo.webp";
import { text_primary } from "styles/theme";
import { about_url } from "components/router/endpoints";
import { fabricator_url } from "components/router/endpoints";
import { merchant_url } from "components/router/endpoints";
import { plans_url } from "components/router/endpoints";

export default function SmFooter() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const nav = (url: string) => {
        window.scrollTo(0, 0);
        navigate(`/${url}`, {replace: false});
    } 

    return (
        <Box>
            <Box sx={mediumAreaStyle}>
                <Grid container alignItems="center">
                    <Grid item xs={1} sx={footerItemStyle}></Grid>
                    <Grid item xs={11} sx={footerItemStyle}>
                        <img src={MiniLogo} height="50" width="100" style={{marginLeft: -12}} />
                    </Grid>
                    <Grid item xs={12} sx={{height: "25px"}}></Grid>
                    <Grid item xs={1.15}></Grid>
                    <Grid item xs={5.85} sx={footerItemStyle}>
                        <Typography variant="h5">
                            Sign Up
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={footerItemStyle}></Grid>
                    <Grid item xs={3} sx={footerItemStyle}>
                        <Button variant="contained" onClick={() => dispatch(setTryNow(true))}>
                            Try Now
                        </Button>
                    </Grid>
                    <Grid item xs={1} sx={footerItemStyle}>
                    </Grid>
                    <Grid item xs={1.15} sx={footerItemStyle}>
                    </Grid>
                    <Grid item xs={10.85} sx={footerItemStyle}>
                        <Typography variant="body1">
                            Join our growing team
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{height: "50px"}}></Grid>
                    <Grid item xs={1} sx={footerItemStyle}></Grid>
                    <Grid item xs={5} sx={footerItemStyle}>
                        <Button onClick={() => nav(about_url)}>
                            About
                        </Button>
                    </Grid>
                    <Grid item xs={6} sx={footerItemStyle}>
                        <Typography variant="body1">
                            Tel: 1-833-61-ARRIS
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={1} sx={footerItemStyle}></Grid>
                    <Grid item xs={5} sx={footerItemStyle}>
                        <Button onClick={() => nav(merchant_url)}>
                            Merchants
                        </Button>
                    </Grid>
                    <Grid item xs={6} sx={footerItemStyle}>
                        <Typography variant="body1">
                            Email: support@arrisstone.com
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={1} sx={footerItemStyle}></Grid>
                    <Grid item xs={5} sx={footerItemStyle}>
                      <Button onClick={() => nav(fabricator_url)}>
                            Fabricators
                      </Button>
                    </Grid>
                    <Grid item xs={6} sx={footerItemStyle}>
                        <Typography variant="body1">
                            11336 105 Ave NW, Edmonton, AB
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={footerItemStyle}></Grid>
                    <Grid item xs={11} sx={footerItemStyle}>
                        <Button onClick={() => nav(plans_url)}>
                            Plans
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={{height: "50px"}}></Grid>
                </Grid>
            </Box> 
            <Box>
                <Grid container>
                    <Grid item xs={1} sx={{paddingBottom: 6}}></Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" sx={{paddingBottom: 6}}>
                            @2022 Arris
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        
                    </Grid>
                    <Grid item xs={2}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                            <IconButton>
                                <FacebookIcon sx={{color: text_primary}} />
                            </IconButton>
                            <IconButton>
                                <TwitterIcon sx={{color: text_primary}} />
                            </IconButton>
                            <IconButton>
                                <InstagramIcon sx={{color: text_primary}} />
                            </IconButton>
                        </Stack>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Box>
        </Box>
  );
}
