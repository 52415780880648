import Box from "@mui/material/Box";
import StatementBox from "components/universal/statement_box";
import { ParallaxBanner } from "react-scroll-parallax";
import { Parallax } from "react-scroll-parallax";
import Pic5 from "static/pic5.jpg";
import { Row7 } from "values/text/home";
import { primary_color } from "styles/theme";
import { white_color } from "styles/theme";
import { homeEdgeContainerStyle } from "styles/home_page/edge";
import { homeEdgeImageStyle } from "styles/home_page/edge";
import { homeEdgeImageWrapperStyle } from "styles/home_page/edge";

export default function MdEdge(){
    return (
        <Box sx={homeEdgeContainerStyle}>
            <Box sx={homeEdgeImageWrapperStyle}>
                <ParallaxBanner 
                    style={homeEdgeImageStyle}
                    layers={[{
                        image: Pic5,
                        translateY: [-45, 50],
                        shouldAlwaysCompleteAnimation: true,
                        expanded: false,
                    }]}>
                        <Parallax translateY={[45, -40]}>
                            <StatementBox 
                                title={Row7.TITLE}
                                subtitle1={Row7.SUBTITLE_1}
                                subtitle2={Row7.SUBTITLE_2}
                                subtitle3={Row7.SUBTITLE_3}
                                paragraph={Row7.PARAGRAPH_1}
                                text_color={white_color}
                                background_color={primary_color}
                                border_color={white_color} 
                                borderRadius={4}
                                paddingTop={10}
                                paddingBottom={10}
                                paddingLeft={5}
                                marginLeft={-2.5}
                                marginTop={5} />
                        </Parallax>
                </ParallaxBanner>
            </Box>
        </Box>
    )
}