import Merchant2 from "static/merchant2.webp";
import { large_area_no_padding } from "styles/values";
import { black_color } from "styles/theme";

export const merchantStreamlineContainerStyle = {
    backgroundColor: black_color
}

export const merchantStreamlineGridStyle = {
    height: "100%"
}

export const merchantStreamlineImageStyle = {
    height: {
        "xs": `${large_area_no_padding}vh`,
        "sm": `${large_area_no_padding}vh`,
        "md": "auto"
    },
    backgroundImage: `url(${Merchant2})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionY: "10%"
}
