import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Grid";
import { commentBoxTitle } from "styles/universal/comment_box";
import { commentBoxParagraph } from "styles/universal/comment_box";

interface Props {
    title: string,
    paragraph: string,
    text_color: string,
    right?: boolean
}

export default function SmCommentBox({title, paragraph, text_color, right}: Props) {
    return (
        <Grid container item xs={12}>
            {
                right ?
                <Grid item xs={0}></Grid> : 
                <Grid item xs={3}></Grid>
            }
            <Grid item xs={9}>
                <Stack>
                    <Typography variant="h6" sx={commentBoxTitle(text_color)}>
                        {title}
                    </Typography>
                    {
                        paragraph ?
                        <Typography variant="body1" sx={commentBoxParagraph(text_color)}>
                            {paragraph}
                        </Typography> :
                        null
                    }
                </Stack>
            </Grid>
            {
                right ?
                <Grid item xs={3}></Grid> : 
                null
            }
        </Grid>
  );
}
