import { SelfOrderCounter } from "arris_dist";

export enum ActionType {
    GET_SELF_ORDER_COUNTERS_SUCCESS="GET_SELF_ORDER_COUNTERS_SUCCESS"
}

interface IGetSelfOrderCountersSuccess {
    type: ActionType.GET_SELF_ORDER_COUNTERS_SUCCESS,
    self_order_counters: SelfOrderCounter[]
}

export type Action =
    IGetSelfOrderCountersSuccess;