import MdAccess from "./md";
import SmAccess from "./sm";
import XsAccess from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function Access({is_tablet, is_phone}: Props){
    if(is_tablet){
        return <XsAccess />;
    }
    else if(is_phone){
        <SmAccess />;
    }
    return <MdAccess />;
}