export const primary_color: string = "#0063fe";
export const secondary_color: string = "#f6be41";
export const transparent_secondary_color: string = "rgba(254, 155, 0, 0.8)";
export const error_color: string = "#ec503e";
export const canvas_error_color: string = "#FF3719";
export const well_color: string = "#e9effe";
export const text_primary: string = "#333333";
export const text_secondary: string = "#202528";
export const black_color: string = "#000000";
export const white_color: string = "#ffffff";
export const grey_color: string = "#888888";
export const dark_grey_color: string = "#757575";
export const light_grey_color: string = "#D4D4D4";
export const transparent_color: string = "rgba(52, 52, 52, 0)";