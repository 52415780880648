import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StatementBox from "components/universal/statement_box";
import { Row2 } from "values/text/about";
import { text_primary } from "styles/theme";
import { white_color } from "styles/theme";
import { largeAreaStyle } from "styles/general";
import { aboutMissionPictureStyle } from "styles/about_page/mission";
import { aboutMissionInnerContainerStyle } from "styles/about_page/mission";
import { aboutMissionOuterContainerStyle } from "styles/about_page/mission";

export default function XsOurMission(){
    return (
        <Box sx={[largeAreaStyle, aboutMissionOuterContainerStyle]}>
            <Grid container sx={aboutMissionInnerContainerStyle}>
                <StatementBox 
                    title={Row2.TITLE}
                    subtitle1={Row2.SUBTITLE_1}
                    subtitle2={Row2.SUBTITLE_2}
                    paragraph={Row2.PARAGRAPH_1}
                    paragraph2={Row2.PARAGRAPH_2}
                    text_color={text_primary}
                    is_phone={true}
                    background_color={white_color}
                    border_color={text_primary} />
                <Grid item xs={6} sx={aboutMissionPictureStyle}>
                </Grid>
            </Grid>
        </Box>
    )
}