export const hero_area_transparent_nav: number = 75;
export const large_area_no_padding: number = 75;
export const large_area: number = 55;
export const medium_area: number = 40;
export const small_area: number = 25;

export const mobile_area_vertical_padding: number = 5;
export const area_vertical_padding: number = 10;

export const paragraph_subtitle_item_spacing: number = 2;
export const paragraph_subtitle_padding: number = 3;