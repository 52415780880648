/// HOOKS ///
import { useEffect } from "react";
import { useState } from "react";
import { useAppSelector } from "hooks/api";
/// COMPONENTS ///
import Box from "@mui/material/Box";
import { PDFViewer } from "@react-pdf/renderer";
import { Page } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import { Image } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import PrintOrderSlabs from "./slabs";
import PrintOrderCounters from "./counters";
import CustomerOrderAreas from "./customer_order_areas";
import PrintOrderSummary from "./order_summary";
import PrintHeader from "../shared/header";
import PrintSummaryHeaders from "../shared/summary_headers";
import PrintTerms from "../shared/terms";
import PrintSignature from "../shared/signature";
/// IMAGES ///
import PowerByLogo from "static/powered_by_logo.png";
/// VALUES ///
import { primary_color } from "arris_dist";

export default function PrintSelfOrder() {
    const [logo, setLogo] = useState<string | null>(null);
    const [logo_read, setLogoRead] = useState<boolean>(false);
    const { self_order } = useAppSelector((state) => state.self_order);
    const { self_order_counters } = useAppSelector((state) => state.self_order);
    const { self_order_areas } = useAppSelector((state) => state.self_order);
    const { self_order_slabs } = useAppSelector((state) => state.self_order);
    const { vendor } = useAppSelector((state) => state.self_order);

    useEffect(() => {
        if(vendor.logo){
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
                var reader = new FileReader();
                reader.onloadend = function() {
                    setLogo(reader.result as string);
                    setLogoRead(true);
                }
                reader.readAsDataURL(xhr.response);
            };
            if(typeof(vendor.logo) === "string"){
                xhr.open("GET", vendor.logo);
                xhr.responseType = "blob";
                xhr.send();
            }
        }
        else{
            setLogoRead(true);
        }
    }, [vendor.logo]);

    Font.register({
        family: "Montserrat",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
            },
            {
                src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
                fontWeight: "bold"
            }
        ]
    });

    const styles = StyleSheet.create({
        page: {
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "50px",
            paddingBottom: "50px"
        },
        logo: {
            height: "40px",
            width: "80px",
            objectFit: "contain",
            objectPosition: "left"
        },
        footer: {
            backgroundColor: primary_color,
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            height: "25px",
            paddingBottom: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        footer_image: {
            height: "20px",
            width: "100px"
        }
    });
            
    return (
        <Box display="flex" justifyContent="center" sx={{height: "100vh", width: "100%", paddingTop: "80px"}}>
            {
                self_order && self_order.id && vendor && vendor.id && logo_read ?
                <PDFViewer width="795px">
                    <Document>
                        <Page size="A4" style={styles.page}>
                            <PrintHeader 
                                title="PURCHASE ORDER"
                                number={self_order.order_number}
                                logo_url={logo ? logo : ""} 
                                seller_name={vendor.business_name ? vendor.business_name : ""}
                                seller_address={vendor.address ? vendor.address : ""}
                                seller_city={vendor.city ? vendor.city : ""}
                                seller_province={vendor.province ? vendor.province : ""}
                                seller_postal_code={vendor.postal_code ? vendor.postal_code : ""}
                                buyer_name={
                                    self_order && self_order.end_customer_data ? 
                                    `${self_order.end_customer_data.first_name} ${self_order.end_customer_data.last_name}` :
                                    ""
                                }
                                buyer_address={self_order && self_order.end_customer_data ? self_order.end_customer_data.address : ""}
                                buyer_city={self_order && self_order.end_customer_data ? self_order.end_customer_data.city : ""}
                                buyer_province={self_order && self_order.end_customer_data ? self_order.end_customer_data.province : ""}
                                buyer_postal_code={self_order && self_order.end_customer_data ? self_order.end_customer_data.postal_code : ""}
                                date={self_order.created} />
                            <PrintSummaryHeaders />
                            <CustomerOrderAreas areas={self_order_areas} />
                            <PrintOrderSummary order={self_order} />
                            <PrintOrderCounters counters={self_order_counters} />
                            {
                                self_order_areas.map(area => {
                                    return (
                                        <PrintOrderSlabs slabs={self_order_slabs.filter(slab => slab.area === area.id)} key={area.id}/>
                                    )
                                })
                            }
                            <PrintTerms payment_terms={vendor.payment_terms ? vendor.payment_terms : ""} />
                            <PrintSignature />
                            <View style={styles.footer} fixed>
                                <Image src={PowerByLogo} style={styles.footer_image} />
                            </View>
                        </Page>
                    </Document>
                </PDFViewer> :
                null
            }
        </Box>
    );
}