import FabricatorHero from "static/fab_hero.webp";
import { white_color } from "styles/theme";

export const fabricatorHeroContainerStyle = {
    paddingTop: {
        "xs": "5vh",
        "sm": "5vh",
        "md": "10vh"
    },
    marginTop: "-10vh",
    backgroundImage: `url(${FabricatorHero})`,
    backgroundSize: "cover",
    backgroundPositionY: "50%",
    backgroundRepeat: "no-repeat"
}

export const fabricatorHeroTitleTextStyle = {
    paddingLeft: "25%",
    paddingRight: "25%",
    lineHeight: 2,
    color: white_color,
    paddingTop: "55vh"
}