import { primary_color } from "styles/theme";
import { text_primary } from "styles/theme";
import { white_color } from "styles/theme";
import MdStatementBox from "./md";
import SmStatementBox from "./sm";
import XsStatementBox from "./xs";

interface Props {
    title: string,
    subtitle1?: string,
    subtitle2?: string,
    subtitle3?: string,
    font_size?: number
    paragraph?: string,
    paragraph2?: string,
    text_color?: string,
    hover_text_color?: string,
    background_color?: string,
    hover_background_color?: string,
    border_color?: string,
    borderRadius?: number,
    paddingTop?: number,
    paddingLeft?: number,
    paddingBottom?: number,
    marginLeft?: number,
    marginTop?: number,
    navigation?: string,
    is_tablet?: boolean,
    is_phone?: boolean,
    hide_try_now?: boolean
}

export default function StatementBox({
    title,
    subtitle1,
    subtitle2,
    subtitle3,
    paragraph,
    paragraph2,
    text_color=text_primary,
    hover_text_color=primary_color,
    background_color=white_color,
    hover_background_color=white_color,
    border_color=text_primary,
    borderRadius=0,
    paddingTop=0,
    paddingBottom=0,
    paddingLeft=0,
    marginLeft=0,
    marginTop=0,
    is_tablet=false,
    is_phone=false,
    hide_try_now=false
}: Props){
    if(is_phone){
        return (
            <XsStatementBox 
                title={title}
                subtitle1={subtitle1}
                subtitle2={subtitle2}
                subtitle3={subtitle3}
                paragraph={paragraph}
                paragraph2={paragraph2}
                text_color={text_color}
                hover_text_color={hover_text_color}
                background_color={background_color}
                hover_background_color={hover_background_color}
                border_color={background_color}
                borderRadius={borderRadius}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                marginLeft={marginLeft}
                marginTop={marginTop}
                hide_try_now={hide_try_now} />
        )
    }
    else if(is_tablet){
        return(
            <SmStatementBox 
                title={title}
                subtitle1={subtitle1}
                subtitle2={subtitle2}
                subtitle3={subtitle3}
                paragraph={paragraph}
                paragraph2={paragraph2}
                text_color={text_color}
                hover_text_color={hover_text_color}
                background_color={background_color}
                hover_background_color={hover_background_color}
                border_color={background_color}
                borderRadius={borderRadius}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                marginLeft={marginLeft}
                marginTop={marginTop}
                hide_try_now={hide_try_now} />
        )
    }

    return (
        <MdStatementBox 
            title={title}
            subtitle1={subtitle1}
            subtitle2={subtitle2}
            subtitle3={subtitle3}
            paragraph={paragraph}
            paragraph2={paragraph2}
            text_color={text_color}
            hover_text_color={hover_text_color}
            background_color={background_color}
            hover_background_color={hover_background_color}
            border_color={border_color}
            borderRadius={borderRadius}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            marginLeft={marginLeft}
            marginTop={marginTop}
            hide_try_now={hide_try_now}  />
    )
}