import { User } from "arris_dist";

export enum ActionType {
    GET_QUOTE_VENDOR_SUCCESS="GET_QUOTE_VENDOR_SUCCESS"
}

interface IGetQuoteVendor {
    type: ActionType.GET_QUOTE_VENDOR_SUCCESS,
    vendor: User
}

export type Action = IGetQuoteVendor;