import { Fragment } from "react";
import { useAppDispatch } from "hooks/api";
import { setTryNow } from "api/actions/ui";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { planTextStyle } from "styles/plans";
import { planPriceWrapperStyle } from "styles/plans";
import { planBillingPeriodTextStyle } from "styles/plans";
import { planTrialTextStyle } from "styles/plans";
import { planButtonStyle } from "styles/plans";

interface Props {
    title: string,
    currency: string,
    price: string,
    price_period: string,
    billing_period: string,
    trial_period: string,
    trial_promo: string
}

export default function PlanPriceStack({
    title,
    currency,
    price,
    price_period,
    billing_period,
    trial_period,
    trial_promo
}: Props){
    const dispatch = useAppDispatch();

    return (
        <Fragment>
            <Typography variant="h6" sx={planTextStyle} textAlign="center">
                {title}
            </Typography>
            <Stack direction="row" sx={planPriceWrapperStyle} justifyContent="center">
                <Typography variant="body2">
                    {currency}
                </Typography>
                <Typography variant="h1">
                    {price}
                </Typography>
            </Stack>
            <Typography variant="body2" textAlign="center">
                {price_period}
            </Typography>
            <Typography variant="body1" textAlign="center" sx={planBillingPeriodTextStyle}>
                {billing_period}
            </Typography>
            <Typography variant="body2" textAlign="center" sx={planTrialTextStyle}>
                {trial_period}
            </Typography>
            <Button variant="contained" sx={planButtonStyle} onClick={() => dispatch(setTryNow(true))}>
                {trial_promo}
            </Button>
        </Fragment>
    )
}
