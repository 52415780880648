import { text_primary } from "styles/theme";

export const aboutTimelineContainerStyle = {
    minHeight: "80vh",
    paddingBottom: "5vh",
    paddingTop: {
        "md": "10vh",
        "sm": 2,
        "xs": "10vh"
    }
}

export const aboutTimelineTitleStyle = {
    paddingLeft: 5,
    paddingBottom: 5
}

export const aboutTimelineGridGutterStyle = {
    marginTop: 1.5
}

export const aboutTimelineGridCenterGridStyle = {
    marginTop: 3
}

export const aboutTimelineArrowContainerStyle = {
    height: "70vh"
}

export const aboutTimelineCenterContainerStyle = {
    minHeight: {
        "md": "30vh",
        "sm": "30vh",
        "xs": "auto"
    },
    height: {
        "md": "auto",
        "sm": "auto",
        "xs": "100%"
    },
    padding: {
        "md": "1vh",
        "sm": "1vh",
        "xs": 0
    }
}

export const aboutTimelineDividerOuterWrapperStyle = {
    minHeight: "5vh"
}

export const aboutTimelineDividerInnerWrapperStyle = {
    height: "100%"
}

export const aboutTimelineDividerStyle = {
    height: "1px",
    backgroundColor: text_primary,
    width: "100%"
}

export const aboutTimelineCenterInnerContainerStyle = {
    height: "100%"
}

export const aboutTimelineBoxStyle = {
    transition: "margin-right 2s ease-in-out",
    borderRadius: 5,
    border: `1px solid ${text_primary}`,
    width: {
        "md": 600,
        "sm": 400,
        "xs": 300
    },
    height: {
        "md": "100%",
        "sm": "100%",
        "xs": "auto"
    },
    minHeight: {
        "md": "auto",
        "sm": "auto",
        "xs": 200
    }
}

export const aboutTimelineStackStyle = {
    padding: 5
}

export const aboutTimelineYearStyle = {
    color: "#e15f09",
    marginBottom: 2
}

export const aboutTimelineSeparatorStyle = {
    marginBottom: 2.5,
    backgroundColor: text_primary,
    width: 85,
    height: "1px"
}

export const aboutTimelineBoxTitleStyle = {
    color: text_primary,
    marginBottom: 2
}

