import { OrderArea } from "arris_dist";
import { OrderCounter } from "arris_dist";
import { OrderSlab } from "arris_dist";
import { Order } from "arris_dist";
import { User } from "arris_dist";
import { Action } from "api/action_types/order";
import { ActionType } from "api/action_types/order";

interface State {
    order_counters: OrderCounter[],
    order: Order | null,
    vendor: User,
    order_areas: OrderArea[],
    order_slabs: OrderSlab[],
    loading: boolean,
    error: string
}

const initialState = {
    order_counters: [],
    order: null,
    order_areas: [],
    order_slabs: [],
    vendor: {},
    loading: false,
    error: ""
}

export const orderReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.GET_ORDER_SUCCESS:
            return {
                ...state,
                order: action.order,
                loading: false,
                error: ""
            }
        case ActionType.GET_ORDER_AREAS_SUCCESS:
            return {
                ...state,
                order_areas: action.order_areas
            }
        case ActionType.GET_ORDER_COUNTERS_SUCCESS:
            return {
                ...state,
                order_counters: action.order_counters,
                loading: false,
                error: ""
            }
        case ActionType.GET_ORDER_SLABS_SUCCESS:
            return {
                ...state,
                order_slabs: action.order_slabs,
                loading: false,
                error: ""
            }
        case ActionType.GET_ORDER_VENDOR_SUCCESS:
            return {
                ...state,
                vendor: action.vendor
            }
        case ActionType.ORDER_ERROR:
            return {
                ...state,
                loading: false,
                error: ""
            }
        case ActionType.ORDER_LOADING:
            return {
                ...state,
                loading: true
            }
        case ActionType.ORDER_UPDATING:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}