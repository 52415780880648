import { Material } from "arris_dist";

export enum ActionType {
    GET_MATERIALS="GET_MATERIALS",
    MATERIAL_PENDING="MATERIAL_PENDING",
    MATERIAL_FAIL="MATERIAL_FAIL"
}

interface getMaterials {
    type: ActionType.GET_MATERIALS
    payload: Material[]
}

interface materialPending {
    type: ActionType.MATERIAL_PENDING
}

interface materialFail {
    type: ActionType.MATERIAL_FAIL,
    payload: string
}

export type Action = getMaterials | materialPending | materialFail;