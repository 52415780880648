import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import PlanPriceStack from "../shared/price_stack";
import Footer from "components/universal/footer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { PLANS_HEADER } from "values/text/plans";
import { MONTHLY_PLAN } from "values/text/plans";
import { MONTHLY_PLAN_BENEFITS } from "values/text/plans";
import { YEARLY_PLAN } from "values/text/plans";
import { YEARLY_PLAN_BENEFITS } from "values/text/plans";
import { planContainerStyle } from "styles/plans";
import { planFeatureRowStyle } from "styles/plans";
import { planFeatureTextStyle } from "styles/plans";
import { plansCardStyle } from "styles/plans";
import { plansBestValueCardStyle } from "styles/plans";
import { bestValueCardInnerWrapperStyle } from "styles/plans"
import { plansValueCardStyle } from "styles/plans";
import { planBestValueTextStyle } from "styles/plans";
import { monthlyPlanStackStyle } from "styles/plans";
import { yearlyPlanStackStyle } from "styles/plans";
import { yearlyPlanBenefitStackStyle } from "styles/plans";

export default function MdPlans() {
    return(
        <Box>
            <Grid container justifyContent="center" sx={{marginBottom: 10}}>
                <Grid item xs={12}>
                    <Typography variant="h2" textAlign="center" sx={{paddingTop: "10vh"}}>
                        {PLANS_HEADER.TITLE}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" textAlign="center" sx={{paddingTop: "2vh", textTransform: "none"}}>
                        {PLANS_HEADER.SUBTITLE}
                    </Typography>
                </Grid>
                <Grid item md={2.5}></Grid>
                <Grid item md={3} sx={planContainerStyle}>
                    <Paper sx={plansCardStyle} elevation={5}>
                        <Stack alignItems="center" sx={monthlyPlanStackStyle}>
                            <PlanPriceStack 
                                title={MONTHLY_PLAN.TITLE} 
                                currency={MONTHLY_PLAN.CURRENCY} 
                                price={MONTHLY_PLAN.PRICE}
                                price_period={MONTHLY_PLAN.PRICE_PERIOD}
                                billing_period={MONTHLY_PLAN.BILLING_PERIOD}
                                trial_period={MONTHLY_PLAN.TRIAL_PERIOD}
                                trial_promo={MONTHLY_PLAN.TRIAL_PROMO} />
                        </Stack>
                        <Stack alignItems="left">
                        {
                            MONTHLY_PLAN_BENEFITS.map(benefit => {
                                return (
                                    <Stack direction="row" sx={planFeatureRowStyle} justifyContent="flex-start">
                                        <Icon color="primary" fontSize="small">
                                            <CheckCircleIcon fontSize="small"/>
                                        </Icon>
                                        <Typography variant="body2" sx={planFeatureTextStyle}>
                                            {benefit}
                                        </Typography>
                                    </Stack>
                                )
                            })
                        }
                            <Stack direction="row" sx={planFeatureRowStyle}>
                                <Typography variant="body2" textAlign="center">
                                <span>&nbsp;</span>
                                </Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item md={1}></Grid>
                <Grid item md={3} sx={planContainerStyle}>
                    <Paper sx={plansBestValueCardStyle} elevation={5}>
                        <Box sx={bestValueCardInnerWrapperStyle}>
                            <Box 
                                sx={plansValueCardStyle}
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <Typography variant="body2" sx={planBestValueTextStyle}>
                                    {YEARLY_PLAN.VALUE_TAG}
                                </Typography>
                            </Box>
                        </Box>
                        <Stack alignItems="center" sx={yearlyPlanStackStyle}>
                            <PlanPriceStack 
                                title={YEARLY_PLAN.TITLE} 
                                currency={YEARLY_PLAN.CURRENCY} 
                                price={YEARLY_PLAN.PRICE}
                                price_period={YEARLY_PLAN.PRICE_PERIOD}
                                billing_period={YEARLY_PLAN.BILLING_PERIOD}
                                trial_period={YEARLY_PLAN.TRIAL_PERIOD}
                                trial_promo={YEARLY_PLAN.TRIAL_PROMO} />
                        </Stack>
                        <Stack sx={yearlyPlanBenefitStackStyle} justifyContent="flex-start">
                        {
                            YEARLY_PLAN_BENEFITS.map(benefit => {
                                return (
                                    <Stack direction="row" sx={planFeatureRowStyle} justifyContent="flex-start">
                                        <Icon color="primary" fontSize="small">
                                            <CheckCircleIcon fontSize="small"/>
                                        </Icon>
                                        <Typography variant="body2" sx={planFeatureTextStyle}>
                                            {benefit}
                                        </Typography>
                                    </Stack>
                                )
                            })
                        }
                        </Stack>
                    </Paper>
                </Grid>
                <Grid xs={2.5}></Grid>
            </Grid>
            <Footer is_tablet={false} is_phone={false} />
        </Box>
    )
}
