import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface Props {
    image: string,
    name: string
}

export default function Partner({image, name}: Props){
    return (
        <Grid item xs={6}>
            <Stack alignItems="center">
                <img src={image}  height="120" width="120" alt={name} />
                <Typography variant="h6" textAlign="center">
                    {name}
                </Typography>
            </Stack>
        </Grid>
    )
}