import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { Action } from "api/action_types/signature";
import { Action as OrderAction } from "api/action_types/order";
import { Action as QuoteAction } from "api/action_types/quote";
import { Action as SelfOrderAction } from "api/action_types/self_order";
import { Action as SketchAction } from "api/action_types/sketch";
import { ActionType } from "api/action_types/signature";
import { ActionType as OrderActionType } from "api/action_types/order";
import { ActionType as QuoteActionType } from "api/action_types/quote";
import { ActionType as SelfOrderActionType } from "api/action_types/self_order";
import { ActionType as SketchActionType } from "api/action_types/sketch";
import { url_prefix } from "settings";
import { signature_url } from "../endpoint";

export const getSignature = (signature_key: string, quote?: boolean, order?: boolean, self_order?: boolean) => {
    return async(dispatch: Dispatch<Action | OrderAction | QuoteAction | SelfOrderAction | SketchAction>) => {
        dispatch({
            type: ActionType.SIGNATURE_LOADING
        });

        const config = {
            headers: {
              "Content-Type":"application/json"
            }
        }

        let url = `${url_prefix}${signature_url}/0/?signature_key=${signature_key}`;
        url += `&quote=${quote}`;
        url += `&order=${order}`;
        url += `&self_order=${self_order}`;

        try
        {
            const {data} = await axios.get(url, config);

            if(data.id){
                dispatch({
                    type: ActionType.GET_SIGNATURE_SUCCESS,
                    signature: data.signature
                });

                if(data.quote){
                    dispatch({
                        type: QuoteActionType.GET_QUOTE_SUCCESS,
                        quote: data.quote
                    });
                    
                    dispatch({
                        type: SketchActionType.GET_COUNTERS_SUCCESS,
                        counters: data.counters
                    });
        
                    dispatch({
                        type: SketchActionType.GET_AREAS_SUCCESS,
                        areas: data.areas
                    });

                    dispatch({
                        type: QuoteActionType.GET_QUOTE_VENDOR_SUCCESS,
                        vendor: data.vendor
                    });
                }
                else if(data.order){
                    dispatch({
                        type: OrderActionType.GET_ORDER_SUCCESS,
                        order: data.order
                    });
                    
                    dispatch({
                        type: OrderActionType.GET_ORDER_COUNTERS_SUCCESS,
                        order_counters: data.counters
                    });
        
                    dispatch({
                        type: OrderActionType.GET_ORDER_AREAS_SUCCESS,
                        order_areas: data.areas
                    });

                    dispatch({
                        type: OrderActionType.GET_ORDER_SLABS_SUCCESS,
                        order_slabs: data.slabs
                    });

                    dispatch({
                        type: OrderActionType.GET_ORDER_VENDOR_SUCCESS,
                        vendor: data.vendor
                    });
                }
                else if(data.self_order){
                    dispatch({
                        type: SelfOrderActionType.GET_SELF_ORDER_SUCCESS,
                        self_order: data.self_order
                    });
                    
                    dispatch({
                        type: SelfOrderActionType.GET_SELF_ORDER_COUNTERS_SUCCESS,
                        self_order_counters: data.counters
                    });
        
                    dispatch({
                        type: SelfOrderActionType.GET_SELF_ORDER_AREAS_SUCCESS,
                        self_order_areas: data.areas
                    });

                    dispatch({
                        type: SelfOrderActionType.GET_SELF_ORDER_SLABS_SUCCESS,
                        self_order_slabs: data.slabs
                    });

                    dispatch({
                        type: SelfOrderActionType.GET_SELF_ORDER_VENDOR_SUCCESS,
                        vendor: data.vendor
                    });
                }
            }
            else{
                dispatch({
                    type: ActionType.ALREADY_SIGNED
                });
            }
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.SIGNATURE_ERROR,
                    error: err.message
                });
            }
        }
    }
}