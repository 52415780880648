import { Signature } from "arris_dist";

export enum ActionType {
    GET_SIGNATURE_SUCCESS="GET_SIGNATURE_SUCCESS",
    UPDATE_SIGNATURE_SUCCESS="UPDATE_SIGNATURE_SUCCESS",
    ALREADY_SIGNED="ALREADY_SIGNED",
    SIGNATURE_LOADING="SIGNATURE_LOADING",
    SIGNATURE_ERROR="SIGNATURE_ERROR"
}

interface IGetSignatureSuccess {
    type: ActionType.GET_SIGNATURE_SUCCESS,
    signature: Signature
}

interface IUpdateSignature {
    type: ActionType.UPDATE_SIGNATURE_SUCCESS
}

interface IAlreadySigned {
    type: ActionType.ALREADY_SIGNED
}

interface ISignatureError {
    type: ActionType.SIGNATURE_ERROR,
    error: string
}

interface ISignatureLoading {
    type: ActionType.SIGNATURE_LOADING
}

export type Action = IGetSignatureSuccess | IUpdateSignature | IAlreadySigned | ISignatureError | ISignatureLoading;