import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SavingsExample from "../example";
import Pic6 from "static/pic6.webp";
import { Row8 } from "values/text/home";
import { homeSavingsContainerStyle } from "styles/home_page/savings";
import { homeSavingsImageStyle } from "styles/home_page/savings";
import { homeSavingsExampleContainer } from "styles/home_page/savings";
import { homeSavingsTitleWrapper } from "styles/home_page/savings";
import { homeSavingsGridSpacer } from "styles/home_page/savings";

export default function XsSavings(){
    return (
        <Grid container sx={homeSavingsContainerStyle}>
            <Grid item xs={12} sx={homeSavingsImageStyle}></Grid>
            <Grid container item xs={12} sx={homeSavingsExampleContainer}>
                <Grid item xs={12} sx={homeSavingsTitleWrapper} justifyContent="space-between">
                    <Typography variant="h5" textAlign="left">
                        {Row8.TITLE}
                    </Typography>
                </Grid>
                <SavingsExample 
                    number={Row8.CELL_1_NUMBER} 
                    number_type={Row8.CELL_1_NUMBER_TYPE}
                    description1={Row8.CELL_1_DESCRIPTION_1}
                    description2={Row8.CELL_1_DESCRIPTION_2} />
                <SavingsExample 
                    number={Row8.CELL_2_NUMBER} 
                    number_type={Row8.CELL_2_NUMBER_TYPE}
                    description1={Row8.CELL_2_DESCRIPTION_1}
                    description2={Row8.CELL_2_DESCRIPTION_2} />
                <Grid item xs={12} sx={homeSavingsGridSpacer}></Grid>
                <SavingsExample 
                    number={Row8.CELL_3_NUMBER} 
                    number_type={Row8.CELL_3_NUMBER_TYPE}
                    description1={Row8.CELL_3_DESCRIPTION_1}
                    description2="" />
                <SavingsExample 
                    number={Row8.CELL_4_NUMBER} 
                    number_type={Row8.CELL_4_NUMBER_TYPE}
                    description1={Row8.CELL_4_DESCRIPTION_1}
                    description2={Row8.CELL_4_DESCRIPTION_2} />
            </Grid>
        </Grid>
    )
}