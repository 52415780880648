import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StatementBox from "components/universal/statement_box";
import { Growth } from "values/text/fabricator";
import { text_primary } from "styles/theme";
import { white_color } from "styles/theme";
import { largePictureAreaStyle } from "styles/general";
import { fabricatorGrowthContainerStyle } from "styles/fabricator/growth";
import { fabricatorGrowthGridContainerStyle } from "styles/fabricator/growth";
import { fabricatorGrowthImageStyle } from "styles/fabricator/growth";

export default function XsGrowth(){
    return (
        <Box sx={[largePictureAreaStyle, fabricatorGrowthContainerStyle]}>
            <Grid container sx={fabricatorGrowthGridContainerStyle}>
                <StatementBox 
                    title={Growth.TITLE}
                    subtitle1={Growth.PARAGRAPH}
                    text_color={text_primary}
                    hover_text_color={white_color}
                    background_color={white_color}
                    hover_background_color={text_primary}
                    border_color={text_primary}
                    is_phone={true}
                    paddingTop={10}
                    paddingBottom={10} />
                <Grid item xs={6} sx={fabricatorGrowthImageStyle}>
                </Grid>
            </Grid>
        </Box>
    )
}