import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StatementBox from "components/universal/statement_box";
import { Quality } from "values/text/merchant";
import { largePictureAreaStyle } from "styles/general";
import { text_primary } from "styles/theme";
import { white_color } from "styles/theme";
import { merchantCustomersGridStyle } from "styles/merchant/customers";
import { merchantCustomersImageStyle } from "styles/merchant/customers";

export default function SmCustomers(){
    return (
        <Box sx={largePictureAreaStyle}>
            <Grid container sx={merchantCustomersGridStyle}>
                <StatementBox 
                    title={Quality.TITLE}
                    subtitle1={Quality.PARAGRAPH}
                    text_color={text_primary}
                    hover_text_color={white_color}
                    background_color={white_color}
                    hover_background_color={text_primary}
                    border_color={text_primary}
                    paddingTop={10}
                    paddingBottom={10}
                    is_tablet={true} />
                <Grid item xs={12} sx={merchantCustomersImageStyle}></Grid>
            </Grid>
        </Box>
    )
}