export enum Hero {
    TITLE="WORD OF MOUTH REDEFINED!"
}

export enum Growth {
    TITLE="UNLOCK EXPONENTIAL GROWTH",
    PARAGRAPH="With Arris, you'll experience a surge in business growth. Its innovative features will streamline your operations, boosting efficiency and profitability. You'll see an influx of new orders without the need for additional marketing, transforming your business performance effortlessly."
}

export enum GrowthDetails {
    TITLE_1="BUILD RELATIONSHIPS WITH MERCHANTS",
    PARAGRAPH_1="​With Arris, fabricators can easily connect with new dealers, fostering valuable relationships that drive business growth. Being an early adopter of Arris gives you a competitive edge, allowing you to secure more jobs than your competitors. It's not just about getting found, it's about building lasting partnerships and staying ahead in the industry.",
    TITLE_2="REAL-TIME INFORMATION",
    PARAGRAPH_2="Fabricators, step into the future with Arris – the definitive solution for all your design, quoting, and marketplace requirements. Say goodbye to outdated pricing methods; generate instant quotes with the most current pricing, ensuring you're always ready to seal the deal. Plus, with our adaptable markups, you have the freedom to set rates that perfectly balance market competitiveness with your business's profitability. With Arris, every piece of information, from design drawings to order details, is at your fingertips in real time."
}

export enum Streamline {
    TITLE="STREAMLINE YOUR SALES PROCESS",
    PARAGRAPH="Arris optimizes your business by simplifying drawing processes, providing real-time quotes, and connecting you with top merchants. It's your key to efficiency, accuracy, and growth."
}

export enum StreamlineDetails {
    TITLE_1="EFFICIENT DRAWING TOOL",
    PARAGRAPH_1="Arris features an efficient drawing tool that revolutionizes the way fabricators work. This tool simplifies the drawing process, making it quick and effortless. It allows you to create accurate sketches in real-time, reducing errors and enhancing productivity. With Arris, you can focus more on craftsmanship and less on time-consuming tasks, transforming your operations and boosting your efficiency.",
    TITLE_2="INTEGRATED PRICING & QUOTING",
    PARAGRAPH_2="Arris provides an integrated pricing and quoting system that streamlines your sales process. This feature allows you to generate accurate quotes in real-time, right at the job site. It also offers the flexibility to adjust pricing strategies on the fly, ensuring you stay competitive or charge a premium for high-quality service. With Arris, you can enhance your customer interactions, close deals faster, and drive your business growth."
}