export const commentBoxTitle = (text_color: string) => ({
    color: text_color,
    paddingLeft: {
        "xs": 6,
        "sm": 10,
        "md": 10
    },
    paddingRight: 8,
    paddingBottom: 2
});

export const commentBoxParagraph = (text_color: string) => ({
    color: text_color,
    paddingLeft: {
        "xs": 6,
        "sm": 10,
        "md": 10
    },
    paddingRight: "10%",
    lineHeight: 2
});