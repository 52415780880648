import { createTheme} from "@mui/material/styles";
import {ThemeProvider } from "@mui/material/styles";
import { ParallaxProvider } from 'react-scroll-parallax';
import { Provider } from "react-redux";
import { store } from "api/store";
import Box from "@mui/material/Box";
import MainRouter from "components/router/router";
import { white_color } from "styles/theme";
import { primary_color } from "styles/theme";
import { secondary_color } from "styles/theme";
import { error_color } from "styles/theme";
import { text_primary } from "styles/theme";
import { text_secondary } from "styles/theme";
import "./App.css";

const theme = createTheme({
    breakpoints: {
        values: {
          xs: 0,
          sm: 650,
          md: 920,
          lg: 1200,
          xl: 1536,
        },
    },
    palette: {
        primary: {
            main: primary_color
        },
        secondary: {
            main: secondary_color
        },
        error: {
            main: error_color
        },
        text: {
            primary: text_primary
        }
    },
    typography: {
        fontFamily: [
            "Roboto"
        ].join(","),
        h1: {
            fontSize: 60,
            fontFamily: "Montserrat",
            textTransform: "uppercase",
            color: text_primary,
            letterSpacing: "0.125em"
        },
        h2: {
            fontSize: 40,
            fontFamily: "Montserrat"
        },
        h3: {
            fontSize: 24,
            fontFamily: "Montserrat",
            textTransform: "uppercase",
            color: text_primary
        },
        h4: {
            fontSize: 20,
            fontFamily: "Montserrat",
            color: primary_color,
            textTransform: "uppercase",
            fontWeight: "bold"
        },
        h5: {
            fontSize: 24,
            fontFamily: "Montserrat"
        },
        h6: {
            fontSize: 16,
            color: white_color,
            fontFamily: "Montserrat",
            textTransform: "uppercase"
        },
        body1: {
            fontFamily: "Montserrat",
            fontSize: 17,
            color: text_primary,
            whiteSpace: "pre-line",
            wordWrap: "break-word"
        },
        body2: {
            fontFamily: "Montserrat",
            fontSize: 14,
            color: text_secondary,
            whiteSpace: "pre-line",
            wordWrap: "break-word"
        },
        button: {
            fontFamily: "Montserrat"
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                InputProps: {
                    style: {
                        fontSize: 16,
                        color: white_color
                    }
                },
                InputLabelProps: {
                    style: {
                        fontSize: 16,
                        color: white_color
                    }
                }
            }
        },
    }
});

export default function App() {
    return(
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ParallaxProvider>
                <Box>
                    <MainRouter />
                </Box>
              </ParallaxProvider>
          </ThemeProvider>
        </Provider>
    )
}
