import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "hooks/api";
import { useAppSelector } from "hooks/api";
import { postConfirmation } from "api/actions/confirmation";
import { resetPasswordValues } from "api/actions/password_reset";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { primary_color } from "styles/theme";
import { text_primary } from "styles/theme";
import { error_color } from "styles/theme";
import { app_url } from "settings";

export default function CompletePasswordReset() {
  const dispatch = useAppDispatch();
  const { uuid } = useParams();
  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [show_password, setShowPassword] = useState<boolean>(false);
  const [show_password2, setShowPassword2] = useState<boolean>(false);
  const { loading, error, account_confirmation_complete } = useAppSelector((state) => state.authentication);
  const [has_error, setHasError] = useState<boolean>(false);
  const [local_error, setLocalError] = useState<string>("");

  useEffect(() => {
    dispatch(resetPasswordValues());
  }, [dispatch]);

  const handleChangePassword = () => {
    if (password !== password2) {
      setHasError(true);
      setLocalError("Passwords must match");
      return;
    }

    if (password.length < 8) {
      setHasError(true);
      setLocalError("Password must be at least 8 characters");
      return;
    }

    if (uuid) {
      dispatch(postConfirmation(uuid, {
        old_password: "",
        password: password,
        password2: password2
      }));
    }
  }

  const redirectPage = () => {
    window.location.replace(app_url + "login");
  }

  return (
    <Box sx={{
      padding: {
        "md": "20vh",
        "xs": "2vh"
      }
    }}>
      {
        account_confirmation_complete ?
          <Stack>
            <Typography variant="h6" sx={{ paddingLeft: 3, color: text_primary, paddingBottom: 0.5 }}>
              Your password has been reset
            </Typography>
            <Button
              onClick={redirectPage}
              variant="contained"
              sx={{ width: 150, marginTop: 1.5, marginLeft: 3 }}>
              Login
            </Button>
          </Stack> :
          <Stack>
            <Typography variant="h6" sx={{ paddingLeft: 3, color: text_primary, paddingBottom: 0.5 }}>
              Enter your new password
            </Typography>
            {
              has_error && local_error !== "" ?
                <Typography variant="body2" sx={{ color: error_color, paddingLeft: 3 }}>
                  {local_error}
                </Typography> :
                null
            }
            {
              !has_error && error !== "" ?
                <Typography variant="body2" sx={{ color: error_color, paddingLeft: 3 }}>
                  {error}
                </Typography> :
                null
            }
            <TextField
              autoCapitalize="none"
              type={show_password ? "text" : "password"}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  color: primary_color
                }
              }}
              InputProps={{
                style: {
                  color: text_primary
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      !show_password ?
                        <Tooltip title="Show Password">
                          <IconButton onClick={() => setShowPassword(true)}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip> :
                        <Tooltip title="Hide Password">
                          <IconButton onClick={(e) => setShowPassword(false)}>
                            <VisibilityOffIcon />
                          </IconButton>
                        </Tooltip>
                    }
                  </InputAdornment>
                )
              }}
              sx={{ marginLeft: 3, marginBottom: 2, marginTop: 1.5, width: 400, color: text_primary }}
              size="small"
              error={has_error}
              onChange={(e) => setPassword(e.target.value)}
              label="Password" />
            <TextField
              autoCapitalize="none"
              type={show_password2 ? "text" : "password"}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  color: primary_color
                }
              }}
              InputProps={{
                style: {
                  color: text_primary
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      !show_password2 ?
                        <Tooltip title="Show Password">
                          <IconButton onClick={() => setShowPassword2(true)}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip> :
                        <Tooltip title="Hide Password">
                          <IconButton onClick={(e) => setShowPassword2(false)}>
                            <VisibilityOffIcon />
                          </IconButton>
                        </Tooltip>
                    }
                  </InputAdornment>
                )
              }}
              sx={{ marginLeft: 3, marginBottom: 2, marginTop: 1.5, width: 400, color: text_primary }}
              size="small"
              error={has_error}
              onChange={(e) => setPassword2(e.target.value)}
              label="Confirm Password" />
            {
              loading ?
                <CircularProgress sx={{ marginTop: 1.5, marginLeft: 3 }} /> :
                <Button
                  onClick={handleChangePassword}
                  variant="contained"
                  sx={{ width: 200, marginTop: 1.5, marginLeft: 3 }}>
                  Change Password
                </Button>
            }

          </Stack>
      }
    </Box>
  );
}
