import { Counter } from "arris_dist";

export enum ActionType {
    CREATE_COUNTER_SUCCESS="CREATE_COUNTER_SUCCESS",
    DELETE_COUNTER_SUCCESS="DELETE_COUNTER_SUCCESS",
    GET_COUNTERS_SUCCESS="GET_COUNTERS_SUCCESS",
    SET_SELECTED_COUNTER="SET_SELECTED_COUNTER",
    UPDATE_COUNTER_SUCCESS="UPDATE_COUNTER_SUCCESS"
}

interface createCounterSuccess {
    type: ActionType.CREATE_COUNTER_SUCCESS,
    payload: Counter
}

interface deleteCounterSuccess {
    type: ActionType.DELETE_COUNTER_SUCCESS,
    payload: string
}

interface getCountersSuccess {
    type: ActionType.GET_COUNTERS_SUCCESS,
    counters: Counter[]
}

interface setSelectedCounter {
    type: ActionType.SET_SELECTED_COUNTER,
    payload: Counter | null
}

interface updateCounterSuccess {
    type: ActionType.UPDATE_COUNTER_SUCCESS,
    payload: Counter
}

export type Action = 
    createCounterSuccess |
    deleteCounterSuccess |
    getCountersSuccess |
    updateCounterSuccess |
    setSelectedCounter;