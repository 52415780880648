import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReadMoreBox from "components/universal/read_more_box";
import { Row5 } from "values/text/home";
import { homeMaterialContainer } from "styles/home_page/material";
import { homeMaterialMdImage } from "styles/home_page/material";
import { homeMaterialImageContainer } from "styles/home_page/material";

export default function MdMaterial(){
    return (
        <Box sx={homeMaterialContainer}>
            <Grid container sx={homeMaterialImageContainer}>
                <Grid item xs={6} sx={homeMaterialMdImage}>

                </Grid>
                <ReadMoreBox 
                    title1={Row5.TITLE_1}
                    title2={Row5.TITLE_2}
                    paragraph={Row5.PARAGRAPH_1}
                    grid_left={2}
                    grid_right={7}
                    grid_end={3}
                    is_mobile={false} />
            </Grid>
        </Box>
    )
}