import { Area } from "arris_dist";
import { QuoteLineItem } from "arris_dist";
import { Quote } from "arris_dist";
import { User } from "arris_dist";
import { Action } from "api/action_types/quote";
import { ActionType } from "api/action_types/quote";

interface State {
    changed: boolean,
    discount: number,
    stone_countertop_removal: number,
    laminate_countertop_removal: number,
    material_area: number,
    tax: number,
    total: number,
    quote_line_items: QuoteLineItem[],
    quote: Quote | null,
    vendor: User,
    areas: Area[],
    loading: boolean,
    error: string
}

const initialState = {
    changed: true,
    discount: 0,
    stone_countertop_removal: 0,
    laminate_countertop_removal: 0,
    material_area: 0,
    tax: 0,
    total: 0,
    quote_line_items: [],
    quote: null,
    areas: [],
    vendor: {},
    loading: false,
    error: ""
}

export const quoteReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.GET_QUOTE_SUCCESS:
            return {
                ...state,
                quote: action.quote,
                loading: false,
                error: ""
            }
        case ActionType.GET_QUOTE_VENDOR_SUCCESS:
            return {
                ...state,
                vendor: action.vendor
            }
        case ActionType.GENERATE_QUOTE:
            return {
                ...state,
                quote_line_items: action.payload,
                loading: false,
                error: ""
            }
        case ActionType.SET_QUOTE_CHANGED:
            return {
                ...state,
                changed: action.payload
            }
        case ActionType.SET_LAMINATE_COUNTERTOP_REMOVAL:
            return {
                ...state,
                laminate_countertop_removal: action.payload
            }
        case ActionType.SET_STONE_COUNTERTOP_REMOVAL:
            return {
                ...state,
                stone_countertop_removal: action.payload
            }
        case ActionType.SET_DISCOUNT:
            return {
                ...state,
                discount: action.payload
            }
        case ActionType.QUOTE_PENDING:
            return {
                ...state,
                loading: true
            }
        case ActionType.QUOTE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}