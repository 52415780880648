import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { heroTransparentAreaStyle } from "styles/general";
import { Hero } from "values/text/merchant";
import { merchantHeroBannerStyle } from "styles/merchant/hero";
import { merchantHeroTitleTextStyle } from "styles/merchant/hero";

export default function SmMerchantHero(){
    return (
        <Box sx={[heroTransparentAreaStyle, merchantHeroBannerStyle]}>
            <Stack>
                <Typography variant="h2" textAlign="center" sx={merchantHeroTitleTextStyle}>
                    {Hero.TITLE}
                </Typography>
            </Stack>
        </Box>
    )
}