// Row 1 // 
export enum Row1 {
    TITLE="OUR JOURNEY",
    PARAGRAPH="Born out of over two decades of experience in the granite industry, Arris was created to address the sector's outdated practices. We recognized the industry's need for modernization and decided to be the catalyst for that change. Our deep understanding of the challenges fabricators face inspired us to develop Arris, a solution designed to streamline operations, enhance efficiency, and drive growth. Our journey is a testament to our commitment to innovation and our desire to propel the granite industry into the future.",
    PARAGRAPH_BRIEF="Born out of over two decades of experience in the granite industry, Arris was created to address the sector's outdated practices."
}

export enum Row2 {
    TITLE="OUR MISSION",
    SUBTITLE_1="Redefining the Way",
    SUBTITLE_2="We Move",
    PARAGRAPH_1="Arris is revolutionizing the granite industry by seamlessly integrating technology with craftsmanship. It simplifies operations, connects businesses with top fabricators, and provides real-time estimates.",
    PARAGRAPH_2="By offering flexible pricing strategies and enhancing transparency in workflows, Arris empowers businesses to optimize their processes and profitability. In essence, Arris is not just a tool, but a transformative force driving innovation and efficiency in the granite industry."
}

export enum Row3 {
    TITLE="What Our Partners Say About Us",
        // Cell 1 //
        CELL_1_YEAR="Action Flooring",
        CELL_1_TITLE="OUR SUCCESS HAS BEEN BOOSTED",
        CELL_1_PARAGRAPH="Our success in securing more contracts has been significantly boosted by Arris. It expedites the estimation process, thereby shortening the purchasing cycle. Arris also enhances transparency in our workflow, enabling us to swiftly identify and resolve any bottlenecks.",
        // Cell 2 //
        CELL_2_YEAR="Rennovation Find",
        CELL_2_TITLE="I CAN GIVE A FINAL PRICE TO THE CUSTOMER AT THE SAME TIME I'M MAKING A MEASUREMENT",
        CELL_2_PARAGRAPH="With Arris, you can deliver accurate quotes in just three minutes, right at the job site. This immediate response not only reduces your workload but also impresses clients and prevents them from considering competitors.",
        // Cell 3 //
        CELL_3_YEAR="2022",
        CELL_3_TITLE="ARRIS IS FOUNDED",
        CELL_3_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font.",
        // Cell 4 //
        CELL_4_YEAR="2023",
        CELL_4_TITLE="FLAGSHIP SOFTWARE LAUNCH",
        CELL_4_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font.",
        // Cell 5 //
        CELL_5_YEAR="2022",
        CELL_5_TITLE="ARRIS IS FOUNDED",
        CELL_5_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font.",
        // Cell 6 //
        CELL_6_YEAR="2023",
        CELL_6_TITLE="FLAGSHIP SOFTWARE LAUNCH",
        CELL_6_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font."
}

export enum Row4 {
    TITLE="Our Values",
    SUBTITLE_1="Revolutionizing the industry through innovative problem solving",
    HEADER_1="Efficiency",
    HEADER_2="INNOVATION",
    HEADER_3="ADAPTABILITY",
    HEADER_4="TRANSPARENCY",
    HEADER_5="EMPOWERMENT",
    PARAGRAPH_1="Arris values the importance of streamlining processes to save time and increase productivity.",
    PARAGRAPH_2="We are committed to providing cutting-edge solutions that revolutionize the way fabricators work.",
    PARAGRAPH_3="We value the ability to adapt to market dynamics, providing flexible pricing strategies to its users.",
    PARAGRAPH_4="We value clear communication and visibility in operations, helping users identify and resolve bottlenecks swiftly.",
    PARAGRAPH_5="Arris believes in empowering its users, providing them with the tools & resources to take control of their profitability & success."
}

