import axios from "axios";
import { AxiosError } from 'axios';
import { Dispatch } from "redux";
import { Action } from "api/action_types/authentication";
import { ActionType } from "api/action_types/authentication";
import { ChangePassword } from "arris_dist";
import { url_prefix } from "settings";

const reset_password_url: string = "password_reset";

export const resetPassword = (email: string) => {
    return async(dispatch: Dispatch<Action>) => {

        dispatch({
            type: ActionType.PASSWORD_RESET_PENDING
        });

        const config = {
            headers: {
              "Content-Type": "application/json"
            }
        }

        const url = `${url_prefix}${reset_password_url}?email=${email}&`;

        try
        {
            await axios.get(url, config);

            dispatch({
                type: ActionType.PASSWORD_RESET_SUCCESS
            });
        }
        catch(err)
        {
            if( err instanceof AxiosError){
                dispatch({
                    type: ActionType.PASSWORD_RESET_FAIL,
                    payload: err.message
                });
            }
        }
    }
}

export const postResetPassword = (change_password: ChangePassword, uuid: string) => {
    return async(dispatch: Dispatch<Action>) => {

        dispatch({
            type: ActionType.PASSWORD_RESET_PENDING
        });

        const config = {
            headers: {
              "Content-Type": "application/json"
            }
        }

        const url = `${url_prefix}${reset_password_url}/?uuid=${uuid}&`;

        try
        {
            await axios.post(url, change_password, config);

            dispatch({
                type: ActionType.PASSWORD_RESET_SUCCESS
            });
        }
        catch(err)
        {
            if( err instanceof AxiosError){
                if(err && err.response){
                    if(err.response.status === 401){
                        dispatch({
                            type: ActionType.PASSWORD_RESET_FAIL,
                            payload: err.response.data.error
                        });
                    }
                    else if(err.response.status === 400){
                        dispatch({
                            type: ActionType.PASSWORD_RESET_FAIL,
                            payload: err.response.data.error
                        });
                    }
                } 
                else{
                    dispatch({
                        type: ActionType.PASSWORD_RESET_FAIL,
                        payload: err.message
                    });
                }
                
            }
        }
    }
}

export const resetPasswordValues = () => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.RESET_PASSWORD_VALUES
        });
    }
}