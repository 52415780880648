import { Cooktop } from "arris_dist";

export enum ActionType {
    CREATE_COOKTOP_SUCCESS="CREATE_COOKTOP_SUCCESS",
    UPDATE_COOKTOP_SUCCESS="UPDATE_COOKTOP_SUCCESS",
    DELETE_COOKTOP_SUCCESS="DELETE_COOKTOP_SUCCESS"
}

interface deleteCooktopSuccess {
    type: ActionType.DELETE_COOKTOP_SUCCESS,
    payload: string
}

interface updateCooktopSuccess {
    type: ActionType.UPDATE_COOKTOP_SUCCESS,
    payload: Cooktop
}

interface createCooktopSuccess {
    type: ActionType.CREATE_COOKTOP_SUCCESS,
    payload: Cooktop
}

export type Action = 
    createCooktopSuccess |
    updateCooktopSuccess |
    deleteCooktopSuccess;