import { Area } from "arris_dist";
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { well_color } from "styles/theme";

interface Props {
    areas: Area[]
}

export default function PrintLineItems({areas}: Props) { 
    const styles = StyleSheet.create({
        text: {
            fontFamily: "Montserrat",
            fontSize: 10
        },
        line: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            paddingTop: "2px",
            paddingBottom: "3px"
        },
        line_well: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            paddingTop: "2px",
            paddingBottom: "3px",
            backgroundColor: well_color
        }
    });  
    return (
        <View style={{marginTop: "2px", width: "100%"}}>
            {
                areas.map(area => {
                    return (
                        <View style={{width: "100%", paddingLeft: "10px", paddingRight: "10px"}} key={area.id}>
                            {
                                area.quote_line_items.map((line_item, index) => {
                                    return (
                                        <View key={line_item.id} style={index % 2 === 0 ? styles.line : styles.line_well}>
                                            <View style={{width: "49%", paddingLeft: "5px"}}>
                                                <Text style={styles.text}>
                                                    {line_item.name}
                                                </Text>
                                            </View>
                                            <View style={{width: "25%", paddingLeft: "6px"}}>
                                                <Text style={styles.text}>
                                                    {line_item.description}
                                                </Text>
                                            </View>
                                            <View style={{width: "26%"}}>
                                                <Text></Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>
                    )
                })
            }
        </View>
    );
}