import { Order } from "arris_dist";
import { UpdateOrder } from "arris_dist";
import { User } from "arris_dist";

export enum ActionType {
    GET_ORDERS_SUCCESS="GET_ORDERS_SUCCESS",
    GET_ORDER_SUCCESS="GET_ORDER_SUCCESS",
    GET_ORDER_VENDOR_SUCCESS="GET_ORDER_VENDOR_SUCCESS",
    CREATE_ORDER_SUCCESS="CREATE_ORDER_SUCCESS",
    UPDATE_ORDER_SUCCESS="UPDATE_ORDER_SUCCESS",
    UPDATE_LOCAL_ORDER="UPDATE_LOCAL_ORDER",
    RESET_ORDER_SUCCESS="RESET_ORDER_SUCCESS"
}

interface IGetOrders {
    type: ActionType.GET_ORDERS_SUCCESS,
    orders: Order[]
}

interface IGetOrder {
    type: ActionType.GET_ORDER_SUCCESS,
    order: Order
}

interface IGetOrderVendor {
    type: ActionType.GET_ORDER_VENDOR_SUCCESS,
    vendor: User
}

interface ICreateOrder {
    type: ActionType.CREATE_ORDER_SUCCESS,
    order: Order
}

interface IUpdateOrder {
    type: ActionType.UPDATE_ORDER_SUCCESS
}

interface IUpdateLocalOrder {
    type: ActionType.UPDATE_LOCAL_ORDER,
    order: UpdateOrder
}

interface IResetOrder {
    type: ActionType.RESET_ORDER_SUCCESS
}

export type Action = IGetOrders | IGetOrder | IGetOrderVendor | ICreateOrder | IUpdateOrder | IUpdateLocalOrder | IResetOrder;