// Row 1 // 
export enum Row1 {
    TITLE="Why Arris?",
        // Cell 1 // 
        CELL_1_SUBTITLE="Effortless Drawing",
        CELL_1_PARAGRAPH="Create layouts, add appliances, angles, curves, materials, & edges in minutes with our intuitive design tool.",
        // Cell 2 //
        CELL_2_SUBTITLE="Instant Quoting",
        CELL_2_PARAGRAPH="Generate on-the-spot quotes with the up-to-date pricing, from any supplier ensuring you never miss out on a sale.",
        // Cell 3 //
        CELL_3_SUBTITLE="Exclusive Fabricator Access",
        CELL_3_PARAGRAPH="Gain access to top-tier fabricators in your vicinity, and beyond, through our exclusive marketplace.",
}

// Row 2 //
export enum Row2 {
    TITLE="Vision",
        // Subtitles //
        SUBTITLE_1="We’re Changing the",
        SUBTITLE_2="Way the Granite",
        SUBTITLE_3="Industry Operates",
        // Paragraphs //
        PARAGRAPH_1="Our vision is to bridge the chasm between fabricators and suppliers by providing an edge-to-edge solution. From drawing the blueprint to quoting to sending the job to the most competitive fabricator. You can do it all in one place and in seconds!"
}

// Row 3 //
export enum Row3 {
    TITLE="Services",
        // Subtitles //
        SUBTITLE_1="We Deliver Exceptional",
        SUBTITLE_2="Products and Services",
        SUBTITLE_3="Around the World"
}

// Row 4 //
export enum Row4 {
    TITLE_1="GAIN ACCESS TO",
    TITLE_2="HUNDREDS OF",
    TITLE_3="FABRICATORS",
    PARAGRAPH_1="Arris simplifies finding top-rated, vetted fabricators for granite stores, offering immediate access to a quality-assured network. This efficient feature enhances sourcing and collaboration, connecting you effortlessly with the best local fabricators, streamlining the process for faster, reliable partnerships."
}

// Row 5 //
export enum Row5 {
    TITLE_1="GET THE BEST",
    TITLE_2="MATERIAL PRICING",
    PARAGRAPH_1="Arris secures unmatched material cost advantages by partnering directly with granite manufacturers, ensuring users access top volume pricing and eliminating middlemen for substantial savings. Beyond software, Arris enhances business profitability and financial benefits, positioning itself as a valuable partner to improve your bottom line."
}
   
// Row 6 //
export enum Row6 {
    TITLE_1="COMPLETE",
    TITLE_2="CONTROL ON",
    TITLE_3="PRICING",
    PARAGRAPH_1="Arris enables easy pricing adjustments through a user-friendly interface, allowing for real-time market responsiveness with dollar or percentage markups. It supports competitive pricing or premium charges, ensuring users maintain control over profitability and align strategies with service quality."
}

export enum Row7 {
    TITLE="WHY ARRIS",
        // Subtitle //
        SUBTITLE_1="An edge-to-edge",
        SUBTITLE_2="solution built by",
        SUBTITLE_3="fabricators & suppliers",
        PARAGRAPH_1="From initial customer contact to final delivery, Arris integrates every step into a cohesive system, increasing efficiencies at every turn. It's more than just a tool; it's a comprehensive platform that transforms the way businesses operate, reducing time-consuming tasks and enhancing productivity. With Arris, businesses gain a strategic advantage, optimizing their processes and driving growth in a competitive market."
}

export enum Row8 {
    TITLE="Average Savings Per Month",
        // Cell 1 //
        CELL_1_NUMBER="168",
        CELL_1_NUMBER_TYPE="HOURS",
        CELL_1_DESCRIPTION_1="WAITING FOR",
        CELL_1_DESCRIPTION_2="FABRICATORS",
        // Cell 2 //
        CELL_2_NUMBER="56",
        CELL_2_NUMBER_TYPE="HOURS",
        CELL_2_DESCRIPTION_1="GENERATING",
        CELL_2_DESCRIPTION_2="QUOTES",
        // Cell 3 //
        CELL_3_NUMBER="$5000",
        CELL_3_NUMBER_TYPE="",
        CELL_3_DESCRIPTION_1="HR COST",
        // Cell 4 //
        CELL_4_NUMBER="73",
        CELL_4_NUMBER_TYPE="HOURS",
        CELL_4_DESCRIPTION_1="FINDING &",
        CELL_4_DESCRIPTION_2="UPDATING PRICES"
}

export enum Row9 {
    TITLE="INDUSTRY",
        // Subtitles //
        SUBTITLE_1="Our",
        SUBTITLE_2="Partners",
        // Paragraphs //
        PARAGRAPH_1="Arris connects with all major granite and quartz producers, ensuring a diverse selection of top-quality materials for users. This broad access allows businesses to deliver superior products and services, making Arris not just a software solution, but a key to industry excellence."
}

export enum Row10 {
    TITLE="JOIN THE EVER GROWING ARRIS COMMUNITY",
        // Subtitles //
        SUBTITLE_1="Our",
        SUBTITLE_2="Partners",
        // Paragraphs //
        PARAGRAPH_1="Arris connects with all major granite and quartz producers, ensuring a diverse selection of top-quality materials for users. This broad access allows businesses to deliver superior products and services, making Arris not just a software solution, but a key to industry excellence."
}

