import { Material } from "arris_dist";
import { Action } from "api/action_types/material";
import { ActionType } from "api/action_types/material";

interface State {
    materials: Material[],
    loading: boolean,
    error: string
}

const initialState = {
    materials: [],
    loading: false,
    error: ""
}

export const materialReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.GET_MATERIALS:
            return {
                ...state,
                materials: action.payload,
                loading: false,
                error: ""
            }
        case ActionType.MATERIAL_PENDING:
            return {
                ...state,
                loading: true
            }
        case ActionType.MATERIAL_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}