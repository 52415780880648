import MdStreamline from "./md";
import SmStreamline from "./sm";
import XsStreamline from "./xs";

interface Props {
    is_phone: boolean,
    is_tablet: boolean
}

export default function Streamline({is_phone, is_tablet}: Props){
    if(is_phone){
        return <XsStreamline />;
    }
    else if(is_tablet){
        <SmStreamline />;
    }
    return <MdStreamline />;
}