import Merchant1 from "static/merchant1.webp";
import { large_area_no_padding } from "styles/values";

export const merchantCustomersGridStyle = {
    height: "100%"
}

export const merchantCustomersImageStyle = {
    height: {
        "xs": `25vh`,
        "sm": `${large_area_no_padding}vh`,
        "md": "75vh"
    },
    WebKitBorderRadius: 4,
    MozBorderRadius: 5,
    backgroundImage: `url(${Merchant1})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: 4,
    backgroundPositionY: "10%",
}
