import { Sink } from "arris_dist";

export enum ActionType {
    CREATE_SINK_SUCCESS="CREATE_SINK_SUCCESS",
    UPDATE_SINK_SUCCESS="UPDATE_SINK_SUCCESS",
    DELETE_SINK_SUCCESS="DELETE_SINK_SUCCESS"
}

interface deleteSinkSuccess {
    type: ActionType.DELETE_SINK_SUCCESS,
    payload: string
}

interface updateSinkSuccess {
    type: ActionType.UPDATE_SINK_SUCCESS,
    payload: Sink
}

interface createSinkSuccess {
    type: ActionType.CREATE_SINK_SUCCESS,
    payload: Sink
}

export type Action = 
    createSinkSuccess |
    updateSinkSuccess |
    deleteSinkSuccess;