import { Signature } from "arris_dist";
import { Action } from "api/action_types/signature";
import { ActionType } from "api/action_types/signature";

interface State {
    signature: Signature | null,
    already_signed: boolean,
    loading: boolean,
    error: string
}

const initialState = {
    loading: false,
    already_signed: false,
    signature: null,
    error: ""
}

export const signatureReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.GET_SIGNATURE_SUCCESS:
            return {
                ...state,
                loading: false,
                signature: action.signature,
                error: ""
            }
        case ActionType.UPDATE_SIGNATURE_SUCCESS:
            return {
                ...state,
                already_signed: true
            }
        case ActionType.ALREADY_SIGNED:
            return {
                ...state,
                already_signed: true
            }
        case ActionType.SIGNATURE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case ActionType.SIGNATURE_LOADING:
            return{
                ...state,
                loading: true
            }
        default:
            return state;
    }
}