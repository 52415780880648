import { OrderCounter } from "arris_dist";

export enum ActionType {
    GET_ORDER_COUNTERS_SUCCESS="GET_ORDER_COUNTERS_SUCCESS"
}

interface IGetOrderCountersSuccess {
    type: ActionType.GET_ORDER_COUNTERS_SUCCESS,
    order_counters: OrderCounter[]
}

export type Action = IGetOrderCountersSuccess;