/// REACT ///
import { useState } from "react";
import { useEffect } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// HOOKS ///
import { useAppDispatch } from "hooks/api";
import { useAppSelector } from "hooks/api";
/// ACTIONS ///
import { setTryNow } from "api/actions/ui";
import { getRegions } from "api/actions/region";
import { getSubRegions } from "api/actions/region";
import { register } from "api/actions/authentication";
import { resetRegistrationValues } from "api/actions/authentication";
/// TYPES ///
import { Registration } from "arris_dist";
import { RegistrationErrors } from "arris_dist";
import { SubRegion } from "arris_dist/dist/types/region";
/// COMPONENTS ///
import AddressAutocomplete from "components/universal/geo";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
/// ICONS ///
import CloseIcon from "@mui/icons-material/Close";
/// FUNCTIONS ///
import { validateEmail } from "functions/email";
import { validatePhoneNumber } from "functions/phone";
/// URLS ///
import { password_reset_url } from "components/router/endpoints";
/// STYLES ///
import { registrationThankYouGridStyle } from "styles/registration";
import { registrationThankYouContainerStyle } from "styles/registration";
import { registrationFormContainerStyle } from "styles/registration";
import { registrationCloseButtonStyle } from "styles/registration";
import { registrationCloseButtonIconStyle } from "styles/registration";
import { registrationFormInnerContainerStyle } from "styles/registration";
import { registrationFormErrorTextStyle } from "styles/registration";
import { registrationResetPasswordTextStyle } from "styles/registration";
import { registrationTextFieldStyle } from "styles/registration";
import { registrationFormLabelStyle } from "styles/registration";
import { registrationRadioControlStyle } from "styles/registration";
import { registrationSubmitContainer } from "styles/registration";
import { registrationSubmitButtonStyle } from "styles/registration";
import { registrationFormTextFieldSpacer } from "styles/registration";
import { registrationSelectionFormLabel } from "styles/registration";
/// VALUES ///
import { REGISTRATION_THANK_YOU_MESSAGE } from "values/text/registration";

export default function RegistrationComponent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { open } = useAppSelector((state) => state.ui);
  const { loading } = useAppSelector((state) => state.authentication);
  const { error } = useAppSelector((state) => state.authentication);
  const { offer_reset } = useAppSelector((state) => state.authentication);
  const { registration_complete } = useAppSelector((state) => state.authentication);
  const { regions } = useAppSelector((state) => state.region);
  const { sub_regions } = useAppSelector((state) => state.region);
  const [filtered_sub_regions, setFilteredSubRegions] = useState<SubRegion[]>([]);
  const [sub_region_error, setSubRegionError] = useState<string>("");
  const [registration, setRegistration] = useState<Registration>({
    region: 0,
    sub_region: 0,
    email: "",
    first_name: "",
    last_name: "",
    business_name: "",
    phone_number: "",
    address: "",
    city: "",
    province: "AB",
    postal_code: "",
    is_fabricator: false,
    is_distributor: true,
    geo_address: "",
    lat: 0,
    lng: 0
  });

  const [registration_error, setRegistrationError] = useState<RegistrationErrors>({
    region_error: "",
    region_has_error: false,
    email_error: "",
    email_has_error: false,
    first_name_error: "",
    first_name_has_error: false,
    last_name_error: "",
    last_name_has_error: false,
    business_name_error: "",
    business_name_has_error: false,
    address_error: "",
    address_has_error: false,
    city_error: "",
    city_has_error: false,
    postal_code_error: "",
    postal_code_has_error: false,
    phone_number_error: "",
    phone_number_has_error: false,
    geo_error: "",
    geo_has_error: false
  });

  useEffect(() => {
    setFilteredSubRegions(sub_regions.filter(sr => sr.region === registration.region));
  }, [sub_regions, registration?.region]);

  useEffect(() => {
    dispatch(getRegions());
    dispatch(getSubRegions());
    dispatch(resetRegistrationValues());
  }, [dispatch]);

  const handlePasswordReset = () => {
    dispatch(setTryNow(false));
    navigate(`/${password_reset_url}`, { replace: false });
  }

  const saveString = (key: string, value: string) => {
    setRegistration({ ...registration, [key]: value });
  }

  const saveInt = (key: string, value: number) => {
    setRegistration({ ...registration, [key]: value });
  }

  const removeError = (key: string) => {
    setRegistrationError({ ...registration_error, [`${key}_error`]: "", [`${key}_has_error`]: false });
  }

  const handleAccountType = (type: string) => {
    if (type === "dealer") {
      setRegistration({ ...registration, is_fabricator: false, is_distributor: true });
    }
    else if (type === "fabricator") {
      setRegistration({ ...registration, is_fabricator: true, is_distributor: false });
    }
  }

  const updateGeo = (label: string, lat: number, lng: number, address: string, city: string, province: string) => {
    console.log(label, lat, lng, address, city, province);
    setRegistration({ ...registration, geo_address: label, lat: lat, lng: lng, address: address, city: city, province: province });
  }

  const geoError = () => {
    setRegistrationError({ ...registration_error, geo_has_error: true, geo_error: "Unable to location this address, please search and choose another" })
  }

  const handleRegistration = () => {
    console.log(registration);
    let error: boolean = false;
    const new_registration_error = { ...registration_error };

    if (!validateEmail(registration?.email)) {
      new_registration_error.email_error = "Enter a valid email address";
      new_registration_error.email_has_error = true;
      error = true;
    }

    if (!validatePhoneNumber(registration?.phone_number)) {
      new_registration_error.phone_number_error = "Enter a valid phone number";
      new_registration_error.phone_number_has_error = true;
      error = true;
    }

    if (registration?.region === 0) {
      new_registration_error.region_error = "Must choose a province";
      new_registration_error.region_has_error = true;
      error = true;
    }

    if (registration?.first_name === "") {
      new_registration_error.first_name_error = "Must enter a first name";
      new_registration_error.first_name_has_error = true;
      error = true;
    }

    if (registration?.last_name === "") {
      new_registration_error.last_name_error = "Must enter a last name";
      new_registration_error.last_name_has_error = true;
      error = true;
    }

    if (registration?.business_name === "") {
      new_registration_error.business_name_error = "Must enter a business name";
      new_registration_error.business_name_has_error = true;
      error = true;
    }

    if (!(registration?.lng) || !(registration.lng !== 0) || !(registration?.lat) || !(registration.lat !== 0)) {
      new_registration_error.geo_error = "Unable to locate this address, please re enter and choose from the drop down";
      new_registration_error.geo_has_error = true;
      error = true;
    }
    setRegistrationError(new_registration_error);


    if (!(registration?.sub_region) || registration.sub_region === 0) {
      setSubRegionError("Must enter a region");
      error = true;
    }

    if (error) {
      return;
    }

    dispatch(register(registration));
  }

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Escape")) {
      dispatch(setTryNow(false));
    }
  };

  return (
    <Drawer onKeyDown={toggleDrawer} variant="temporary" anchor="bottom" open={open}>
      {
        registration_complete ?
          <Box sx={registrationThankYouContainerStyle} role="presentation">
            <Grid container sx={registrationThankYouGridStyle}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {REGISTRATION_THANK_YOU_MESSAGE}
                </Typography>
              </Grid>
            </Grid>
          </Box> :
          <Box sx={registrationFormContainerStyle} role="presentation">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography variant="h6" sx={{ paddingLeft: "50px", paddingTop: "25px" }}>
                Sign Up
              </Typography>
              <IconButton
                onClick={() => dispatch(setTryNow(false))}
                sx={registrationCloseButtonStyle}>
                <CloseIcon sx={registrationCloseButtonIconStyle} />
              </IconButton>
            </div>
            <Grid container sx={registrationFormInnerContainerStyle}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" sx={registrationFormErrorTextStyle}>
                    {error}
                  </Typography>
                  {
                    offer_reset ?
                      <Typography
                        variant="body2"
                        sx={registrationResetPasswordTextStyle}
                        onClick={handlePasswordReset}>
                        Reset password?
                      </Typography> :
                      null
                  }
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", paddingRight: "20px" }}>
                  <FormLabel sx={registrationFormLabelStyle} id="Info">Name</FormLabel>
                  <TextField
                    sx={registrationTextFieldStyle}
                    InputLabelProps={{ shrink: true, style: { color: "#fff" } }}
                    onFocus={() => removeError("first_name")}
                    error={registration_error?.first_name_has_error}
                    helperText={registration_error?.first_name_error ?? ""}
                    size="small"
                    label="First Name"
                    id="first_name"
                    onChange={e => saveString("first_name", e.target.value)} />
                  <TextField
                    sx={registrationTextFieldStyle}
                    InputLabelProps={{ shrink: true, style: { color: "#fff" } }}
                    onFocus={() => removeError("last_name")}
                    error={registration_error?.last_name_has_error}
                    helperText={registration_error?.last_name_error ?? ""}
                    size="small"
                    label="Last Name"
                    id="last_name"
                    onChange={e => saveString("last_name", e.target.value)} />
                  <TextField
                    sx={registrationTextFieldStyle}
                    InputLabelProps={{ shrink: true, style: { color: "#fff" } }}
                    onFocus={() => removeError("business_name")}
                    error={registration_error?.business_name_has_error}
                    helperText={registration_error?.business_name_error}
                    size="small"
                    label="Business Name"
                    id="business_name"
                    onChange={e => saveString("business_name", e.target.value)} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", paddingRight: "20px" }}>
                  <FormLabel sx={registrationFormLabelStyle} id="address">Address</FormLabel>
                  <TextField
                    sx={registrationTextFieldStyle}
                    InputLabelProps={{ shrink: true, style: { color: "#fff" } }}
                    onFocus={() => removeError("region")}
                    error={registration_error?.region_has_error}
                    helperText={registration_error?.region_error ?? ""}
                    size="small"
                    defaultValue={0}
                    label="Main Region"
                    select
                    onChange={e => saveInt("region", Number(e.target.value))}>
                    <MenuItem key={0} value={0}>
                      {"         "}
                    </MenuItem>
                    {
                      regions.map(region => {
                        return (
                          <MenuItem key={region.id} value={region.id}>
                            {region.name}
                          </MenuItem>
                        )
                      })
                    }
                  </TextField>
                  <TextField
                    sx={registrationTextFieldStyle}
                    InputLabelProps={{ shrink: true, style: { color: "#fff" } }}
                    onFocus={() => removeError("sub_region")}
                    error={sub_region_error !== ""}
                    helperText={sub_region_error}
                    size="small"
                    defaultValue={0}
                    label="Sub Region"
                    select
                    onChange={e => saveInt("sub_region", Number(e.target.value))}>
                    <MenuItem key={0} value={0}>
                      {"         "}
                    </MenuItem>
                    {
                      filtered_sub_regions.map(sub_region => {
                        return (
                          <MenuItem key={sub_region.id} value={sub_region.id}>
                            {sub_region.name}
                          </MenuItem>
                        )
                      })
                    }
                  </TextField>
                  <AddressAutocomplete
                    label={registration?.geo_address ?? ""}
                    error={registration_error?.geo_error}
                    has_error={registration_error?.geo_has_error}
                    geoError={geoError}
                    updateGeo={updateGeo}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", paddingRight: "20px" }}>
                  <FormLabel sx={registrationFormLabelStyle} id="contact">Contact</FormLabel>
                  <TextField
                    autoCapitalize="none"
                    sx={registrationTextFieldStyle}
                    InputLabelProps={{ shrink: true, style: { color: "#fff" } }}
                    onFocus={() => removeError("email")}
                    error={registration_error?.email_has_error}
                    helperText={registration_error?.email_error ?? ""}
                    size="small"
                    label="Email"
                    id="email"
                    onChange={e => saveString("email", e.target.value)} />
                  <TextField
                    sx={registrationTextFieldStyle}
                    InputLabelProps={{ shrink: true, style: { color: "#fff" } }}
                    onFocus={() => removeError("phone_number")}
                    error={registration_error?.phone_number_has_error}
                    helperText={registration_error?.phone_number_error ?? ""}
                    size="small"
                    label="Phone: 555-555-5555"
                    id="phone_number"
                    onChange={e => saveString("phone_number", e.target.value)} />
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", paddingRight: "20px" }}>
                  <FormLabel sx={registrationSelectionFormLabel} id="account_type">
                    I am a
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="splash-group-application"
                    defaultValue="dealer"
                    name="splash-group-application"
                    onChange={(e) => handleAccountType(e.target.value)} >
                    <FormControlLabel
                      sx={registrationFormLabelStyle}
                      value="dealer"
                      control={<Radio sx={registrationRadioControlStyle} />}
                      label={
                        <Typography variant="body1" sx={registrationFormLabelStyle}>
                          Dealer
                        </Typography>
                      } />
                    <FormControlLabel
                      sx={registrationFormLabelStyle}
                      value="fabricator"
                      control={<Radio sx={registrationRadioControlStyle} />}
                      label={
                        <Typography variant="body1" sx={registrationFormLabelStyle}>
                          Fabricator
                        </Typography>
                      } />
                  </RadioGroup>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" sx={registrationSubmitContainer}>
                  {
                    loading ?
                      <CircularProgress /> :
                      <Button
                        onClick={handleRegistration}
                        sx={registrationSubmitButtonStyle}>
                        Submit
                      </Button>
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
      }
    </Drawer>
  );
}
