import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Row8 } from "values/text/home";
import { homeSavingsContainerStyle } from "styles/home_page/savings";
import { homeSavingsGridContainerStyle } from "styles/home_page/savings";
import { homeSavingsImageStyle } from "styles/home_page/savings";
import { homeSavingsExampleContainer } from "styles/home_page/savings";
import { homeSavingsTitleWrapper } from "styles/home_page/savings";
import { homeSavingsCellNumberText } from "styles/home_page/savings";
import { homeSavingsCellDivider } from "styles/home_page/savings";
import { homeSavingsCellDividerThin } from "styles/home_page/savings";
import { homeSavingsCellWrapper } from "styles/home_page/savings";
import { homeSavingsCellDescriptionText } from "styles/home_page/savings";
import { homeSavingsLowerCellGridWrapper } from "styles/home_page/savings";

export default function MdSavings(){
    return (
        <Box sx={homeSavingsContainerStyle}>
            <Grid container sx={homeSavingsGridContainerStyle}>
                <Grid item xs={6} sx={homeSavingsImageStyle}></Grid>
                <Grid container item xs={6} sx={homeSavingsExampleContainer}>
                    <Grid item xs={12} sx={homeSavingsTitleWrapper}>
                        <Typography variant="h5" textAlign="left">
                            {Row8.TITLE}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction="row" alignItems="baseline">
                            <Typography variant="h2">
                                {Row8.CELL_1_NUMBER}
                            </Typography>
                            <Typography variant="h6" sx={homeSavingsCellNumberText}>
                                {Row8.CELL_1_NUMBER_TYPE}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction="row" alignItems="baseline">
                            <Typography variant="h2">
                                {Row8.CELL_2_NUMBER}
                            </Typography>
                            <Typography variant="h6" sx={homeSavingsCellNumberText}>
                                {Row8.CELL_2_NUMBER_TYPE}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={0.5} sx={homeSavingsCellDivider}></Grid>
                    <Grid item xs={2.5} sx={homeSavingsCellDividerThin}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={0.5} sx={homeSavingsCellDivider}></Grid>
                    <Grid item xs={2.5} sx={homeSavingsCellDividerThin}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                        <Stack sx={homeSavingsCellWrapper}>
                            <Typography variant="h6" sx={homeSavingsCellDescriptionText}>
                                {Row8.CELL_1_DESCRIPTION_1}
                            </Typography>
                            <Typography variant="h6" sx={homeSavingsCellDescriptionText}>
                                {Row8.CELL_1_DESCRIPTION_2}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack sx={homeSavingsCellWrapper}>
                            <Typography variant="h6" sx={homeSavingsCellDescriptionText}>
                                {Row8.CELL_2_DESCRIPTION_1}
                            </Typography>
                            <Typography variant="h6" sx={homeSavingsCellDescriptionText}>
                                {Row8.CELL_2_DESCRIPTION_2}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} sx={homeSavingsLowerCellGridWrapper}>
                        <Stack direction="row" alignItems="baseline">
                            <Typography variant="h2">
                                {Row8.CELL_3_NUMBER}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} sx={homeSavingsLowerCellGridWrapper}>
                        <Stack direction="row" alignItems="baseline">
                            <Typography variant="h2">
                                {Row8.CELL_4_NUMBER}
                            </Typography>
                            <Typography variant="h6" sx={homeSavingsCellNumberText}>
                                {Row8.CELL_4_NUMBER_TYPE}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={0.5} sx={homeSavingsCellDivider}></Grid>
                    <Grid item xs={2.5} sx={homeSavingsCellDividerThin}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={0.5} sx={homeSavingsCellDivider}></Grid>
                    <Grid item xs={2.5} sx={homeSavingsCellDividerThin}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                        <Stack sx={homeSavingsCellWrapper}>
                            <Typography variant="h6" sx={homeSavingsCellDescriptionText}>
                                {Row8.CELL_3_DESCRIPTION_1}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack sx={homeSavingsCellWrapper}>
                            <Typography variant="h6" sx={homeSavingsCellDescriptionText}>
                                {Row8.CELL_4_DESCRIPTION_1}
                            </Typography>
                            <Typography variant="h6" sx={homeSavingsCellDescriptionText}>
                                {Row8.CELL_4_DESCRIPTION_2}
                            </Typography>
                        </Stack>
                    </Grid>
            
                </Grid>
            </Grid>
        </Box>
    )
}