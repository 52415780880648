/// TYPES ///
import { OrderCounter } from "arris_dist";
import { SelfOrderCounter } from "arris_dist";
import { Vector2 } from "arris_dist";
/// FUNCTIONS ///
import { drawPrintOrderCounter } from "arris_dist";
import { drawPrintCooktop } from "arris_dist";
import { drawPrintSink } from "arris_dist";
import { getCenterOfCounter } from "arris_dist";
import { getOptimalZoom } from "arris_dist";
/// COMPONENTS ///
import { View } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
import { Canvas } from "@react-pdf/renderer";

interface Props {
    counter: OrderCounter | SelfOrderCounter
}

export default function PrintOrderCounter({counter}: Props) {
    const paintCanvas = (painter: any, availableWidth: number, availableHeight: number) => {
        const zoom: number = getOptimalZoom(counter, {X: availableWidth - 75, Y: availableHeight - 75});
        const zero_center: Vector2 = getCenterOfCounter(counter, zoom);
        painter.fontSize(9);

        const offset: Vector2 = {
            X: availableWidth / 2 - zero_center.X,
            Y: availableHeight / 2 - zero_center.Y
        };

        drawPrintOrderCounter(
            counter.corners,
            counter.points,
            painter, 
            offset, 
            zoom
        );

        drawPrintCooktop(painter, counter, offset, zoom);
        drawPrintSink(painter, counter, offset, zoom);
        painter.stroke("#666666");   
        return null;
    }
            
    return (
        <View style={{marginTop: "25px", flexBasis: "50%"}} wrap={false}>
            <Text style={{fontSize: 10, fontFamily: "Montserrat"}}>{counter.material_name} {counter.color_name}</Text>
            <Canvas
                style={{height: "300px"}}
                paint={paintCanvas}
            />
        </View>
    );
}