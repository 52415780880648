import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TimelineBox from "../timeline_box";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Row3 } from "values/text/about";
import { aboutTimelineArrowContainerStyle } from "styles/about_page/timeline";
import { aboutTimelineTitleStyle } from "styles/about_page/timeline";
import { aboutTimelineGridGutterStyle } from "styles/about_page/timeline";
import { aboutTimelineGridCenterGridStyle } from "styles/about_page/timeline";
import { aboutTimelineCenterContainerStyle } from "styles/about_page/timeline";
import { aboutTimelineContainerStyle } from "styles/about_page/timeline";


export default function XsTimeline(){
    const [timeline_index, setTimelineIndex] = useState<number>(0);

    const incrementTimelineIndex = () => {
        if(timeline_index === 2){
            setTimelineIndex(0);
        }
        else{
            if(timeline_index < 2){
                setTimelineIndex(prev => prev + 1);
            }
        }
    }

    const decrementTimelineIndex = () => {
        if(timeline_index === 0){
            setTimelineIndex(2);
        }
        else{
            setTimelineIndex(prev => prev - 1);
        }
    }

    return (
        <Box sx={aboutTimelineContainerStyle}>
            <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    <Typography variant="h5" sx={aboutTimelineTitleStyle}>
                        {Row3.TITLE}
                    </Typography>
                </Grid>
                <Grid item xs={1} sx={aboutTimelineGridGutterStyle}>
                    <Box 
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={aboutTimelineArrowContainerStyle}>
                        <IconButton onClick={decrementTimelineIndex}>
                            <ArrowBackIosIcon />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={10} sx={aboutTimelineGridCenterGridStyle}>
                    <Box 
                        sx={aboutTimelineCenterContainerStyle}
                        display="flex"
                        alignItems="center"
                        justifyContent="center">
                            {
                                timeline_index === 0 ?
                                <TimelineBox 
                                    year={Row3.CELL_1_YEAR}
                                    title={Row3.CELL_1_TITLE}
                                    paragraph={Row3.CELL_1_PARAGRAPH}
                                    is_phone={true} /> :
                                null
                            }
                            {
                                timeline_index === 1 ?
                                <TimelineBox 
                                    year={Row3.CELL_2_YEAR}
                                    title={Row3.CELL_2_TITLE}
                                    paragraph={Row3.CELL_2_PARAGRAPH}
                                    shift_right={true}
                                    is_phone={true} /> :
                                null
                            }
                    </Box>
                </Grid>
                <Grid item xs={1} sx={aboutTimelineGridGutterStyle}>
                    <Box 
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={aboutTimelineArrowContainerStyle}>
                        <IconButton onClick={incrementTimelineIndex}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>    
        </Box>
    )
}