import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TimelineBox from "../timeline_box";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Row3 } from "values/text/about";
import { aboutTimelineArrowContainerStyle } from "styles/about_page/timeline";
import { aboutTimelineTitleStyle } from "styles/about_page/timeline";
import { aboutTimelineGridGutterStyle } from "styles/about_page/timeline";
import { aboutTimelineGridCenterGridStyle } from "styles/about_page/timeline";
import { aboutTimelineCenterContainerStyle } from "styles/about_page/timeline";
import { aboutTimelineContainerStyle } from "styles/about_page/timeline";
import { aboutTimelineDividerOuterWrapperStyle } from "styles/about_page/timeline";
import { aboutTimelineDividerInnerWrapperStyle } from "styles/about_page/timeline";
import { aboutTimelineDividerStyle } from "styles/about_page/timeline";
import { aboutTimelineCenterInnerContainerStyle } from "styles/about_page/timeline";

export default function SmTimeline(){
    const [timeline_index, setTimelineIndex] = useState<number>(0);

    const incrementTimelineIndex = () => {
        return;
        if(timeline_index === 2){
            setTimelineIndex(0);
        }
        else{
            setTimelineIndex(prev => prev + 1);
        }
    }

    const decrementTimelineIndex = () => {
        return;
        if(timeline_index === 0){
            setTimelineIndex(2);
        }
        else{
            setTimelineIndex(prev => prev - 1);
        }
    }

    return (
        <Box sx={aboutTimelineContainerStyle}>
            <Grid container>
                <Grid item xs={1.15}></Grid>
                <Grid item xs={10}>
                    <Typography variant="h5" sx={aboutTimelineTitleStyle}>
                        {Row3.TITLE}
                    </Typography>
                </Grid>
                <Grid item xs={1} sx={aboutTimelineGridGutterStyle}>
                    <Box 
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={aboutTimelineArrowContainerStyle}>
                        <IconButton onClick={decrementTimelineIndex}>
                            <ArrowBackIosIcon />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid container item xs={10} sx={aboutTimelineGridCenterGridStyle}>
                    <Grid item xs={12}>
                        <Box 
                            sx={aboutTimelineCenterContainerStyle}
                            display="flex"
                            alignItems="center"
                            justifyContent="center">
                                {
                                    timeline_index === 0 ?
                                    <TimelineBox 
                                        is_tablet={true}
                                        year={Row3.CELL_1_YEAR}
                                        title={Row3.CELL_1_TITLE}
                                        paragraph={Row3.CELL_1_PARAGRAPH} /> :
                                    null
                                }
                                {
                                    timeline_index === 1 ?
                                    <TimelineBox 
                                        is_tablet={true}
                                        year={Row3.CELL_3_YEAR}
                                        title={Row3.CELL_3_TITLE}
                                        paragraph={Row3.CELL_3_PARAGRAPH}
                                        shift_right={true} /> :
                                    null
                                }
                                {
                                    timeline_index === 2 ?
                                    <TimelineBox 
                                        is_tablet={true}
                                        year={Row3.CELL_5_YEAR}
                                        title={Row3.CELL_5_TITLE}
                                        paragraph={Row3.CELL_5_PARAGRAPH} /> :
                                    null
                                }
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={aboutTimelineDividerOuterWrapperStyle}>
                        <Box 
                            sx={aboutTimelineDividerInnerWrapperStyle}
                            display="flex"
                            alignItems="center"
                            justifyContent="center">
                            <Box
                                sx={aboutTimelineDividerStyle}></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={aboutTimelineCenterContainerStyle}>
                        <Box 
                            sx={aboutTimelineCenterInnerContainerStyle}
                            display="flex"
                            alignItems="center"
                            justifyContent="center">
                                {
                                    timeline_index === 0 ?
                                    <TimelineBox 
                                        is_tablet={true}
                                        year={Row3.CELL_2_YEAR}
                                        title={Row3.CELL_2_TITLE}
                                        paragraph={Row3.CELL_2_PARAGRAPH}
                                        shift_right={true} /> :
                                    null
                                }
                                {
                                    timeline_index === 1 ?
                                    <TimelineBox 
                                        is_tablet={true}
                                        year={Row3.CELL_4_YEAR}
                                        title={Row3.CELL_4_TITLE}
                                        paragraph={Row3.CELL_4_PARAGRAPH} /> :
                                    null
                                }
                                {
                                    timeline_index === 2 ?
                                    <TimelineBox 
                                        is_tablet={true}
                                        year={Row3.CELL_6_YEAR}
                                        title={Row3.CELL_6_TITLE}
                                        paragraph={Row3.CELL_6_PARAGRAPH}
                                        shift_right={true} /> :
                                    null
                                }
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={1} sx={aboutTimelineGridGutterStyle}>
                    <Box 
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={aboutTimelineArrowContainerStyle}>
                        <IconButton onClick={incrementTimelineIndex}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>    
        </Box>
    )
}