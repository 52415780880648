import { SelfOrderArea } from "arris_dist";
import { SelfOrderCounter } from "arris_dist";
import { SelfOrderSlab } from "arris_dist";
import { SelfOrder } from "arris_dist";
import { User } from "arris_dist";
import { Action } from "api/action_types/self_order";
import { ActionType } from "api/action_types/self_order";

interface State {
    self_order_counters: SelfOrderCounter[],
    self_order: SelfOrder | null,
    vendor: User,
    self_order_areas: SelfOrderArea[],
    self_order_slabs: SelfOrderSlab[],
    loading: boolean,
    error: string
}

const initialState = {
    self_order_counters: [],
    self_order: null,
    self_order_areas: [],
    self_order_slabs: [],
    vendor: {},
    loading: false,
    error: ""
}

export const selfOrderReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.GET_SELF_ORDER_SUCCESS:
            return {
                ...state,
                self_order: action.self_order,
                loading: false,
                error: ""
            }
        case ActionType.GET_SELF_ORDER_AREAS_SUCCESS:
            return {
                ...state,
                self_order_areas: action.self_order_areas
            }
        case ActionType.GET_SELF_ORDER_COUNTERS_SUCCESS:
            return {
                ...state,
                self_order_counters: action.self_order_counters,
                loading: false,
                error: ""
            }
        case ActionType.GET_SELF_ORDER_SLABS_SUCCESS:
            return {
                ...state,
                self_order_slabs: action.self_order_slabs,
                loading: false,
                error: ""
            }
        case ActionType.GET_SELF_ORDER_VENDOR_SUCCESS:
            return {
                ...state,
                vendor: action.vendor
            }
        case ActionType.SELF_ORDER_ERROR:
            return {
                ...state,
                loading: false,
                error: ""
            }
        case ActionType.SELF_ORDER_LOADING:
            return {
                ...state,
                loading: true
            }
        case ActionType.SELF_ORDER_UPDATING:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}