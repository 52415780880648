import { Bump } from "arris_dist";

export enum ActionType {
    CREATE_BUMP="CREATE_BUMP",
    UPDATE_BUMP="UPDATE_BUMP",
    DELETE_BUMP="DELETE_BUMP"
}

interface createBump {
    type: ActionType.CREATE_BUMP,
    payload: Bump,
    counter_uuid: string
}

interface updateBump {
    type: ActionType.UPDATE_BUMP,
    payload: Bump,
    counter_uuid: string
}

interface deleteBump {
    type: ActionType.DELETE_BUMP,
    payload: string,
    counter_uuid: string
}

export type Action = createBump | updateBump | deleteBump;