import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Partner from "../partner";
import StatementBox from "components/universal/statement_box";
import { Row9 } from "values/text/home";
import { primary_color } from "styles/theme";
import { white_color } from "styles/theme";
import CambriaImage from "static/cambria.webp";
import CaesarstoneImage from "static/caesarstone.webp";
import HariStonesImage from "static/hari_stones.webp";
import SilstoneImage from "static/silstone.webp";
import { homePartnersContainerStyle } from "styles/home_page/partners";
import { homePartnersPartnersContainerStyle } from "styles/home_page/partners";

export default function XsOurPartners(){
    return (
        <Box sx={homePartnersContainerStyle}>
            <Grid container>
                <StatementBox 
                    title={Row9.TITLE}
                    subtitle1={Row9.SUBTITLE_1}
                    subtitle2={Row9.SUBTITLE_2}
                    paragraph={Row9.PARAGRAPH_1}
                    text_color={white_color}
                    background_color={primary_color}
                    border_color={white_color} 
                    paddingTop={5}
                    is_phone={true} />
                <Grid container item xs={12} sx={homePartnersPartnersContainerStyle}>
                    <Partner image={SilstoneImage} name="Silestone" />
                    <Partner image={CaesarstoneImage} name="Caesarstone" />
                    <Partner image={CambriaImage} name="Cambria" />
                    <Partner image={HariStonesImage} name="Hari Stones" />
                </Grid>
            </Grid>
        </Box>
    )
}