import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Grid";
import { commentBoxTitle } from "styles/universal/comment_box";
import { commentBoxParagraph } from "styles/universal/comment_box";

interface Props {
    title: string,
    paragraph: string,
    text_color: string,
    right?: boolean
}

export default function XsCommentBox({title, paragraph, text_color, right}: Props) {
    return (
        <Grid item xs={12}>
            <Stack>
                <Typography variant="h6" sx={commentBoxTitle(text_color)}>
                    {title}
                </Typography>
                {
                    paragraph ?
                    <Typography variant="body1" sx={commentBoxParagraph(text_color)}>
                        {paragraph}
                    </Typography> :
                    null
                }
            </Stack>
        </Grid>
  );
}
