import MdGrowth from "./md";
import SmGrowth from "./sm";
import XsGrowth from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function Growth({is_tablet, is_phone}: Props){
    if(is_phone){
        return <XsGrowth />;
    }
    else if(is_tablet){
        return <SmGrowth />;
    }
    return <MdGrowth />;
}