import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { primary_color } from "styles/theme";

export default function PrintSummaryHeaders() {   
    const styles = StyleSheet.create({
        text: {
            fontFamily: "Montserrat",
            fontSize: 11,
            fontWeight: "bold",
            color: "#ffffff"
        },
        text_right: {
            fontFamily: "Montserrat",
            fontSize: 11,
            fontWeight: "bold",
            color: "#ffffff",
            textAlign: "right"
        }
    });

    return (
        <View style={{marginTop: "15px", display: "flex", flexDirection: "row", backgroundColor: primary_color}}>
            <View style={{width: "50%", paddingTop: "2px", paddingBottom: "3px", paddingLeft: "15px"}}>
                <Text style={styles.text}>
                    Product
                </Text>
            </View>
            <View style={{width: "25%", marginLeft: "0.5%", paddingTop: "2px", paddingBottom: "3px"}}>
                <Text style={styles.text}>
                    Quantity
                </Text>
            </View>
            <View style={{width: "25%", marginLeft: "0.5%", paddingTop: "2px", paddingBottom: "3px", paddingRight: "12px"}}>
                <Text style={styles.text_right}>
                    Price
                </Text>
            </View>
        </View>
    );
}