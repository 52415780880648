/// TYPES ///
import { OrderSlab } from "arris_dist";
import { SelfOrderSlab } from "arris_dist";
/// COMPONENTS ///
import { Fragment } from "react";
import PrintOrderSlab from "./slab";

interface Props {
    slabs: OrderSlab[] | SelfOrderSlab[]
}

export default function PrintOrderSlabs({slabs}: Props) {
    return (
        <Fragment>
            {
                slabs.map((slab, index) => {
                    return (
                        <PrintOrderSlab 
                            key={index}
                            index={index}
                            slab={slab}/>
                    )
                })
            }
        </Fragment>
    );
}