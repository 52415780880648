import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";

export default function PrintSignature() {
  const styles = StyleSheet.create({
    body2: {
      fontFamily: "Montserrat",
      fontSize: 10
    }
  });

  return (
    <View style={{ width: "100%", paddingLeft: "5px" }} wrap={false}>
      <View style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: "50px" }}>
        <View style={{ width: "160px" }}>
          <Text style={styles.body2}>
            Accepted By:
          </Text>
        </View>
        <View style={{ width: "160px" }}>
          <Text style={styles.body2}>
            Signature:
          </Text>
        </View>
        <View style={{ width: "160px" }}>
          <Text style={styles.body2}>
            Date:
          </Text>
        </View>
      </View>
      <View style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: "75px" }}>
        <View style={{ width: "140px", borderBottom: '1px solid black' }}>

        </View>
        <View style={{ width: "140px", borderBottom: '1px solid black', marginLeft: "20px" }}>

        </View>
        <View style={{ width: "140px", borderBottom: '1px solid black', marginLeft: "20px" }}>

        </View>
      </View>
    </View>
  );
}
