import Fab2 from "static/fab2.webp";
import { black_color } from "styles/theme";
import { medium_area } from "styles/values";

export const fabricatorStreamlineContainerStyle = {
    backgroundColor: black_color
}

export const fabricatorStreamlineGridStyle = {
    height: "100%"
}

export const fabricatorStreamlineImageStyle = {
    backgroundImage: `url(${Fab2})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: {
        "xs": "auto",
        "sm": `${medium_area}vh`,
        "md": "auto"
    }
}