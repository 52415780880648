import { Line } from "arris_dist";

export enum ActionType {
    SET_SELECTED_LINE="SET_SELECTED_LINE"
}

interface setSelectedLine {
    type: ActionType.SET_SELECTED_LINE,
    payload: Line | null
}

export type Action = setSelectedLine;