import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { primary_color } from "styles/theme";

interface Props {
    discount: number,
    tax_name: string,
    tax: number,
    second_tax_name: string,
    second_tax: number,
    total: number
}

export default function PrintSummary({discount, tax_name, tax, second_tax_name, second_tax, total}: Props) {        
    const styles = StyleSheet.create({
        body2: {
            fontFamily: "Montserrat",
            fontSize: 10,
            fontWeight: "bold",
            whiteSpace: "no-wrap"
        },
        body2_right: {
            fontFamily: "Montserrat",
            fontSize: 10,
            fontWeight: "bold",
            textAlign: "right"
        },
        total: {
            fontFamily: "Montserrat",
            fontSize: 12,
            fontWeight: "bold",
            color: primary_color
        },
        total_right: {
            fontFamily: "Montserrat",
            fontSize: 12,
            fontWeight: "bold",
            textAlign: "right",
            color: primary_color
        }
    });
    
    return (
        <View style={{marginTop: "25px", marginLeft: "15px", marginRight: "15px", display: "flex", flexDirection: "row"}}>
            <View style={{width: "50%"}}>

            </View>
            <View style={{width: "20%"}}>
                {
                    discount ? 
                    <Text style={styles.body2}>
                        Discount %
                    </Text> :
                    null
                }
                <Text style={styles.body2}>
                    Subtotal
                </Text>
                {
                    discount ? 
                    <Text style={styles.body2}>
                        Discount
                    </Text> :
                    null
                }
                <Text style={styles.body2}>
                    {tax_name !== "" ? tax_name : "Tax"}
                </Text>
                {
                    second_tax ? 
                    <Text style={styles.body2}>
                        {second_tax_name !== "" ? second_tax_name : "Second Tax"}
                    </Text> :
                    null
                }
                <Text style={styles.total}>
                    Total
                </Text>
            </View>
            <View style={{width: "30%"}}>
                {
                    discount ? 
                    <Text style={styles.body2_right}>
                        {
                            discount.toFixed(2)
                        }%
                    </Text> :
                    null
                }
                <Text style={styles.body2_right}>
                    ${
                        total.toFixed(2)
                    }
                </Text>
                {
                    discount ? 
                    <Text style={styles.body2_right}>
                        $({
                            (Math.round(discount * total) / 100).toFixed(2)
                        })
                    </Text>:
                    null
                }
                <Text style={styles.body2_right}>
                    ${
                        (tax - (tax * (discount / 100))).toFixed(2)
                    }    
                </Text>
                {
                    second_tax ? 
                    <Text style={styles.body2_right}>
                        ${
                            (second_tax - (second_tax * (discount / 100))).toFixed(2)
                        }    
                    </Text> :
                    null
                }
                <Text style={styles.total_right}>
                    ${
                        (
                            total +
                            tax +
                            second_tax - 
                            Math.round(total * discount) / 100 - 
                            Math.round(tax * discount) / 100 - 
                            Math.round(second_tax * discount) / 100).toFixed(2)
                    }
                </Text>
            </View>
        </View>
    );
}