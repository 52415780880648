/// TYPES ///
import { Order } from "arris_dist";
import { SelfOrder } from "arris_dist";
/// COMPONENTS ///
import { Fragment } from "react";
import PrintSummary from "../shared/summary";

interface Props {
    order: Order | SelfOrder
}

export default function PrintOrderSummary({order}: Props) {    
    return (
        <Fragment>
            <PrintSummary 
                total={order.total !== null && order.total !== undefined ? order.total : 0}
                tax_name={order.tax_name}
                tax={order.tax !== null && order.tax !== undefined ? order.tax : 0}
                second_tax_name={order.second_tax_name}
                second_tax={order.second_tax !== null && order.second_tax !== undefined ? order.second_tax : 0}
                discount={0} />
        </Fragment>
    );
}