import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Animation from "../animation";
import Description from "../description";
import { largeAreaStyle } from "styles/general";
import { Row1 } from "values/text/home";
import { heroWhyContainerStyle } from "styles/home_page/why";
import { heroWhyTitleStyle } from "styles/home_page/why";

export default function XsWhyArris() {
    return(
        <Box sx={[largeAreaStyle, heroWhyContainerStyle]}>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Typography variant="h2" textAlign="center" sx={heroWhyTitleStyle}>
                        {Row1.TITLE}
                    </Typography>
                </Grid>
                <Animation src="/assets/video/icon1.mp4" is_phone={true} />
                <Description subtitle={Row1.CELL_1_SUBTITLE} paragraph={Row1.CELL_1_PARAGRAPH} is_phone={true} />
                <Animation src="/assets/video/icon2.mp4" is_phone={true} />
                <Description subtitle={Row1.CELL_2_SUBTITLE} paragraph={Row1.CELL_2_PARAGRAPH} is_phone={true} />
                <Animation src="/assets/video/icon3.mp4" is_phone={true} />
                <Description subtitle={Row1.CELL_3_SUBTITLE} paragraph={Row1.CELL_3_PARAGRAPH} is_phone={true} />
            </Grid>
        </Box>
    )
}
