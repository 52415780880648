import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { homeSavingsCellNumberText } from "styles/home_page/savings";
import { homeSavingsCellDivider } from "styles/home_page/savings";
import { homeSavingsCellDividerThin } from "styles/home_page/savings";
import { homeSavingsCellWrapper } from "styles/home_page/savings";
import { homeSavingsCellDescriptionText } from "styles/home_page/savings";

interface Props {
    number: string,
    number_type: string,
    description1: string,
    description2: string
}

export default function SavingsExample({number, number_type, description1, description2}: Props){
    return (
        <Grid container item xs={6} alignItems="baseline">
            <Grid item xs={12}>
                <Stack direction="row" alignItems="baseline" spacing={1}>
                    <Typography variant="h2">
                        {number}
                    </Typography>
                    <Typography variant="h6" sx={homeSavingsCellNumberText}>
                        {number_type}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={3} sx={homeSavingsCellDivider}></Grid>
            <Grid item xs={3} sx={homeSavingsCellDividerThin}></Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={12}>
                <Stack sx={homeSavingsCellWrapper}>
                    <Typography variant="h6" sx={homeSavingsCellDescriptionText}>
                        {description1}
                    </Typography>
                    <Typography variant="h6" sx={homeSavingsCellDescriptionText}>
                        {description2}
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}