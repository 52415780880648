import { Region } from "arris_dist";
import { SubRegion } from "arris_dist/dist/types/region";

export enum ActionType {
  REGION_PENDING = "REGION_PENDING",
  REGION_FAIL = "REGION_FAIL",
  GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS",
  GET_SUB_REGIONS = "GET_SUB_REGIONS"

}

interface getRegionsSuccess {
  type: ActionType.GET_REGIONS_SUCCESS,
  payload: Region[]
}

interface IGetSubRegions {
  type: ActionType.GET_SUB_REGIONS,
  payload: SubRegion[]
}

interface regionPending {
  type: ActionType.REGION_PENDING
}

interface regionFail {
  type: ActionType.REGION_FAIL,
  payload: string
}

export type Action =
  getRegionsSuccess |
  IGetSubRegions |
  regionPending |
  regionFail;
