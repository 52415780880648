import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CommentBox from "components/universal/comment_box";
import { smallAreaStyle } from "styles/general";
import { white_color } from "styles/theme";
import { StreamlineDetails } from "values/text/fabricator";
import { fabricatorStreamlineDetailsContainerStyle } from "styles/fabricator/streamline_detail";
import { fabricatorStreamlineDetailsSpacerStyle } from "styles/fabricator/streamline_detail";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function Details({is_tablet, is_phone}: Props){
    return (
        <Box sx={[smallAreaStyle, fabricatorStreamlineDetailsContainerStyle]}>
            <Grid container>
                <CommentBox 
                    is_tablet={is_tablet}
                    is_phone={is_phone}
                    title={StreamlineDetails.TITLE_1}
                    paragraph={StreamlineDetails.PARAGRAPH_1}
                    right={is_tablet || is_phone}
                    text_color={white_color}/>
                {
                    is_tablet || is_phone ? 
                    <Grid item xs={12} sx={fabricatorStreamlineDetailsSpacerStyle}></Grid> : null
                }
                <CommentBox 
                    is_tablet={is_tablet}
                    is_phone={is_phone}
                    title={StreamlineDetails.TITLE_2}
                    paragraph={StreamlineDetails.PARAGRAPH_2}
                    text_color={white_color}
                    right={true}/>
            </Grid>
        </Box>
    )
}