import MdAboutHero from "./md";
import SmAboutHero from "./sm";
import XsAboutHero from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}
export default function AboutHero({is_tablet, is_phone}: Props){
    if(is_phone){
        return <XsAboutHero />;
    }
    else if(is_tablet){
        return <SmAboutHero />;
    }
    return <MdAboutHero />;
}