// Row 1 // 
export enum Hero {
    TITLE="\"I CAN GIVE A FINAL PRICE TO THE CUSTOMER AT THE SAME MOMENT I'M MAKING A MEASUREMENT.\"",
        // Cell 1 // 
        CELL_1_SUBTITLE="Seamless Migration",
        CELL_1_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It's easy.",
        // Cell 2 //
        CELL_2_SUBTITLE="24/5 Support",
        CELL_2_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It's easy.",
        // Cell 3 //
        CELL_3_SUBTITLE="Effortless Drawing",
        CELL_3_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It's easy.",
}

export enum Quality {
    TITLE="Unlock new revenue streams with ARRIS!",
    PARAGRAPH=" Streamline your operations with intuitive drawing, instant quoting, and fabricator marketplace all rolled into one neat package. Experience an influx of new orders without the need for additional marketing or hiring!"
}

export enum QualityDetails {
    TITLE_1="CUT WAIT TIMES IN HALF",
    PARAGRAPH_1="​With Arris, connect with new fabricators in your vicinity and beyond, fostering valuable relationships that drive business growth. Being an early adopter of Arris gives you a competitive edge, allowing you to secure more jobs than your competitors. It's not just about getting found, it's about building lasting partnerships and staying ahead in the industry.",
    TITLE_2="PROVIDE ACCURATE QUOTES INSTANTLY",
    PARAGRAPH_2="Empower your business with the ability to generate on-the-spot quotes using the most up-to-date pricing. Say goodbye to cumbersome pricing spreadsheets that slow you down. Instead, take full control of your pricing strategy. Our system offers flexible markups, allowing you to determine the optimal rates for your business."
}

export enum Streamline {
    TITLE="STREAMLINE YOUR SALES PROCESS",
    PARAGRAPH="Arris optimizes your business by simplifying drawing processes, providing real-time quotes, and connecting you with top merchants. It's your key to efficiency, accuracy, and growth."
}

export enum StreamlineDetails {
    TITLE_1="EFFICIENT DRAWING TOOL",
    PARAGRAPH_1="Arris features an efficient drawing tool that revolutionizes the way fabricators work. This tool simplifies the drawing process, making it quick and effortless. It allows you to create accurate sketches in real-time, reducing errors and enhancing productivity. With Arris, you can focus more on craftsmanship and less on time-consuming tasks, transforming your operations and boosting your efficiency.",
    TITLE_2="INTEGRATED PRICING & QUOTING",
    PARAGRAPH_2="Arris provides an integrated pricing and quoting system that streamlines your sales process. This feature allows you to generate accurate quotes in real-time, right at the job site. It also offers the flexibility to adjust pricing strategies on the fly, ensuring you stay competitive or charge a premium for high-quality service. With Arris, you can enhance your customer interactions, close deals faster, and drive your business growth."
}