/// TYPES ///
import { Vector2 } from "arris_dist";
import { OrderSlab } from "arris_dist";
import { SelfOrderSlab } from "arris_dist";
/// FUNCTIONS ///
import { drawOrderPrintCutShapes } from "arris_dist";
import { getOptimalSlabZoom } from "arris_dist";
import { getSlabStartPoint } from "arris_dist";
/// COMPONENTS ///
import { View } from "@react-pdf/renderer";
import { Canvas } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
/// VALUES ///
import { light_grey_color } from "arris_dist";
import { primary_color } from "arris_dist";

const base_offset: Vector2 = {X: 0, Y: 0};

interface Props {
    index: number,
    slab: OrderSlab | SelfOrderSlab
}

export default function PrintOrderSlab({index, slab}: Props) {
    const paintCanvas = (painter: any, availableWidth: number, availableHeight: number) => {
        const width: number = slab.width ? slab.width * 4: 0;
            const length: number = slab.length ? slab.length * 4: 0;
            const zoom: number = getOptimalSlabZoom(
                width,
                length,
                {X: availableWidth, Y: availableHeight}
            ); 
            const slab_start: Vector2 = getSlabStartPoint(length / 4, index);
    
            painter.moveTo(base_offset.X, base_offset.Y);
            painter.lineTo(base_offset.X + width * zoom, base_offset.Y);
            painter.lineTo(base_offset.X + width * zoom, base_offset.Y + length * zoom);
            painter.lineTo(base_offset.X, base_offset.Y + length * zoom);
            painter.lineTo(base_offset.X, base_offset.Y);
            painter.fill(light_grey_color);

            drawOrderPrintCutShapes(painter, slab, zoom, base_offset, slab_start);
            painter.fill(primary_color);
        return null;
    }
            
    return (
        <View style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "25px"}} wrap={false}>
            <Text style={{fontSize: 10, fontFamily: "Montserrat"}}>{slab.material_name} {slab.color_name}</Text>
            <Canvas
                style={{height: "225px"}}
                paint={paintCanvas}
            />
        </View>
    );
}