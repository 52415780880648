export const homeServicesContainerStyle = {
    paddingTop: 8,
    paddingBottom: 8
}

export const scroller = {
    maxWidth: '1024px',
    mask: 'linear-gradient(90deg, transparent, white 20%, white 80%, transparent)',
    WebkitMask: 'linear-gradient(90deg, transparent, white 20%, white 80%, transparent)',
    paddingRight: '50px',
    paddingLeft: '50px',
    border: '10px solid red',
    overflow: 'hidden',
  };
  
  export const scrollerinner = {
    columnGap: '14px',
    display: 'flex',
    paddingBlock: '2px',
    width: 'max-content',
    flexWrap: 'nowrap',
    listStyle: 'none',
    animation: 'scroll 100s linear infinite backwards',
    paddingInlineStart: '0px',
  };

  export const scrollerWrapper = {
    paddingBlock: "20px"
  }

  export const scrollerImgMd = {
    width: "300px"
  }

  export const scrollerImgXs = {
    width: "200px"
  }
  export const clientsTitleStyleXs = {
    textTransform: "uppercase",
    paddingTop: 1,
    paddingBottom: 2,
    fontSize: "25px"
}
export const clientsTitleStyleMd = {
  textTransform: "uppercase",
  paddingTop: 1,
  paddingBottom: 2,
  fontSize: "40px"
}