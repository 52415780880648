import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Hero } from "values/text/fabricator";
import { heroTransparentAreaStyle } from "styles/general";
import { fabricatorHeroContainerStyle } from "styles/fabricator/hero";
import { fabricatorHeroTitleTextStyle } from "styles/fabricator/hero";

export default function SmFabricatorHero(){
    return (
        <Box sx={[heroTransparentAreaStyle, fabricatorHeroContainerStyle]}>
            <Stack>
                <Typography variant="h2" textAlign="center" sx={fabricatorHeroTitleTextStyle}>
                    {Hero.TITLE}
                </Typography>
            </Stack>
        </Box>
    )
}