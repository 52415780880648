import Pic4 from "static/pic4.jpg";
// pic4.jpg, if you replace the image with a different name
// (contained in the top level folder "static")
// change it above here and it will reflect in the app

export const homeControlContainerStyle = {
    marginTop: {
        "md":"15vh",
        "xs":"-5vh"
    }
}

export const homeControlImageStyle = {
    backgroundImage: `url(${Pic4})`,
    height: "75vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    marginBottom: {
        "xs": -5,
        "md": "auto"
    }
}