import Typography from "@mui/material/Typography";
import { statementBoxTitleTextStyle } from "styles/universal/statement_box";

interface Props {
    text_color: string,
    border_color: string,
    title: string,
    paddingBottom?: number,
    paddingLeft?: number
}

export default function StatementBoxTitle({text_color, border_color, title, paddingBottom=5, paddingLeft=10}: Props){
    return (
        <Typography 
            variant="h6" 
            sx={statementBoxTitleTextStyle(text_color, border_color, paddingLeft, paddingBottom)}>
            {title}
        </Typography>
    )
}
