import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CommentBox from "components/universal/comment_box";
import { smallAreaStyle } from "styles/general";
import { white_color } from "styles/theme";
import { StreamlineDetails } from "values/text/merchant";
import { merchantStreamlineDetailsContainerStyle } from "styles/merchant/streamline_detail";
import { merchantStreamlineDetailsSpacerStyle } from "styles/merchant/streamline_detail";

interface Props {
    is_phone: boolean,
    is_tablet: boolean
}

export default function Details({is_phone, is_tablet}: Props){
    return (
        <Box sx={[smallAreaStyle, merchantStreamlineDetailsContainerStyle]}>
            <Grid container>
                <CommentBox 
                    is_tablet={is_tablet}
                    is_phone={is_phone}
                    title={StreamlineDetails.TITLE_1}
                    paragraph={StreamlineDetails.PARAGRAPH_1}
                    text_color={white_color}
                    right={is_phone || is_tablet ? true : false} />
                <Grid item xs={12} sx={merchantStreamlineDetailsSpacerStyle}></Grid>
                <CommentBox 
                    is_tablet={is_tablet}
                    is_phone={is_phone}
                    title={StreamlineDetails.TITLE_2}
                    paragraph={StreamlineDetails.PARAGRAPH_2}
                    text_color={white_color}
                    right={is_phone || is_tablet ? true : false} />
            </Grid>
        </Box>
    )
}