import MdValuesList from "./md";
import SmValuesList from "./sm";
import XsValuesList from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function ValuesList({is_tablet, is_phone}: Props){
    if(is_phone){
        return <XsValuesList />;
    }
    else if(is_tablet){
        return <SmValuesList />;
    }
    return <MdValuesList />;
}