import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { heroWhyAnimationGridStyle } from "styles/home_page/why";
import { heroWhyAnimationInnerWrapperStyle } from "styles/home_page/why";
import { heroWhyAnimationOuterWrapperStyle } from "styles/home_page/why";

interface Props { 
    src: string
}

export default function MdAnimation({src}: Props){
    return (
        <Grid item xs={2.75} sx={heroWhyAnimationGridStyle}>
            <Box sx={heroWhyAnimationOuterWrapperStyle} display="flex" justifyContent="center">
                <Box 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center" 
                    sx={heroWhyAnimationInnerWrapperStyle}>
                    <video autoPlay={true} muted height="250" width="250">
                        <source src={src} type="video/mp4"></source>
                    </video>
                </Box>
            </Box>
        </Grid>
    )
}