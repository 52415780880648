import Pic3 from "static/pic3.webp";
// pic3.webp is the image for md size, if you replace the image with a different name
// (contained in the top level folder "static")
// change it above here and it will reflect in the app

export const homeMaterialContainer = {
    height: {
        "xs": "auto",
        "sm": "auto",
        "md": "75vh"
    },
    paddingTop: {
        "xs": 5,
        "md": "auto"
    }
}

export const homeMaterialImageContainer = {
    height: "75vh",
    paddingBottom: {
        "xs": 8,
        "md": "10vh"
    },
    paddingTop: {
        "xs": 2,
        "md": "10vh"
    }
}

// for xs and sm the image is container in the component because it uses Parallax
export const homeMaterialImage = {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto"
}

export const homeMaterialXsImage = {
    ...homeMaterialImage,
    height: "100%"
}

export const homeMaterialMdImage = {
    ...homeMaterialImage,
    height: "75vh",
    backgroundImage: `url(${Pic3})`
}