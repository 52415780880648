import { text_primary } from "styles/theme";
import { white_color } from "styles/theme";

export const readMoreTitleTextStyle = {
    paddingBottom: 1
}

export const readMoreParagraphTextStyle = {
    paddingBottom: 3,
    paddingTop: 3.5,
    paddingRight: {
        "xs": "10%",
        "md": "30%"
    },
    lineHeight: 2
}

export const readMoreButtonIconStyle = {
    height: 26,
    width: 26,
    marginLeft: 0.75,
    paddingLeft: 1,
    borderLeft: `1px solid ${text_primary}`
}

export const readMoreButtonStyle = {
    paddingLeft: 1.25,
    paddingRight: 1.25,
    border: `1px solid ${text_primary}`,
    marginTop: 2,
    color: text_primary,
    ":hover": {
        color: white_color,
        backgroundColor: text_primary
    }
}