/// TYPES ///
import { Order } from "arris_dist";
/// COMPONENTS ///
import { Fragment } from "react";
import PrintSummary from "../shared/summary";

interface Props {
    order: Order
}

export default function PrintCustomerOrderSummary({order}: Props) {   
    return (
        <Fragment>
            <PrintSummary 
                total={order.end_customer_total !== null && order.end_customer_total !== undefined ? order.end_customer_total : 0}
                tax={order.end_customer_tax !== null && order.end_customer_tax !== undefined ? order.end_customer_tax : 0}
                tax_name={order && order.tax_name && order.tax_name !== "" ? order.tax_name : "Tax"}
                second_tax_name={order && order.second_tax_name && order.second_tax_name !== "" ? order.second_tax_name : ""}
                second_tax={order.end_customer_second_tax !== null && order.end_customer_second_tax !== undefined ?
                    order.end_customer_second_tax : 0}
                discount={order.discount !== null && order.discount !== undefined ? order.discount : 0} />
        </Fragment>
    );
}