import MdQuality from "./md";
import XsQuality from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function Quality({is_tablet, is_phone}: Props){
    if(is_phone){ 
        return <XsQuality />;
    }
    else if(is_tablet){
        return <XsQuality />;
    }
    return <MdQuality />;
}