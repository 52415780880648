// Image is About2.png in "static" folder. 
// If it's changed it has to be changed in 
// src/components/about/second_banner/index.tsx in the imports at top of file

export const aboutSecondBannerContainerStyle = {
    height: "100vh",
    overflow: "hidden"
}

export const aboutSecondBannerParallaxImageStyle = {
    height: "125%",
    width: "125%"
}