import Typography from "@mui/material/Typography";
import { statementBoxParagraphTextStyle } from "styles/universal/statement_box";

interface Props {
    text_color: string,
    border_color: string,
    text: string | null | undefined,
    paddingTop?: number,
    paddingLeft?: number
}

export default function StatementBoxParagraph({text_color, border_color, text, paddingTop=4, paddingLeft=10}: Props){
    if(text && text !== ""){
        return (
            <Typography
                variant="body1"
                sx={statementBoxParagraphTextStyle(text_color, border_color, paddingTop, paddingLeft)}>
                {text}
            </Typography>
        )
    }
    return null;
}
