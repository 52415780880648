import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import MerchantHero from "./hero";
import Customers from "./customers";
import Quality from "./quality";
import Streamline from "./streamline";
import Details from "./streamline_details";
import Footer from "components/universal/footer";

export default function Merchant() {
    const theme = useTheme();
    const is_phone = useMediaQuery(theme.breakpoints.down("sm"));
    const is_tablet = useMediaQuery(theme.breakpoints.down("md"));

    return(
        <Box>
            <MerchantHero is_phone={is_phone} is_tablet={is_tablet} />
            <Customers is_phone={is_phone} is_tablet={is_tablet} />
            <Quality is_phone={is_phone} is_tablet={is_tablet} />
            <Streamline is_phone={is_phone} is_tablet={is_tablet} />
            <Details is_phone={is_phone} is_tablet={is_tablet} />
            <Footer is_tablet={is_tablet} is_phone={is_phone} />
        </Box>
    )
}
