import { primary_color } from "styles/theme";
import { white_color } from "styles/theme";

export const aboutValueListContainerStyle = {
    backgroundColor: primary_color,
    padding: {
        "md": "default",
        "sm": 0,
        "xs": 0
    },
    paddingBottom: {
        "md": "default",
        "sm": "5vh",
        "xs": "5vh"
    }
}

export const aboutValueListHeaderStyle = {
    fontSize: 18
}

export const aboutValueListParagraphStyle = (right: boolean) => ({
    color: white_color,
    marginTop: 1,
    paddingRight: right ? 0 : 6
});

export const aboutValueListSpacerStyle = {
    height: {
        "md": "0px",
        "sm": "50px",
        "xs": "25px"
    }
}