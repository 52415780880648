import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { heroWhyDescriptionParagraphStyle } from "styles/home_page/why";
import { heroWhyDescriptionSubtitleStyle } from "styles/home_page/why";
import { heroWhyDescriptionWrapperStyle } from "styles/home_page/why";
import { heroWhyAnimationGridStyle } from "styles/home_page/why";

interface Props { 
    subtitle: string,
    paragraph: string
}

export default function SmDescription({subtitle, paragraph}: Props) {
    return(
        <Grid item xs={8} sx={[heroWhyAnimationGridStyle, heroWhyDescriptionWrapperStyle]}>
            <Stack>
                <Typography variant="body1" sx={heroWhyDescriptionSubtitleStyle}>
                    {subtitle}
                </Typography>
                <Typography variant="body1" sx={heroWhyDescriptionParagraphStyle} textAlign="justify">
                    {paragraph}
                </Typography>
            </Stack>
        </Grid>
    )
}
