import { SelfOrderArea } from "arris_dist";

export enum ActionType {
    GET_SELF_ORDER_AREAS_SUCCESS="GET_SELF_ORDER_AREAS_SUCCESS"
}

interface IGetSelfOrderAreasSuccess {
    type: ActionType.GET_SELF_ORDER_AREAS_SUCCESS,
    self_order_areas: SelfOrderArea[]
}

export type Action = IGetSelfOrderAreasSuccess;