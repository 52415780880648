import MdEdge from "./md";
import SmEdge from "./sm";
import XsEdge from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function Edge({is_phone, is_tablet}: Props){
    if(is_phone){
        return <XsEdge />;
    }
    else if(is_tablet){
        return <SmEdge />;
    }
    return <MdEdge />;
}