import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { plans_url } from "components/router/endpoints";
import { fabricator_url } from "components/router/endpoints";
import { merchant_url } from "components/router/endpoints";
import { about_url } from "components/router/endpoints";
import { app_url } from "settings";
import { mobileNavWrapper } from "styles/navbar";
import { mobileNavListItemTextStyle } from "styles/navbar";

interface Props {
    open: boolean,
    setOpen: (open: boolean) => void
}

export default function MobileDrawer({open, setOpen}: Props) {
    const navigate = useNavigate();

    const navigateLogin = () => {
        window.location.href = app_url + "login";
    }

    const nav = (url: string) => {
        setOpen(false);
        window.scrollTo(0,0);
        navigate(url, {replace: false});
    }

    return (
        <Drawer anchor="right" open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
            <Box sx={mobileNavWrapper}>
                <List>
                    <ListItem key="plans" disablePadding>
                        <ListItemButton onClick={() => nav(plans_url)}>
                            <ListItemIcon>
                                
                            </ListItemIcon>
                            <ListItemText primary="Plans" sx={mobileNavListItemTextStyle} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="merchants" disablePadding>
                        <ListItemButton onClick={() => nav(merchant_url)}>
                            <ListItemIcon>
                                
                            </ListItemIcon>
                            <ListItemText primary="For Merchants" sx={mobileNavListItemTextStyle} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="fabricators" disablePadding>
                        <ListItemButton onClick={() => nav(fabricator_url)}>
                            <ListItemIcon>
                                
                            </ListItemIcon>
                            <ListItemText primary="For Fabricators" sx={mobileNavListItemTextStyle} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="login" disablePadding>
                        <ListItemButton onClick={navigateLogin}>
                            <ListItemIcon>
                                
                            </ListItemIcon>
                            <ListItemText primary="Login" sx={mobileNavListItemTextStyle} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
}
