import { SelfOrderSlab } from "arris_dist";

export enum ActionType {
    GET_SELF_ORDER_SLABS_SUCCESS="GET_SELF_ORDER_SLABS_SUCCESS"
}

interface IGetSelfOrderSlabsSuccess {
    type: ActionType.GET_SELF_ORDER_SLABS_SUCCESS,
    self_order_slabs: SelfOrderSlab[]
}

export type Action = IGetSelfOrderSlabsSuccess;