import { primary_color } from "styles/theme";

export const aboutValuesContainerBackground = {
    backgroundColor: primary_color,
    paddingTop: {
        "md": "10vh",
        "sm": 0,
        "xs": "default"
    },
    paddingBottom: {
        "md": "default",
        "sm": 0,
        "xs": "default"
    }
}