import Box from "@mui/material/Box";
import StatementBox from "components/universal/statement_box";
import { Row7 } from "values/text/home";
import { primary_color } from "styles/theme";
import { white_color } from "styles/theme";
import { homeEdgeContainerStyle } from "styles/home_page/edge";

export default function SmEdge(){
    return (
        <Box sx={homeEdgeContainerStyle}>
            <StatementBox 
                title={Row7.TITLE}
                subtitle1={Row7.SUBTITLE_1}
                subtitle2={Row7.SUBTITLE_2}
                subtitle3={Row7.SUBTITLE_3}
                paragraph={Row7.PARAGRAPH_1}
                text_color={white_color}
                background_color={primary_color}
                border_color={primary_color} 
                is_tablet={true} 
                paddingBottom={6}
                paddingTop={6} />
        </Box>
    )
}