import MdFabricatorHero from "./md";
import SmFabricatorHero from "./sm";
import XsFabricatorHero from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function FabricatorHero({is_tablet, is_phone}: Props){
    if(is_phone){
        return <XsFabricatorHero />;
    }
    else if(is_tablet){
        return <SmFabricatorHero />;
    }
    return <MdFabricatorHero />;
}