import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import MdPlans from "./md";
import SmPlans from "./sm";
import XsPlans from "./xs";

export default function Plans() {
    const theme = useTheme();
    const is_phone = useMediaQuery(theme.breakpoints.down("sm"));
    const is_tablet = useMediaQuery(theme.breakpoints.down("md"));

    if(is_phone){
        return <XsPlans />;
    }
    else if(is_tablet){
        return <SmPlans />;
    }
    return <MdPlans />;
}
