import { ColorOption } from "arris_dist";

export enum ActionType {
    CREATE_COLOR_OPTION_SUCCESS="CREATE_COLOR_OPTION_SUCCESS",
    UPDATE_COLOR_OPTION_SUCCESS="UPDATE_COLOR_OPTION_SUCCESS",
    DELETE_COLOR_OPTION_SUCCESS="DELETE_COLOR_OPTION_SUCCESS"
}

interface deleteColorOptionSuccess {
    type: ActionType.DELETE_COLOR_OPTION_SUCCESS,
    payload: string
}

interface updateColorOptionSuccess {
    type: ActionType.UPDATE_COLOR_OPTION_SUCCESS,
    payload: ColorOption
}

interface createColorOptionSuccess {
    type: ActionType.CREATE_COLOR_OPTION_SUCCESS,
    payload: ColorOption
}

export type Action = 
    createColorOptionSuccess |
    updateColorOptionSuccess |
    deleteColorOptionSuccess;