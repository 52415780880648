import { Fragment } from "react";
import { Quote } from "arris_dist";
import PrintSummary from "../shared/summary";

interface Props {
    quote: Quote
}

export default function PrintQuoteSummary({quote}: Props) {            
    return (
        <Fragment>
            <PrintSummary 
                total={quote.total !== null && quote.total !== undefined ? quote.total : 0}
                tax_name={
                    quote &&
                    quote.tax_name &&
                    quote.tax_name !== "" ? 
                    quote.tax_name : ""
                }
                tax={quote.tax !== null && quote.tax !== undefined ? quote.tax : 0}
                second_tax_name={
                    quote &&
                    quote.second_tax_name &&
                    quote.second_tax_name !== "" ? 
                    quote.second_tax_name : ""
                }
                second_tax={quote.second_tax ? quote.second_tax : 0}
                discount={quote.discount !== null && quote.discount !== undefined ? quote.discount : 0} />
        </Fragment>
    );
}