export enum ActionType {
    NAVBAR_TRANSPARENCY="NAVBAR_TRANSPARENCY",
    SET_TRY_NOW="SET_TRY_NOW"
}

interface navbarTransparency {
    type: ActionType.NAVBAR_TRANSPARENCY,
    transparent: boolean
}

interface setTryNow {
    type: ActionType.SET_TRY_NOW,
    open: boolean
}

export type Action = 
    navbarTransparency |
    setTryNow;