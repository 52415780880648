import Typography from "@mui/material/Typography";
import { statementBoxSubtitleTextStyle } from "styles/universal/statement_box";

interface Props {
    text_color: string,
    border_color: string,
    subtitle: string | null | undefined,
    paddingBottom: number,
    paddingLeft?: number
}

export default function StatementBoxSubtitle({text_color, border_color, subtitle, paddingBottom, paddingLeft=10}: Props){
    if(subtitle && subtitle !== ""){
        return (
            <Typography 
                variant="h5" 
                sx={statementBoxSubtitleTextStyle(text_color, border_color, paddingLeft, paddingBottom)}>
                {subtitle}
            </Typography>
        )
    }
    return null;
}
