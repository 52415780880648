export enum PLANS_HEADER {
    TITLE="Choose your pricing plan",
    SUBTITLE="Find one that works for you"
}

export enum MONTHLY_PLAN {
    TITLE="Flexible Plan",
    CURRENCY="$",
    PRICE="199",
    PRICE_PERIOD="Every month",
    BILLING_PERIOD="Billed Monthly",
    TRIAL_PERIOD="30 day free trial",
    TRIAL_PROMO="Start free trial"
}

export const MONTHLY_PLAN_BENEFITS: string[] = [
    "Access to all drawing and cost projection tools Zero fees",
    "Zero fees for setup or implementation",
    "Comprehensive support via phone, email, and Zoom without any",
    "A vast repository of knowledge and resources",
    "Complete features for task scheduling and project management",
    "Freedom to cancel at any time"
]

export enum YEARLY_PLAN {
    VALUE_TAG="Best Value",
    TITLE="Momentum Plan",
    CURRENCY="$",
    PRICE="165",
    PRICE_PERIOD="Every month",
    BILLING_PERIOD="Billed Yearly",
    TRIAL_PERIOD="30 day free trial",
    TRIAL_PROMO="Start free trial"
}

export const YEARLY_PLAN_BENEFITS: string[] = [
    "Get 2 months FREE, when you pay annually ($408 in SAVINGS)",
    "Access to all drawing and cost projection tools",
    "Zero fees for setup or implementation",
    "Comprehensive support via phone, email, and Zoom without any",
    "A vast repository of knowledge and resources",
    "Complete features for task scheduling and project management",                           
    "Freedom to cancel at any time"
]