import { Fragment } from "react";
import { getDate } from "arris_dist";
import { getExpiryDate } from "arris_dist";
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { Image } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { primary_color } from "styles/theme";
import { light_grey_color } from "styles/theme";
import { white_color } from "styles/theme";

interface Props {
    title: string,
    number: string,
    logo_url: string,
    seller_name: string,
    seller_address: string,
    seller_city: string,
    seller_province: string,
    seller_postal_code: string,
    buyer_name: string,
    buyer_address: string,
    buyer_city: string,
    buyer_province: string,
    buyer_postal_code: string,
    date: string
}

export default function PrintHeader({
    title,
    number,
    logo_url,
    seller_name,
    seller_address,
    seller_city,
    seller_province,
    seller_postal_code,
    buyer_name,
    buyer_address,
    buyer_city,
    buyer_province,
    buyer_postal_code,
    date
}: Props) {
    const styles = StyleSheet.create({
        big_title: {
            fontFamily: "Montserrat",
            color: white_color,
            fontSize: 20
        },
        title: {
            fontWeight: "bold",
            fontSize: 13,
            fontFamily: "Montserrat"
        },
        body2: {
            fontFamily: "Montserrat",
            fontSize: 10
        },
        body2_white: {
            fontFamily: "Montserrat",
            fontSize: 10,
            color: white_color
        },
        logo: {
            height: "60px",
            width: "120px",
            objectFit: "contain",
            objectPosition: "left"
        },
        row: {
            marginTop: "5px",
            display: "flex",
            flexDirection: "row"
        }
    });

    
            
    return (
        <Fragment>
            <View style={{display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: "15px"}}>
                <View style={{width: "50%", display: "flex", justifyContent: "center", alignItems: "flex-start", height: "70px"}}>
                    {
                        logo_url !== null && logo_url !== undefined && logo_url !== "" ?
                        <Image src={logo_url} style={styles.logo} /> :
                        null
                    } 
                </View>
                <View style={{display: "flex", width: "50%", height: "70px", justifyContent: "center", alignItems: "center", backgroundColor: primary_color}}>
                    <Text style={styles.big_title}>
                        {title}
                    </Text>
                    <Text style={styles.body2_white}>
                        Invoice #: {number}
                    </Text>
                </View>
            </View>
            <View style={{display: "flex", flexDirection: "row", paddingLeft: "15px"}}>
                <View style={{width: "50%", height: "70px", paddingTop: "5px"}}>
                    <Text style={styles.title}>
                        Payment Info
                    </Text>
                    <Text style={styles.body2}>
                        {seller_name}
                    </Text>
                    <Text style={styles.body2}>
                        {seller_address}
                    </Text>
                    <Text style={styles.body2}>
                        {seller_city}, {seller_province}
                    </Text>
                    <Text style={styles.body2}>
                        {seller_postal_code}
                    </Text>
                </View>
                <View style={{textAlign: "right", width: "50%", height: "70px", backgroundColor: light_grey_color, paddingTop: "5px", paddingRight: "15px"}}>
                    <Text style={styles.title}>
                        To
                    </Text>
                    <Text style={styles.body2}>
                        {buyer_name}
                    </Text>
                    <Text style={styles.body2}>
                        {buyer_address}
                    </Text>
                    <Text style={styles.body2}>
                        {`${buyer_city}, ${buyer_province}`}
                    </Text>
                    <Text style={styles.body2}>
                        {buyer_postal_code}
                    </Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={{paddingLeft: "15px"}}>
                    <Text style={styles.body2}>
                        Created on: {getDate(date)}
                    </Text>
                    <Text style={styles.body2}>
                        Expires on: 
                    </Text>
                </View>
            </View>
        </Fragment>
    );
}

//
//{getExpiryDate(date)}