import { User } from "arris_dist";

export enum ActionType {
    LOGIN_PENDING = "LOGIN_PENDING",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_FAIL = "LOGIN_FAIL",
    REGISTER_PENDING = "REGISTER_PENDING",
    REGISTER_SUCCESS = "REGISTER_SUCCESS",
    REGISTER_FAIL = "REGISTER_FAIL",
    GET_CONFIRMATION_SUCCESS = "GET_CONFIRMATION",
    POST_CONFIRMATION_SUCCESS = "POST_CONFIRMATION",
    CONFIRMATION_FAIL = "CONFIRMATION_FAIL",
    CONFIRMATION_PENDING = "CONFIRMATION_PENDING",
    PASSWORD_RESET_PENDING = "PASSWORD_RESET_PENDING",
    PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS",
    PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL",
    RESET_REGISTRATION_VALUES = "RESET_REGISTRATION_VALUES",
    RESET_PASSWORD_VALUES = "RESET_PASSWORD_VALUES"
}

interface loginPending {
    type: ActionType.LOGIN_PENDING
}

interface loginSuccess {
    type: ActionType.LOGIN_SUCCESS,
    payload: User
}

interface loginFail {
    type: ActionType.LOGIN_FAIL,
    payload: string
}

interface registerPending {
    type: ActionType.REGISTER_PENDING
}

interface registerSuccess {
    type: ActionType.REGISTER_SUCCESS
}

interface registerFail {
    type: ActionType.REGISTER_FAIL,
    payload: string,
    offer_reset: boolean
}

interface getConfirmationSuccess {
    type: ActionType.GET_CONFIRMATION_SUCCESS
}

interface postConfirmationSuccess {
    type: ActionType.POST_CONFIRMATION_SUCCESS
}

interface confirmationFail {
    type: ActionType.CONFIRMATION_FAIL,
    payload: string,
    expired: boolean
}

interface confirmationPending {
    type: ActionType.CONFIRMATION_PENDING
}

interface passwordResetPending {
    type: ActionType.PASSWORD_RESET_PENDING
}

interface passwordResetSuccess {
    type: ActionType.PASSWORD_RESET_SUCCESS
}

interface passwordResetFail {
    type: ActionType.PASSWORD_RESET_FAIL,
    payload: string
}

interface resetRegistrationValues {
    type: ActionType.RESET_REGISTRATION_VALUES
}

interface resetPasswordValues {
    type: ActionType.RESET_PASSWORD_VALUES
}

export type Action = 
    loginPending |
    loginFail |
    loginSuccess |
    registerPending |
    registerSuccess |
    registerFail |
    getConfirmationSuccess |
    postConfirmationSuccess |
    confirmationFail |
    confirmationPending |
    passwordResetPending |
    passwordResetSuccess |
    passwordResetFail |
    resetRegistrationValues |
    resetPasswordValues;