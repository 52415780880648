import { Action } from 'api/action_types/ui';
import { ActionType } from 'api/action_types/ui';

interface State {
    transparent: boolean,
    open: boolean
}

const initialState = {
    transparent: false,
    open: false
}

export const uiReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.NAVBAR_TRANSPARENCY:
            return {
                ...state,
                transparent: action.transparent
            }
        case ActionType.SET_TRY_NOW:
            return {
                ...state,
                open: action.open
            }
        default:
            return state;
    }
}

