import Pic2Xs from "static/pic2_xs.jpg";
import Pic2 from "static/pic2.webp";
// pic2_xs.jpg is the image for xs and sm sizes, if you replace the image with a different name
// pic2.webp is the image for md size
// (contained in the top level folder "static")
// change it above here and it will reflect in the app

export const homeAccessContainerStyle = {
    minHeight: {
        "xs": "auto",
        "sm": "auto",
        "md": "75vh"
    },
    paddingTop: {
        "xs": 0,
        "sm": 0,
        "md": 8
    }
}

export const homeAccessImageStyle = {
    backgroundImage: {
        "xs": `url(${Pic2Xs})`,
        "sm": `url(${Pic2Xs})`,
        "md": `url(${Pic2})`
    },
    height: {
        "xs": "45vh",
        "sm": "55vh",
        "md": "75vh"
    },
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "150%",
    backgroundSize: {
        "xs": "cover",
        "sm": "cover",
        "md": "contain"
    }
}

export const homeAccessReadMoreContainerStyle = {
    minHeight: {
        "md": "75vh",
        "sm": "55vh",
        "xs": "45vh"
    },
    paddingTop: {
        "md": 8,
        "sm": "auto",
        "xs": "auto"
    }
}

export const homeAccessReadMoreGridWrapperStyle = {
    paddingTop: {
        "xs": 10,
        "sm": 10,
        "md": "auto"
    }
}

export const homeAccessImageContainerStyle = {
    minHeight: {
        "xs": "45vh",
        "sm": "75vh",
        "md": "auto"
    }
}

export const homeAccessImageInnerContainerStyle = {
    paddingTop: {
        "xs": 0,
        "sm": 2,
        "md": "auto"
    }
}