import Box from "@mui/material/Box";
import { largeAreaStyle } from "styles/general";
import { homeHeroContainer } from "styles/home_page/hero";
import { homeHeroVideoWrapper } from "styles/home_page/hero";

export default function SmHomeHero(){
    return (
        <Box sx={[largeAreaStyle, homeHeroContainer]}>
            <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                sx={homeHeroVideoWrapper}>
                <video height={400} width={715}
                    autoPlay={true} loop={true} muted>
                    <source src="/assets/video/jumbo_video.mp4" type="video/mp4"></source>
                </video>
            </Box>
        </Box>
    )
}