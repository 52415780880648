export const homeHeroContainer = {
    display: "flex",
    justifyContent: "center",
    paddingTop: {
        "md":"8vh",
        "sm":"8vh",
        "xs":"4vh"
    },
    paddingBottom: {
        "md":"8vh",
        "sm":"8vh",
        "xs":"4vh"
    },
    minHeight: {
        "md":"8vh",
        "sm":"8vh",
        "xs":"10vh"
    }
}

// video wrapper is slightly smaller than video to hide the edges
export const homeHeroVideoWrapper = {
    overflow: "hidden",
    height: {
        "xs": 200,
        "sm": 405,
        "md": 536
    },
    width: {
        "xs": 360,
        "sm": 720,
        "md": 956
    },
    marginLeft: 0
}