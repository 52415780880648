import { Seam } from "arris_dist";

export enum ActionType {
    CREATE_SEAM="CREATE_SEAM",
    DELETE_SEAM="DELETE_SEAM"
}

interface createSeam {
    type: ActionType.CREATE_SEAM,
    payload: Seam,
    counter_uuid: string
}

interface deleteSeam {
    type: ActionType.DELETE_SEAM,
    payload: string,
    point_uuid: string,
    counter_uuid: string
}


export type Action = createSeam | deleteSeam;