import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { Action } from "api/action_types/authentication";
import { ActionType } from "api/action_types/authentication";
import { Registration } from "arris_dist";
import { User } from "arris_dist";
import { url_prefix } from "settings";

const register_url: string = "registration";
const login_url: string = "auth/login";

export const login = (user: User) => {
    return async(dispatch: Dispatch<Action>) => {

        dispatch({
            type: ActionType.LOGIN_PENDING
        });

        const config = {
            headers: {
              "Content-Type":"application/json"
            }
        }

        const url = `${url_prefix}${login_url}`;

        try
        {
            const {data} = await axios.post(url, user, config);
            
            dispatch({
                type: ActionType.LOGIN_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if( err instanceof Error){
                dispatch({
                    type: ActionType.LOGIN_FAIL,
                    payload: err.message
                });
            }
            
        }
    }
}

export const register = (registration: Registration) => {
    return async(dispatch: Dispatch<Action>) => {

        dispatch({
            type: ActionType.REGISTER_PENDING
        });

        const config = {
            headers: {
              "Content-Type":"multipart/form-data"
            }
        }

        const url = `${url_prefix}${register_url}/`;

        try
        {
            await axios.post(url, registration, config);

            dispatch({
                type: ActionType.REGISTER_SUCCESS
            });
        }
        catch(err)
        {
            if( err instanceof AxiosError){
                if(err && err.response && err.response.status === 400){
                    dispatch({
                        type: ActionType.REGISTER_FAIL,
                        payload: err.response.data.error,
                        offer_reset: true
                    });
                }
                else{
                    dispatch({
                        type: ActionType.REGISTER_FAIL,
                        payload: "An unknown error has occurred please contact the administrator",
                        offer_reset: false
                    });
                }
            }
        }
    }
}

export const resetRegistrationValues = () => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.RESET_PASSWORD_VALUES
        });
    }
}