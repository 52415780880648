import { Fragment } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReadMoreBox from "components/universal/read_more_box";
import { Row4 } from "values/text/home";
import { homeAccessImageStyle } from "styles/home_page/access";
import { homeAccessReadMoreContainerStyle } from "styles/home_page/access";
import { homeAccessReadMoreGridWrapperStyle } from "styles/home_page/access";
import { homeAccessImageContainerStyle } from "styles/home_page/access";
import { homeAccessImageInnerContainerStyle } from "styles/home_page/access";

export default function XsAccess(){
    return (
        <Fragment>
            <Box sx={homeAccessImageContainerStyle}>
                <Grid container sx={homeAccessImageInnerContainerStyle}>
                    <Grid item xs={12} sx={homeAccessImageStyle}> </Grid>
                </Grid>
            </Box>
            <Box sx={homeAccessReadMoreContainerStyle}>
                <Grid container sx={homeAccessReadMoreGridWrapperStyle}>
                    <ReadMoreBox 
                        title1={Row4.TITLE_1}
                        title2={Row4.TITLE_2}
                        title3={Row4.TITLE_3}
                        paragraph={Row4.PARAGRAPH_1}
                        grid_left={2}
                        grid_right={9}
                        is_mobile={true} />
                </Grid>
            </Box>
        </Fragment>
    )
}