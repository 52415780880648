import Pic6 from "static/pic6.webp";
// pic6.webp is the image for md
// (contained in the top level folder "static")
// change it above here and it will reflect in the app

import { text_primary } from "styles/theme";

export const homeSavingsContainerStyle = {
    height: {
        "xs": "100%",
        "md": "65vh"
    },
    paddingTop: {
        "xs": "5vh",
        "md": "10vh"
    },
    paddingBottom: {
        "xs": 0,
        "md": "5vh"
    }
}

export const homeSavingsGridContainerStyle = {
    height: "100%",
    paddingTop: {
        "xs": "5vh",
        "md": 0
    }
}

export const homeSavingsImageStyle = {
    backgroundImage: `url(${Pic6})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: {
        "xs": "34vh",
        "md": "100%"
    }
}

export const homeSavingsExampleContainer = {
    paddingLeft: {
        "xs": 4,
        "md": 10
    },
    paddingRight: {
        "xs": 4,
        "md": 10
    },
    paddingBottom: 10,
    paddingTop: {
        "xs": 0,
        "md": "10vh"
    }
}

export const homeSavingsTitleWrapper = {
    marginBottom: 10
}

export const homeSavingsCellNumberText = {
    color: text_primary,
    marginLeft: 0.25
}

export const homeSavingsCellDivider = {
    borderBottom: `3px solid ${text_primary}`
}

export const homeSavingsCellDividerThin = {
    borderBottom: `1px solid ${text_primary}`
}

export const homeSavingsCellWrapper = {
    paddingTop: 2
}

export const homeSavingsCellDescriptionText = {
    color: text_primary
}

export const homeSavingsGridSpacer = {
    height: "50px"
}

export const homeSavingsLowerCellGridWrapper = {
    marginTop: 4
}