import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReadMoreBox from "components/universal/read_more_box";
import { Row4 } from "values/text/home";
import { homeAccessContainerStyle } from "styles/home_page/access";
import { homeAccessImageStyle } from "styles/home_page/access";
import { homeAccessReadMoreContainerStyle } from "styles/home_page/access";

export default function MdAccess(){
    return (
        <Box sx={homeAccessContainerStyle}>
            <Grid container sx={homeAccessReadMoreContainerStyle}>
                <ReadMoreBox 
                    title1={Row4.TITLE_1}
                    title2={Row4.TITLE_2}
                    title3={Row4.TITLE_3}
                    paragraph={Row4.PARAGRAPH_1}
                    grid_left={4}
                    grid_right={9}
                    is_mobile={false} />
                <Grid 
                    item 
                    xs={6} 
                    sx={homeAccessImageStyle}>
                </Grid>
            </Grid>
        </Box>
    )
}