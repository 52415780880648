import { Area } from "arris_dist";
import { Counter } from "arris_dist";
import { CutShape } from "arris_dist";
import { Slab } from "arris_dist";
import { Line } from "arris_dist";
import { Action } from "api/action_types/sketch";
import { ActionType } from "api/action_types/sketch";

const max_history: number = 5;

interface State {
    areas: Area[],
    counters: Counter[],
    selected_counter: Counter | null,
    cut_shapes: CutShape[],
    history_position: number,
    cut_shape_history: CutShape[][],
    counter_history: Counter[][],
    selected_cut_shape: CutShape | null,
    selected_line: Line | null,
    slabs: Slab[]
}

const initialState = {
    areas: [],
    counters: [],
    selected_counter: null,
    cut_shapes: [],
    history_position: 0,
    counter_history: [],
    cut_shape_history: [],
    selected_cut_shape: null,
    slabs: [],
    selected_line: null
}

export const sketchReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.GET_AREAS_SUCCESS:
            return {
                ...state,
                areas: action.areas
            }
        case ActionType.GET_COUNTERS_SUCCESS:
            return {
                ...state,
                counters: action.counters
            }
        case ActionType.CREATE_COUNTER_SUCCESS:
            return {
                ...state,
                counters: [...state.counters, action.payload]
            }
        case ActionType.UPDATE_COUNTER_SUCCESS:
            return {
                ...state,
                counters: [...state.counters.filter(item => item.uuid !== action.payload.uuid), action.payload]
            }
        case ActionType.DELETE_COUNTER_SUCCESS:
            return{
                ...state,
                counters: [...state.counters.filter(item => item.uuid !== action.payload)]
            }
        case ActionType.CREATE_CUT_SHAPES_SUCCESS:
            return {
                ...state,
                cut_shapes: [...state.cut_shapes.filter(item => item.counter_uuid !== action.counter_uuid), ...action.payload]
            }
        case ActionType.UPDATE_CUT_SHAPES_SUCCESS:
            return {
                ...state,
                cut_shapes: [...state.cut_shapes.filter(item => item.counter_uuid !== action.counter_uuid), ...action.payload]
            }
        case ActionType.UPDATE_CUT_SHAPE_SUCCESS:
            return {
                ...state,
                cut_shapes: [...state.cut_shapes.filter(item => item.uuid !== action.payload.uuid), action.payload]
            }
        case ActionType.DELETE_CUT_SHAPE_SUCCESS:
            return {
                ...state,
                cut_shapes: [...state.cut_shapes.filter(item => item.uuid !== action.payload)]
            }
        case ActionType.SET_SELECTED_CUT_SHAPE:
            return {
                ...state,
                selected_cut_shape: action.payload
            }
            case ActionType.CREATE_HISTORY_POINT:
                let counter_history: Counter[][] = [...state.counter_history];
                let cut_shape_history: CutShape[][] = [...state.cut_shape_history];
    
                if(state.history_position + 1 > max_history){
                    counter_history.shift();
                    cut_shape_history.shift();
                }
    
                return {
                    ...state,
                    history_position: state.history_position + 1 > max_history ? max_history : state.history_position + 1,
                    counter_history: [...counter_history.slice(0, state.history_position + 1), state.counters],
                    cut_shape_history: [...cut_shape_history.slice(0, state.history_position + 1), state.cut_shapes]
                }
            case ActionType.RESTORE:
                return {
                    ...state,
                    counters: state.counter_history[state.history_position],
                    cut_shapes: state.cut_shape_history[state.history_position]
                }
            case ActionType.REDO:
                if(state.counter_history.length - 1 === state.history_position){
                    return state;
                }
                return {
                    ...state,
                    counters: state.counter_history[state.history_position + 1],
                    cut_shapes: state.cut_shape_history[state.history_position + 1],
                    history_position: state.history_position + 1
                }
            case ActionType.UNDO:
                if(state.history_position === 0){
                    return state;
                }
                return {
                    ...state,
                    counters: state.counter_history[state.history_position - 1],
                    cut_shapes: state.cut_shape_history[state.history_position - 1],
                    history_position: state.history_position - 1
                }
        case ActionType.SET_SELECTED_LINE:
            return {
                ...state,
                selected_line: action.payload
            }
        case ActionType.CREATE_AREA_SUCCESS:
            return {
                ...state,
                areas: [...state.areas, action.payload]
            }
        case ActionType.UPDATE_AREA_SUCCESS:
            return {
                ...state,
                areas: [...state.areas.map(item => item.uuid === action.payload.uuid ? 
                    action.payload : 
                    item
                )]
            }
        case ActionType.DELETE_AREA_SUCCESS:
            return {
                ...state,
                areas: [...state.areas.filter(item => item.uuid !== action.payload)]
            }
        case ActionType.CREATE_SEAM:
            return {
                ...state,
                counters: [...state.counters.map(counter => counter.uuid === action.counter_uuid ?
                    {...counter, points: [...counter.points.map(point => action.payload.point_uuid === point.uuid ? 
                        {...point, seams: [...point.seams, action.payload]} :
                        point
                    )]} :
                    counter
                )]
            }
        case ActionType.DELETE_SEAM:
            return {
                ...state,
                counters: [...state.counters.map(counter => counter.uuid === action.counter_uuid ? 
                    {...counter,  points: [...counter.points.map(point => action.point_uuid ? 
                        {...point, seams: [...point.seams.filter(item => item.uuid !== action.payload)]} :
                        point
                    )]} :
                    counter
                )]
            }
        case ActionType.CREATE_SLAB_SUCCESS:
            return {
                ...state,
                slabs: [...state.slabs, action.payload]
            }
        case ActionType.DELETE_SLAB_SUCCESS:
            return {
                ...state,
                slabs: [...state.slabs.filter(item => item.uuid !== action.payload)]
            }
        default:
            return state;
    }
}