import { primary_color } from "styles/theme";
import { white_color } from "styles/theme";
import { transparent_color } from "styles/theme";

export const navAppBarStyle = (zIndex: number) => ({
    zIndex: zIndex,
    paddingLeft: {
        xs: 0,
        lg: 10
    },
    marginLeft: 0,
    left: 0,
    right: 0,
    top: 0,
    height: "10vh",
    backgroundColor: white_color
});

export const navToolbarStyle = {
    backgroundColor: transparent_color,
}

export const navLogoStyle = {
    cursor: "pointer",
    paddingLeft: 15
    
}

export const logoTextStyle = {
    marginLeft: 3,
    cursor: "pointer"
}

export const navBarSpacerStyle = {
    flexGrow: 1
}

export const navBarButtonStyle = {
    marginRight: 2,
    display: {
        xs: "none",
        md: "block"
    }
}

export const navBarTryNowButtonStyle = {
    border: `1px solid ${primary_color}`,
    paddingLeft: 3, 
    paddingRight: 3,
    paddingTop: 0.5,
    paddingBottom: 0.5,
    marginRight: {
        xs: 2,
        md: 0
    },
    ":hover": {
        bgcolor: white_color,
        color: primary_color
    }
}

export const navHamburgerButtonStyle = {
    visibility: {
        xs: "visible",
        md: "hidden"
    }
}
export const navBarDemoButtonStyle = {
    border: `1px solid ${primary_color}`,
    backgroundColor: white_color,
    color: primary_color,
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 0.6,
    paddingBottom: 0.6,
    marginRight: {
        xs: 2,
        md: 1
    },
    marginLeft: {
        xs: 2,
        md: 0
    },
    display: {
        xs: "none",
        md: "block"
    },
    ":hover": {
        bgcolor: primary_color,
        color: white_color
    }
}
export const navBarPromo = {
    bgcolor: primary_color,
    display: {
        xs: "block",
        md: "none",
        sm: "block"
    },
    paddingTop: 0.5,
    paddingBottom: 0.5,
    ":hover": {
        bgcolor: white_color,
        color: primary_color
    }
}
export const navBarPromoTextStyle = {
    cursor: "pointer",
    marginRight: "24px"
}