import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";

interface Props {
    payment_terms: string
}

export default function PrintTerms({payment_terms}: Props) {
    const styles = StyleSheet.create({
        body2: {
            fontSize: 10,
            fontFamily: "Montserrat"
        },
        body2_bold: {
            fontSize: 10,
            fontFamily: "Montserrat",
            fontWeight: "bold"
        }
    });
            
    return (
        <View wrap={false}>
           <View style={{marginTop: "25px", display: "flex", flexDirection: "row"}} >
                <Text style={styles.body2_bold}>
                    This quote is subject to the following terms and conditions:
                </Text>
            </View>
            <View>
                <Text style={styles.body2}>
                    {payment_terms}
                </Text>
            </View>
        </View>
    );
}