import MdStreamline from "./md";
import SmStreamline from "./sm";
import XsStreamline from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function Streamline({is_tablet, is_phone}: Props){
    if(is_phone){
        return <XsStreamline />;
    }
    else if(is_tablet){
        return <SmStreamline />;
    }
    return <MdStreamline />;
}