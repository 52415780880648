import XsCommentBox from "./xs";
import SmCommentBox from "./sm";
import MdCommentBox from "./md";

interface Props {
    is_tablet: boolean,
    is_phone: boolean,
    title: string,
    paragraph: string,
    text_color: string,
    right?: boolean
}

export default function CommentBox({is_phone, is_tablet, title, paragraph, text_color, right}: Props){
    if(is_phone){
        return (
            <XsCommentBox
                title={title}
                paragraph={paragraph}
                text_color={text_color}
                right={right} />
        );
    }
    else if(is_tablet){
        return (
            <SmCommentBox
                title={title}
                paragraph={paragraph}
                text_color={text_color}
                right={right} />
        );
    }
    return (
        <MdCommentBox
            title={title}
            paragraph={paragraph}
            text_color={text_color}
            right={right} />
    );
}