import { primary_color } from "styles/theme"

export const homePartnersContainerStyle = {
    minHeight: "65vh",
    paddingBottom: "5vh",
    backgroundColor: primary_color,
    paddingTop: {
        "xs": "5vh",
        "sm": "5vh",
        "md": "10vh"
    }
}

export const homePartnersPartnersContainerStyle = {
    paddingTop: {
        "xs": 10,
        "sm": 10,
        "md": 5
    },
    paddingBottom: 5
}