import { OrderArea } from "arris_dist";

export enum ActionType {
    GET_ORDER_AREAS_SUCCESS="GET_ORDER_AREAS_SUCCESS"
}

interface IGetOrderAreasSuccess {
    type: ActionType.GET_ORDER_AREAS_SUCCESS,
    order_areas: OrderArea[]
}

export type Action = IGetOrderAreasSuccess;