import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Row4 } from "values/text/about";
import { mediumAreaStyle } from "styles/general";
import { aboutValueListContainerStyle } from "styles/about_page/values_list";
import { aboutValueListHeaderStyle } from "styles/about_page/values_list";
import { aboutValueListParagraphStyle } from "styles/about_page/values_list";
import { aboutValueListSpacerStyle } from "styles/about_page/values_list";

export default function XsValuesList(){
    return (
        <Box sx={[mediumAreaStyle, aboutValueListContainerStyle]}>
            <Grid container>
                <Grid item xs={1.5}></Grid>
                <Grid item xs={10}>
                    <Stack>
                        <Typography variant="h6" sx={aboutValueListHeaderStyle}>
                            {Row4.HEADER_1}
                        </Typography>    
                        <Typography variant="body1" sx={aboutValueListParagraphStyle(false)}>
                            {Row4.PARAGRAPH_1}
                        </Typography>    
                    </Stack>
                </Grid>
                <Grid item xs={0.5}></Grid>
                <Grid item xs={12} sx={aboutValueListSpacerStyle}></Grid>
                <Grid item xs={1.5}></Grid>
                <Grid item xs={10}>
                    <Stack>
                        <Typography variant="h6" sx={aboutValueListHeaderStyle}>
                            {Row4.HEADER_2}
                        </Typography>    
                        <Typography variant="body1" sx={aboutValueListParagraphStyle(false)}>
                            {Row4.PARAGRAPH_2}
                        </Typography>    
                    </Stack>
                </Grid>
                <Grid item xs={0.5}></Grid>
                <Grid item xs={12} sx={aboutValueListSpacerStyle}></Grid>
                <Grid item xs={1.5}></Grid>
                <Grid item xs={10}>
                    <Stack>
                        <Typography variant="h6" sx={aboutValueListHeaderStyle}>
                            {Row4.HEADER_3}
                        </Typography>    
                        <Typography variant="body1" sx={aboutValueListParagraphStyle(false)}>
                            {Row4.PARAGRAPH_3}
                        </Typography>    
                    </Stack>
                </Grid>
                <Grid item xs={0.5}></Grid>
                <Grid item xs={12} sx={aboutValueListSpacerStyle}></Grid>
                <Grid item xs={1.5}></Grid>
                <Grid item xs={10}>
                    <Stack>
                        <Typography variant="h6" sx={aboutValueListHeaderStyle}>
                            {Row4.HEADER_4}
                        </Typography>    
                        <Typography variant="body1" sx={aboutValueListParagraphStyle(false)}>
                            {Row4.PARAGRAPH_4}
                        </Typography>    
                    </Stack>
                </Grid>
                <Grid item xs={0.5}></Grid>
                <Grid item xs={12} sx={aboutValueListSpacerStyle}></Grid>
                <Grid item xs={1.5}></Grid>
                <Grid item xs={10}>
                    <Stack>
                        <Typography variant="h6" sx={aboutValueListHeaderStyle}>
                            {Row4.HEADER_5}
                        </Typography>    
                        <Typography variant="body1" sx={aboutValueListParagraphStyle(false)}>
                            {Row4.PARAGRAPH_5}
                        </Typography>    
                    </Stack>
                </Grid>
                <Grid item xs={0.5}></Grid>
            </Grid>
        </Box>
    )
}