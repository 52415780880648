import Box from "@mui/material/Box";
import StatementBox from "components/universal/statement_box";
import { Row4 } from "values/text/about";
import { mediumAreaStyle } from "styles/general";
import { primary_color } from "styles/theme";
import { white_color } from "styles/theme";
import { aboutValuesContainerBackground } from "styles/about_page/values";

export default function Values(){
    return (
        <Box sx={[mediumAreaStyle, aboutValuesContainerBackground]}>
            <StatementBox 
                title={Row4.TITLE}
                subtitle1={Row4.SUBTITLE_1}
                text_color={white_color}
                background_color={primary_color}
                border_color={white_color}
                is_tablet={true}
                paddingTop={5}
                paddingBottom={5}  />
        </Box>
    )
}