import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import HomeHero from "./hero";
import HomeDivider from "./divider";
import WhyArris from "./why";
import Vision from "./vision";
import Access from "./access";
import Clients from "./clients";
import Material from "./material";
import Control from "./control";
import Edge from "./edge";
import Savings from "./savings";
import Footer from "components/universal/footer";
import OurPartners from "./partners";

export default function Home() {
    const theme = useTheme();

    const is_phone = useMediaQuery(theme.breakpoints.down("sm"));
    const is_tablet = useMediaQuery(theme.breakpoints.down("md"));
    const is_large = useMediaQuery(theme.breakpoints.up("md"));
    const is_medium = useMediaQuery(theme.breakpoints.up("sm"));

    return(
        <Box>
            <HomeHero is_tablet={is_tablet} is_phone={is_phone} />
            <HomeDivider />
            <WhyArris is_tablet={is_tablet} is_phone={is_phone} />
            <Vision is_phone={is_phone} is_tablet={is_tablet} />
            <Clients is_phone={is_phone} is_tablet={is_tablet} />
            <Access is_tablet={is_tablet} is_phone={is_phone} />
            <Material is_mobile={is_phone || is_tablet} />
            <Control is_mobile={is_phone || is_tablet} />
            <Edge is_phone={is_phone} is_tablet={is_tablet} />
            <Savings is_mobile={is_phone || is_tablet} />
            <OurPartners is_phone={is_phone} is_tablet={is_tablet} />
            <Footer is_tablet={is_tablet} is_phone={is_phone} />
        </Box>
    )
}
