import MdFooter from "./md";
import SmFooter from "./sm";
import XsFooter from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function Footer({is_tablet, is_phone}: Props) {
    if(is_phone){
        return <XsFooter />;
    }
    else if(is_tablet){
        return <SmFooter />;
    }
    return <MdFooter />;
}
