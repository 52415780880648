import MdOurMission from "./md";
import SmOurMission from "./sm";
import XsOurMission from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}
export default function OurMission({is_tablet, is_phone}: Props){
    if(is_phone){
        return <XsOurMission />;
    }
    else if(is_tablet){
        return <SmOurMission />;
    }
    return <MdOurMission />;
}