import axios from "axios";
import { AxiosError } from 'axios';
import { Dispatch } from "redux";
import { Action } from "api/action_types/authentication";
import { ActionType } from "api/action_types/authentication";
import { ChangePassword } from "arris_dist";
import { url_prefix } from "settings";

const email_confirmation_url: string = "email_confirmation";

export const getConfirmation = (uuid: string) => {
    return async(dispatch: Dispatch<Action>) => {

        dispatch({
            type: ActionType.CONFIRMATION_PENDING
        });

        const config = {
            headers: {
              "Content-Type": "application/json"
            }
        }

        const url = `${url_prefix}${email_confirmation_url}/?uuid=${uuid}&`;

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_CONFIRMATION_SUCCESS
            });
        }
        catch(err)
        {
            if( err instanceof AxiosError){
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: ActionType.CONFIRMATION_FAIL,
                        payload: "This confirmation is no longer active, please enter your email to send another code",
                        expired: true
                    });
                } 
                else{
                    dispatch({
                        type: ActionType.CONFIRMATION_FAIL,
                        payload: err.message,
                        expired: false
                    });
                }
            }
        }
    }
}

export const postConfirmation = (uuid: string, change_password: ChangePassword) => {
    return async(dispatch: Dispatch<Action>) => {

        dispatch({
            type: ActionType.CONFIRMATION_PENDING
        });

        const config = {
            headers: {
              "Content-Type": "application/json"
            }
        }

        const url = `${url_prefix}${email_confirmation_url}/?uuid=${uuid}&`;

        try
        {
            const {data} = await axios.post(url, change_password, config);

            dispatch({
                type: ActionType.POST_CONFIRMATION_SUCCESS
            });
        }
        catch(err)
        {
            if( err instanceof AxiosError){
                if(err && err.response){
                    if(err.response.status === 401){
                        dispatch({
                            type: ActionType.CONFIRMATION_FAIL,
                            payload: "This confirmation is no longer active, please enter your email to send another code",
                            expired: true
                        });
                    }
                    else if(err.response.status === 400){
                        dispatch({
                            type: ActionType.CONFIRMATION_FAIL,
                            payload: err.response.data.error,
                            expired: false
                        });
                    }
                } 
                else{
                    dispatch({
                        type: ActionType.CONFIRMATION_FAIL,
                        payload: err.message,
                        expired: false
                    });
                }
            }
            
        }
    }
}