export const heroWhyContainerStyle = {
    paddingTop: "6vh",
    paddingBottom: {
        "xs": "10vh",
        "sm": "6vh",
        "md": "6vh"
    }
}

export const heroWhyTitleStyle = {
    textTransform: "uppercase",
    paddingTop: 1,
    paddingBottom: 2
}

export const heroWhyDescriptionWrapperStyle = {
    paddingTop: {
        "xs": 0,
        "sm": "auto",
        "md": 2
    },
    padding: {
        "xs": 2,
        "sm": 2,
        "md": "auto"
    }
}

export const heroWhyDescriptionSubtitleStyle = {
    fontWeight: "bold",
    paddingLeft: {
        "xs": 0,
        "sm": 0
    }
}

export const heroWhyAnimationGridStyle = {
    paddingRight: {
        "xs": "auto",
        "sm": "auto",
        "md": 0
    }
}

export const heroWhyAnimationOuterWrapperStyle = {
    width: "100%"
}

// video wrapper is slightly smaller than video to hide the edges
export const heroWhyAnimationInnerWrapperStyle = {
    overflow: "hidden",
    height: {
        "xs": 249,
        "sm": 249,
        "md": 249
    },
    width: {
        "xs": 249,
        "sm": 249,
        "md": 249
    }
}

export const heroWhyDescriptionParagraphStyle = {
    padding: {
        "xs": "auto",
        "sm": "auto",
        "md": 2
    },
    paddingTop: {
        "xs": 1,
        "sm": 1,
        "md": "auto"
    },
    paddingRight: {
        "xs": 4,
        "sm": 0,
        "md": "auto"
    },
    paddingLeft: {
        "xs": 4,
        "sm": 4,
        "md": "auto"
    }
}