import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CommentBox from "components/universal/comment_box";
import { GrowthDetails } from "values/text/fabricator";
import { text_primary } from "styles/theme";
import { smallAreaStyle } from "styles/general";
import { fabricatorCommentsSpacer } from "styles/fabricator/comments";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function  Comments({is_tablet, is_phone}: Props){
    return (
        <Box sx={smallAreaStyle}>
            <Grid container>
                <CommentBox 
                    is_tablet={is_tablet}
                    is_phone={is_phone}
                    title={GrowthDetails.TITLE_1}
                    paragraph={GrowthDetails.PARAGRAPH_1}
                    right={is_tablet || is_phone}
                    text_color={text_primary}/>
                {
                    is_tablet || is_phone ? 
                    <Grid item xs={12} sx={fabricatorCommentsSpacer}></Grid> : null
                }
                <CommentBox 
                    is_tablet={is_tablet}
                    is_phone={is_phone}
                    title={GrowthDetails.TITLE_2}
                    paragraph={GrowthDetails.PARAGRAPH_2}
                    text_color={text_primary}
                    right={true}/>
            </Grid>
        </Box>
    )
}