import { Dispatch } from "redux";
import { Action } from "api/action_types/ui";
import { ActionType } from "api/action_types/ui";

export const setNavbarTransparency = (transparent: boolean) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.NAVBAR_TRANSPARENCY,
            transparent: transparent
        });
    }
}

export const setTryNow = (open: boolean) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_TRY_NOW,
            open: open
        });
    }
}
