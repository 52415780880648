import { Region } from "arris_dist";
import { SubRegion } from "arris_dist/dist/types/region";
import { Action } from "api/action_types/region";
import { ActionType } from "api/action_types/region";

interface State {
  regions: Region[],
  sub_regions: SubRegion[],
  loading: boolean,
  error: string
}

const initialState = {
  loading: false,
  regions: [],
  sub_regions: [],
  error: ""
}

export const regionReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_REGIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        regions: action.payload,
        error: ""
      }
    case ActionType.GET_SUB_REGIONS:
      return {
        ...state,
        loading: false,
        sub_regions: action.payload,
        error: ""
      }
    case ActionType.REGION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case ActionType.REGION_PENDING:
      return {
        ...state,
        loading: true
      }
    default:
      return state;
  }
}
