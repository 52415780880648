/// TYPES ///
import { OrderLineItem } from "arris_dist";
import { SelfOrderLineItem } from "arris_dist";
/// COMPONENTS ///
import { StyleSheet } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
/// VALUES ///
import { marketing_well_color } from "arris_dist";

interface Props {
    line_items: OrderLineItem[] | SelfOrderLineItem[]
}

export default function OrderLineItems({line_items}: Props) {
    const styles = StyleSheet.create({
        text: {
            fontFamily: "Montserrat",
            fontSize: 10
        },
        line: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            paddingTop: "2px",
            paddingBottom: "3px"
        },
        line_well: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            paddingTop: "2px",
            paddingBottom: "3px",
            backgroundColor: marketing_well_color
        }
    });

    return (
        <View style={{width: "100%", marginTop: "2px"}}>
            {line_items.map((line_item, index) => {
                    return (
                        <View key={line_item.id} style={index % 2 === 0 ? styles.line : styles.line_well}>
                            <View style={{width: "49%", paddingLeft: "5px"}}>
                                <Text style={styles.text}>
                                    {line_item.name}
                                </Text>
                            </View>
                            <View style={{width: "25%", paddingLeft: "6px"}}>
                                <Text style={styles.text}>
                                    {line_item.description}
                                </Text>
                            </View>
                            <View style={{width: "26%"}}>
                                <Text></Text>
                            </View>
                        </View>
                    )
                })
            }
        </View>
                      
    );
}