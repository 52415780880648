import Fab1 from "static/fab1.webp";
import { medium_area } from "styles/values";

export const fabricatorGrowthContainerStyle = {
    paddingTop: "5vh"
}

export const fabricatorGrowthGridContainerStyle = {
    height: "100%"
}

export const fabricatorGrowthImageStyle = {
    height: {
        "xs": "auto",
        "sm": `${medium_area}vh`,
        "md": "auto"
    },
    WebKitBorderRadius: {
        "xs": 4,
        "sm": 0,
        "md": 4
    },
    MozBorderRadius: {
        "xs": 5,
        "sm": 0,
        "md": 5
    },
    borderRadius: {
        "xs": 4,
        "sm": 0,
        "md": 4
    },
    backgroundImage: `url(${Fab1})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: {
        "xs": "contain",
        "sm": "cover",
        "md": "contain"
    },
    backgroundPositionY: {
        "xs": "10%",
        "sm": "80%",
        "md": "10%"
    }
}