import MdVision from "./md";
import SmVision from "./sm";
import XsVision from "./xs";

interface Props {
    is_tablet: boolean,
    is_phone: boolean
}

export default function Vision({is_tablet, is_phone}: Props){
    if(is_phone){ 
        return <XsVision />;
    }
    else if(is_tablet){
        return <SmVision />;
    }
    return <MdVision />;
}