import { Box, Typography } from '@mui/material';
import {scrollerWrapper, scrollerImgMd, scrollerImgXs, clientsTitleStyleXs} from 'styles/home_page/clients/index';
import { Row10 } from 'values/text/home';
import AccentMarble from "static/accent.jpg";
import Action from "static/action.jpg";
import Aya from "static/aya kitchens.jpg";
import Bfc from "static/bfc.jpg";
import RennovationF from "static/rennovationfind.jpg";
import Divider from '@mui/material/Divider';

export default function XsClients() {
  return (
    <Box sx={scrollerWrapper}>
    <Typography variant="h2" textAlign="center" sx={clientsTitleStyleXs}>
                        {Row10.TITLE}
                    </Typography>
    <div className='scroller' data-animated="true">
      <ul className='scroller__inner'>
      {itemData.map((item) => (
        <li key={item.img}>
          <img style={scrollerImgXs}
            src={`${item.img}`}
            alt={item.title}
            loading="lazy"
          />
        </li>
      ))}
      {itemData.map((item) => (
        <li key={item.img}>
          <img style={scrollerImgXs}
            src={`${item.img}`}
            alt={item.title}
            loading="lazy"
          />
        </li>
      ))}
    </ul>
    </div>
    <Divider variant="middle" border-width="2px"></Divider>
    </Box>
  );
}

const itemData = [

  {
    img: AccentMarble,
    title: 'Accent Marble',
  },
  {
    img: Action,
    title: 'Action Flooring',
  },
  {
    img: RennovationF,
    title: 'Rennovation Find',
  },
  {
    img: Aya,
    title: 'Aya Kitchens',
  },
  {
    img: Bfc,
    title: 'BFC Flooring',
  }
];
