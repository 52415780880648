/// HOOKS ///
import { useParams } from "react-router-dom";
import { useRef } from "react";
import { useAppDispatch } from "hooks/api";
/// ACTIONS ///
import { updateSignature } from "api/actions/signature";
/// COMPONENTS ///
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SignaturePad from "react-signature-canvas";
/// VALUES ///
import { well_color } from "styles/theme";

export default function DrawSignature() {
  const dispatch = useAppDispatch();
  const { key } = useParams();
  const sig_canvas = useRef<SignaturePad | null>(null);

  const clear = () => {
    if (sig_canvas && sig_canvas.current) {
      sig_canvas.current.clear();
    }
  }

  const addSignature = () => {
    if (sig_canvas && sig_canvas.current && key) {
      dispatch(updateSignature(sig_canvas.current.getTrimmedCanvas().toDataURL("image/png"), key));
    }
  }

  return (
    <Stack sx={{
      justifyContent: "center", width: {
        "md": "795px",
        "sm": "500px",
        "xs": "300px"
      }, marginLeft: "auto", marginRight: "auto"
    }}>
      <Stack direction="row">
        <Button onClick={clear}>
          Clear
        </Button>
        <Button onClick={addSignature}>
          Submit
        </Button>
      </Stack>
      <Box sx={{
        width: {
          "md": "795px",
          "sm": "500px",
          "xs": "350px"
        }, height: 200, borderBottom: "1px solid black", backgroundColor: well_color
      }}>
        <SignaturePad ref={sig_canvas} canvasProps={{ width: "795px", height: 200 }} />
      </Box>
      <Typography variant="body2">
        Sign here for the order below
      </Typography>
    </Stack>
  )
}
